import { Avatar, AvatarProps, MantineNumberSize } from "@mantine/core"
import { nameToInitials } from "../utils/nameToInitials"

export type DsAvatarProps = Pick<AvatarProps, "src"> & {
  name: string
  size: MantineNumberSize
}

export function DsAvatar(props: DsAvatarProps) {
  const { name, ...rest } = props
  const initials = nameToInitials(name)

  return (
    <Avatar radius={128} alt={name} {...rest}>
      {initials}
    </Avatar>
  )
}
