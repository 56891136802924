import { RouterProvider } from "react-router-dom"
import { PageFallback } from "./pages/PageFallback"
import { NotificationsProvider } from "@mantine/notifications"
import { ColorSchemeProvider, MantineProvider } from "@mantine/core"
import { ReactQueryDevtools } from "@tanstack/react-query-devtools"
import { PersistQueryClientProvider } from "@tanstack/react-query-persist-client"
import { router } from "./router"
import { useTheme } from "./hooks/theme/useTheme"
import { AuthProvider } from "./hooks/useAuth"
import { queryClient, persister } from "./lib/react-query"
import { DebugMenu } from "./components/DebugMenu"

export function AppProviders() {
  const { colorScheme, toggleColorScheme, theme } = useTheme()

  return (
    <ColorSchemeProvider
      colorScheme={colorScheme}
      toggleColorScheme={toggleColorScheme}
    >
      <MantineProvider withGlobalStyles withNormalizeCSS theme={theme}>
        <NotificationsProvider position="top-right" zIndex={401}>
          <AuthProvider>
            <PersistQueryClientProvider
              client={queryClient}
              persistOptions={{ persister }}
            >
              <RouterProvider
                router={router}
                fallbackElement={<PageFallback />}
              />
              <DebugMenu />
              <ReactQueryDevtools initialIsOpen={false} />
            </PersistQueryClientProvider>
          </AuthProvider>
        </NotificationsProvider>
      </MantineProvider>
    </ColorSchemeProvider>
  )
}
