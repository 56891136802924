import { PostModel, PostType } from "src/models/post"

export const parsePost = (post: PostType): PostModel => {
  return {
    ...post,
    liked: post.liked.length > 0,
    likesCount: post.likesCount?.[0].count || 0,
    commentsCount: post.commentsCount?.[0].count || 0
  }
}
