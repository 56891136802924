import { createStyles, UnstyledButton } from "@mantine/core"
import { Eye, EyeOff, Lock } from "@raise-sistemas/icons"
import React, { useState } from "react"
import { DsTextInputBase, DsTextInputBaseProps } from "../DsTextInputBase"

export type DsTextInputPasswordProps = Omit<
  DsTextInputBaseProps,
  "icon" | "type" | "withAsterisk" | "classNames" | "required" | "rightSection"
> & {
  isRequired?: boolean
  isVisible?: boolean
}

const selectType: {
  [key: string]: {
    type: string
    icon: React.ReactNode
  }
} = {
  text: {
    type: "text",
    icon: <EyeOff />
  },
  password: {
    type: "password",
    icon: <Eye />
  }
}

const useStyles = createStyles(theme => ({
  rightSection: {
    marginTop: "6px",
    "& > button > svg": {
      stroke: theme.colors.gray[5]
    }
  }
}))

export function DsTextInputPassword(props: DsTextInputPasswordProps) {
  const [type, setType] = useState<string>("password")

  const { classes } = useStyles()

  const onChangeType = () => {
    setType(prev => (prev === "password" ? "text" : "password"))
  }
  return (
    <DsTextInputBase
      classNames={{ rightSection: classes.rightSection }}
      {...props}
      type={selectType[type].type}
      withAsterisk={props.isRequired}
      required={props.isRequired}
      icon={<Lock />}
      rightSection={
        props.isVisible ? (
          <UnstyledButton onClick={onChangeType}>
            {selectType[type].icon}
          </UnstyledButton>
        ) : null
      }
    />
  )
}
