import { Textarea, TextareaProps } from "@mantine/core"
import React from "react"

export type DsTextareaProps = Pick<
  TextareaProps,
  | "value"
  | "disabled"
  | "placeholder"
  | "label"
  | "error"
  | "onChange"
  | "onBlur"
  | "onFocus"
> & {
  area?: "medium" | "large"
}

const textAreaProps = {
  medium: {
    minRows: 1,
    maxRows: 4
  },
  large: {
    minRows: 5
  }
}

export const DsTextarea = React.forwardRef<any, DsTextareaProps>(
  (props, ref) => {
    const { area } = props
    const config = textAreaProps[area ? area : "medium"]
    return <Textarea autosize {...props} {...config} ref={ref} />
  }
)
