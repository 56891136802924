import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

type SpaceEditPayload = {
  title: string
  description: string
  logo: string
  qty_members: number
}

export async function spaceEdit(payload: SpaceEditPayload) {
  const { error } = await supabase
    .from("db")
    .update({
      data: payload
    })
    .eq("tenant", getTenant())
    .eq("kind", KINDS.SPACE)

  if (error) throw error
}
