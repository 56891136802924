import { AppShell } from "@mantine/core"
import { Outlet } from "react-router-dom"
import { useDisclosure } from "@mantine/hooks"
import { AppNav } from "./components/AppNav"
import { AppHeader } from "./components/AppHeader"

export default function App() {
  const [opened, { toggle }] = useDisclosure(false)
  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      navbar={
        <AppNav onClose={toggle} hiddenBreakpoint="sm" hidden={!opened} />
      }
      header={<AppHeader opened={opened} toggle={toggle} />}
      padding={0}
    >
      <Outlet />
    </AppShell>
  )
}
