import { useTranslation } from "react-i18next"
import { ConfirmDeleteModal } from "../ConfirmDeleteModal"
import { usePostDeleteMutation } from "../../hooks/mutations/usePostDeleteMutation"

type Props = {
  postId: string
  opened: boolean
  onClose: () => void
}

export function PostConfirmDeleteModal({ postId, opened, onClose }: Props) {
  const { isLoading, mutate } = usePostDeleteMutation()
  const { t } = useTranslation()

  const handleDelete = () => mutate(postId)

  return (
    <ConfirmDeleteModal
      opened={opened}
      onClose={onClose}
      title={t("validation.are_you_sure")}
      description={t("validation.delete_post_question")}
      onDelete={handleDelete}
      isLoading={isLoading}
    />
  )
}
