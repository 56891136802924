import { Navigate } from "react-router-dom"
import { getHost } from "../../helpers/getHost"
import { PageHomeSpace } from "./PageHomeSpace"

export const PageHome = () => {
  const currentHost = getHost()

  if (currentHost === "app") {
    return <Navigate to="/creator" />
  }

  return <PageHomeSpace />
}
