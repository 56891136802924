import { ChangeEvent } from "react"
import { useTranslation } from "react-i18next"
import { ActionIcon, Loader, Tooltip } from "@mantine/core"
import { IconCheck, IconCircleOff, IconHelp } from "@tabler/icons"
import { DsTextInputAddon, DsTextInputAddonProps } from "@raise-sistemas/ds"

type InputSubdomainProps = Pick<DsTextInputAddonProps, "error"> & {
  onChange: (value: string) => void
  value?: string
  isLoading?: boolean
}

export const InputSubdomain = (props: InputSubdomainProps) => {
  const { onChange, value = "", error, isLoading = false } = props
  const { t } = useTranslation()

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    value = value
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, "")
      .replace(/^-+/g, "")
      .replace(/ +/g, "-")
      .replace(/-+/g, "-")
    onChange(value)
  }

  const handleBlur = (event: ChangeEvent<HTMLInputElement>) => {
    let value = event.target.value
    value = value.replace(/^-+|-+$/g, "")
    onChange(value)
  }

  const handleLeftIcon = () => {
    if (value.length < 4) return null
    if (isLoading) return <Loader color="gray" size="xs" />
    if (error) {
      return <IconCircleOff color="red" size={18} data-testid="circle-off" />
    }
    return <IconCheck color="green" size={20} data-testid="check" />
  }

  return (
    <DsTextInputAddon
      value={value}
      onBlur={handleBlur}
      onChange={handleChange}
      error={error}
      label={t("input.community.domain.label")}
      placeholder={t("input.community.domain.placeholder")}
      rightSection="lumminy.app"
      iconRight={
        <Tooltip label={t("input.community.domain.tooltip")}>
          <ActionIcon>
            <IconHelp size={18} />
          </ActionIcon>
        </Tooltip>
      }
      leftSection={handleLeftIcon()}
    />
  )
}
