import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

type SpaceConfigCreatePayload = {
  tenant: string
  user_uuid: string
  data: {
    theme: {
      colors: {
        primary: string
      }
    }
  }
}

export const spaceConfigCreate = async (payload: SpaceConfigCreatePayload) => {
  const { error } = await supabase.from("db").insert({
    kind: KINDS.SPACE_CONFIG,
    tenant: payload.tenant,
    user_uuid: payload.user_uuid,
    data: {
      theme: {
        colors: {
          primary: payload.data.theme.colors.primary
        }
      },
      colorScheme: "light"
    }
  })

  if (error) throw error
}
