import { parsePost } from "../helpers/parsePost"
import { PostType } from "../models/post"
import { supabase } from "../lib/supabase"
import { getUserId } from "../helpers/getUserId"
import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"

export async function postById(id: string) {
  const userId = await getUserId()

  const { data } = await supabase
    .from("db")
    .select(
      `
        id::text, 
        data, 
        member(*),
        user_uuid,
        liked:db!parent_id(id::text),
        likesCount:db!parent_id(count),
        commentsCount:db!parent_id(count),
        channel_post:db!child_id!inner(
          channel:parent_id(id::text, data)
        )
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST)
    .eq("liked.kind", KINDS.POST_LIKE)
    .eq("likesCount.kind", KINDS.POST_LIKE)
    .eq("commentsCount.kind", KINDS.POST_COMMENT)
    .eq("liked.user_uuid", userId)
    .eq("channel_post.child_id", id)
    .single()

  return data ? parsePost(data as unknown as PostType) : null
}
