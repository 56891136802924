import { Center, Container, Space } from "@mantine/core"
import { useForm } from "@mantine/form"
import {
  DsBox,
  DsButtonPrimary,
  DsGroup,
  DsImageLogo,
  DsText,
  DsTextInputBase
} from "@raise-sistemas/ds"
import { IconCheck } from "@tabler/icons"
import { useMutation } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import { spaceCreate } from "../../api/spaceCreate"
import { AppColorSwatchGrid } from "../../components/AppColorSwatchGrid"
import { ImageUpload } from "../../components/ImageUpload"
import { InputSubdomain } from "../../components/InputSubdomain"
import { getHost } from "../../helpers/getHost"
import { slugify } from "../../helpers/slugify"
import { useAuth } from "../../hooks/useAuth"
import { useAvailableTenant } from "../../hooks/useAvailableTenant"
import { STORAGE_KEYS } from "../../lib/constants"

export const PageCreateCommunity = () => {
  const { t } = useTranslation()
  const currentHost = getHost()
  const { user, setUser } = useAuth()

  if (currentHost !== "app") return <Navigate to="/" />

  const form = useForm({
    initialValues: {
      color: "dark",
      data: {
        title: "",
        logo: ""
      }
    },
    validate: {
      data: {
        title: value =>
          value?.length < 3 ? t("validation.required_field") : null,
        logo: value => (!value ? t("validation.required_field") : null)
      }
    }
  })

  const { registerField, tenant, validate, isLoading, setTenant } =
    useAvailableTenant()

  const mutation = useMutation(spaceCreate)

  const handleSubmit = () => {
    const invalidTenant = validate().hasError
    const invalidForm = form.validate().hasErrors
    const isInValid = invalidTenant || invalidForm

    if (isInValid || isLoading) return

    const payload = {
      color: form.values.color,
      data: {
        title: form.values.data.title,
        description: "",
        logo: form.values.data.logo,
        qty_members: 0
      },
      tenant
    }

    mutation.mutate(payload, {
      onSuccess() {
        localStorage.setItem(STORAGE_KEYS.TENANT_ID, tenant)

        setUser(currentUser => {
          return currentUser && { ...currentUser, tenant }
        })
      },
      onError(error) {
        console.log(error)
      }
    })
  }

  const handleUploadLogo = (url: string | null) => {
    if (url) form.setFieldValue("data.logo", url)
  }

  if (user?.tenant) return <Navigate to="/channels/posts" />

  return (
    <Center style={{ display: "flex", flexDirection: "column" }}>
      <Space h={100} />
      <Container size="xs">
        <Center style={{ display: "flex", flexDirection: "column" }}>
          <DsText variant="heading-3">{t("auth.basic_information")}</DsText>
          <DsText variant="body-2">{t("auth.make_your_space")}</DsText>
        </Center>
        <Space h={46} />
        <DsTextInputBase
          {...form.getInputProps("data.title")}
          onChange={e => {
            form.setFieldValue("data.title", e.target.value)
            setTenant(slugify(e.target.value))
          }}
          label={t("input.community.name.label")}
          placeholder={t("input.community.name.placeholder")}
        />
        <Space h={46} />
        <DsText variant="body-2">Logo</DsText>
        <Space h={6} />
        <DsGroup align="flex-start">
          {form.values.data.logo && (
            <DsImageLogo src={form.values.data.logo} size="md" radius="sm" />
          )}
          <ImageUpload onUpload={handleUploadLogo} style={{ flex: 1 }} />
        </DsGroup>
        <Space h={6} />
        {form.errors["data.logo"] && (
          <DsText variant="body-3" color="red">
            {form.errors["data.logo"].toString()}
          </DsText>
        )}
        <Space h={46} />
        <InputSubdomain {...registerField} />
        <Space h={46} />
        <DsText variant="body-2">{t("root.color")}</DsText>
        <Space h={10} />
        <AppColorSwatchGrid
          value={form.values.color}
          onChange={color => form.setFieldValue("color", color)}
        />
        <Space h={46} />
        <DsBox
          sx={() => ({
            display: "flex",
            justifyContent: "end"
          })}
        >
          <DsButtonPrimary
            leftIcon={<IconCheck />}
            onClick={handleSubmit}
            loading={mutation.isLoading}
          >
            {t("button.create_space")}
          </DsButtonPrimary>
        </DsBox>
      </Container>
      <Space h={46} />
    </Center>
  )
}
