import { Provider } from "@supabase/supabase-js"
import { BASE_URL } from "../env"
import { supabase } from "../lib/supabase"
const redirectTo = `${BASE_URL}/oauth`

export type SigninPayload = {
  email: string
  password: string
}

export async function signin(payload: SigninPayload) {
  if ("password" in payload) {
    const { error } = await supabase.auth.signInWithPassword(payload)
    if (error) throw error
  } else {
    const { error } = await supabase.auth.signInWithOtp(payload)
    if (error) throw error
  }
}

export const signInOAuth = (provider: Provider) => {
  return supabase.auth.signInWithOAuth({
    provider,
    options: {
      redirectTo
    }
  })
}

export const signInWithDiscord = () => signInOAuth("discord")

export const signInWithGitHub = () => signInOAuth("github")

export const signInWithGoogle = () => signInOAuth("google")
