import { Stack, Text, ThemeIcon, Title, createStyles } from "@mantine/core"
import { DsBox, DsButtonPrimary } from "@raise-sistemas/ds"
import { IconCirclePlus, IconFile } from "@tabler/icons"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { PostCreateEditDrawer } from "./post/PostCreateEditDrawer"

const useStyles = createStyles(theme => ({
  box: {
    boxShadow: theme.shadows.sm,
    borderRadius: theme.radius.md,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    padding: `${theme.spacing.xl * 3}px ${theme.spacing.xl}px`
  }
}))

export function EmptyState() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)

  return (
    <>
      <DsBox className={classes.box}>
        <Stack align="center">
          <ThemeIcon variant="light" radius={80} size={80} color="gray">
            <IconFile size={40} />
          </ThemeIcon>
          <Title align="center">{t("root.no_activity")}</Title>
          <Text color="dimmed" align="center" mb="lg">
            {t("root.no_activity_description")}
          </Text>
          <DsButtonPrimary
            leftIcon={<IconCirclePlus />}
            onClick={() => setOpened(true)}
          >
            {t("button.create_post")}
          </DsButtonPrimary>
        </Stack>
      </DsBox>
      <PostCreateEditDrawer opened={opened} setOpened={setOpened} />
    </>
  )
}
