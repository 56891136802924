import { supabase } from "../lib/supabase"
import { getTenant } from "../helpers/getTenant"

export const memberList = async () => {
  const { data, error } = await supabase
    .from("member")
    .select("*")
    .eq("tenant", getTenant())

  if (error) throw error

  return data
}
