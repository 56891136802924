import { useQuery } from "@tanstack/react-query"
import { tenantByHost } from "../../api/tenantByHost"
import { getHost } from "../../helpers/getHost"
import { STORAGE_KEYS } from "../../lib/constants"
import { queries } from "../../lib/query-key-factory"

export const useTenantQuery = () => {
  const currentHost = getHost()
  return useQuery({
    queryKey: queries.tenant._def,
    queryFn: tenantByHost,
    staleTime: 1000 * 60 * 30,
    cacheTime: 1000 * 60 * 60,
    onSuccess(tenant) {
      if (tenant) localStorage.setItem(STORAGE_KEYS.TENANT_ID, tenant)
    },
    enabled: currentHost !== "app"
  })
}
