import { ColorScheme } from "@mantine/core"
import { customTheme } from "./customTheme"
import { useHotkeys } from "@mantine/hooks"
import { useColorScheme } from "./useColorScheme"
import { usePrimaryColor } from "./usePrimaryColor"

export const useTheme = () => {
  const [primaryColor] = usePrimaryColor()
  const [colorScheme, setColorScheme] = useColorScheme()

  const toggleColorScheme = (value?: ColorScheme) => {
    setColorScheme(value || (colorScheme === "dark" ? "light" : "dark"))
  }

  useHotkeys([["mod+J", () => toggleColorScheme()]])

  const theme = { primaryColor, colorScheme, ...customTheme }

  return {
    theme,
    colorScheme,
    primaryColor,
    toggleColorScheme
  }
}
