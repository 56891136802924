import { formatDistanceToNow } from "date-fns"
import { useDateFnsLocale } from "./useDateFnsLocale"
import { getDateFromId } from "../helpers/getDateFromId"

export const useDateDistanceToNow = () => {
  const locale = useDateFnsLocale()

  const dateDistanceToNow = (date: string) => {
    const dateParse = getDateFromId(date)

    return formatDistanceToNow(dateParse, {
      locale,
      addSuffix: true
    })
  }

  return {
    dateDistanceToNow
  }
}
