import { MS_SUBDOMAIN_URL } from "../lib/constants"
import { getSession } from "./getSession"

type HostCreatePayload = {
  id: string
  tenant: string
}

export const hostCreate = async ({ id, tenant }: HostCreatePayload) => {
  const { access_token } = await getSession()

  const response = await fetch(`${MS_SUBDOMAIN_URL}/host/${id}/${tenant}`, {
    method: "PUT",
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })

  if (!response.ok) throw new Error("Unable to add host")
}
