import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

export const hasTenant = async (tenant: string) => {
  const { count } = await supabase
    .from("db")
    .select("*", { count: "exact", head: true })
    .eq("kind", KINDS.SPACE)
    .eq("tenant", tenant)

  return Boolean(count && count > 0)
}
