import { DsStackBase, DsStackBaseProps } from "../DsStackBase"

export type DsStackVerticalProps = Pick<
  DsStackBaseProps,
  | "align"
  | "justify"
  | "styles"
  | "children"
  | "className"
  | "classNames"
  | "spacing"
>

export function DsStackVertical(props: DsStackVerticalProps) {
  return <DsStackBase {...props} />
}
