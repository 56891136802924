import { useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { queries } from "../lib/query-key-factory"
import { ChannelDataType } from "../models/channel"

export const useCurrentChannel = () => {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const queryClient = useQueryClient()

  const queryFn = () => {
    const channel = queryClient.getQueryData<ChannelDataType>(
      queries.channels.current.queryKey
    )

    return channel || { emoji: "", title: "" }
  }

  const { data } = useQuery<ChannelDataType>(
    queries.channels.current.queryKey,
    {
      queryFn,
      cacheTime: 0,
      staleTime: 0,
      refetchOnWindowFocus: true,
      refetchOnMount: true
    }
  )

  const isDashboard = pathname === "/dashboard"
  const emoji = data?.emoji || ""
  const title = data?.title || ""

  return {
    emoji,
    title: isDashboard ? t("dashboard") : title
  }
}
