export const spacings: { [key: string]: number } = {
  "sp-2": 2,
  "sp-4": 4,
  "sp-8": 8,
  "sp-12": 12,
  "sp-16": 16,
  "sp-20": 20,
  "sp-24": 24,
  "sp-28": 28,
  "sp-32": 32,
  "sp-48": 48,
  "sp-64": 64,
  "sp-80": 80,
  "sp-128": 128,
  "sp-200": 200,
  "sp-400": 400
}
export const sizes: { [key: string]: number } = {
  "size-2": 2,
  "size-4": 4,
  "size-8": 8,
  "size-12": 12,
  "size-16": 16,
  "size-20": 20,
  "size-24": 24,
  "size-28": 28,
  "size-32": 32,
  "size-48": 48,
  "size-64": 64,
  "size-80": 80,
  "size-128": 128,
  "size-200": 200,
  "size-400": 400
}
export const radius: { [key: string]: number | string } = {
  "radius-8": 8,
  "radius-16": 16,
  "radius-100": "50%"
}

export const fontSize: { [key: string]: number } = {
  "font-size-12": 12,
  "font-size-14": 14,
  "font-size-16": 16,
  "font-size-24": 24,
  "font-size-32": 32,
  "font-size-48": 48
}

export const weights: { [key: string]: number } = {
  regular: 400,
  medium: 500,
  "semi-bold": 600,
  bold: 700
}
