import { useTranslation } from "react-i18next"
import { ReplyType } from "../../models/comment"
import { queries } from "../../lib/query-key-factory"
import { ConfirmDeleteModal } from "../ConfirmDeleteModal"
import { toastError } from "../../helpers/toastError"
import { commentReplyDelete } from "../../api/commentReplyDelete"
import { useMutation, useQueryClient } from "@tanstack/react-query"

type Props = {
  reply: ReplyType
  opened: boolean
  onClose: () => void
}

export function CommentReplyConfirmDeleteModal({
  reply,
  opened,
  onClose
}: Props) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation(commentReplyDelete, {
    onError() {
      toastError({
        message: t("error.message.reply_delete_fail")
      })
    },
    onSuccess() {
      queryClient.invalidateQueries(queries.comments.list._def)
      queryClient.invalidateQueries(queries.replies.list._def)
    }
  })

  const handleDelete = () => {
    mutate({ id: reply.id })
  }

  return (
    <ConfirmDeleteModal
      opened={opened}
      onClose={onClose}
      title={t("validation.are_you_sure")}
      description={t("validation.delete_comment_question")}
      onDelete={handleDelete}
      isLoading={isLoading}
    />
  )
}
