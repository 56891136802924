import { MultiSelect, createStyles } from "@mantine/core"
import {
  DsButtonPrimary,
  DsButtonSecondary,
  DsDrawer,
  DsGroup,
  DsStackVertical,
  DsText
} from "@raise-sistemas/ds"
import { Dispatch, SetStateAction } from "react"
import { useTranslation } from "react-i18next"
import { usePostCreateMutation } from "../../hooks/mutations/usePostCreateMutation"
import { usePostUpdateMutation } from "../../hooks/mutations/usePostUpdateMutation"
import { useChannelsQuery } from "../../hooks/queries/useChannelsQuery"
import { usePostEditor } from "../../hooks/usePostEditor"
import { usePostForm } from "../../hooks/usePostForm"
import { PostEditor } from "./PostEditor"

type PostCreateEditModalProps = {
  post?: {
    id: string
    title: string
    content: string
    channels: string[]
  }
  opened: boolean
  setOpened: Dispatch<SetStateAction<boolean>>
}

const POST_DRAWER_HEADER_HEIGHT = 50
const INITIAL_CONTENT = "<h2 data-placeholder></h2><p data-placeholder></p>"

const useStyles = createStyles(() => ({
  header: {
    height: POST_DRAWER_HEADER_HEIGHT,
    padding: "0 16px",
    marginBottom: 0
  },
  body: {
    padding: 16,
    height: `calc(100vh - ${POST_DRAWER_HEADER_HEIGHT}px)`,
    overflowY: "scroll",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  }
}))

export function PostCreateEditDrawer(props: PostCreateEditModalProps) {
  const { classes } = useStyles()
  const { opened, setOpened, post } = props
  const { t } = useTranslation()
  const form = usePostForm(post)
  const channels = useChannelsQuery()
  const editor = usePostEditor({
    content: post?.content || INITIAL_CONTENT,
    onUpdate: ({ editor }) => {
      if (!editor) return

      if (editor.isEmpty) {
        return editor.commands.setNode("heading", { level: 2 })
      }

      const json = editor.getJSON()
      const title = json?.content?.[0].content?.[0].text
      form.setFieldValue("title", title || "")
      form.clearFieldError("content")
    }
  })

  const content = editor?.getHTML() || INITIAL_CONTENT
  const data = channels.map(channel => ({
    value: channel.id,
    label: channel.data.title
  }))

  const edit = usePostUpdateMutation({
    onSuccess() {
      setOpened(false)
    }
  })
  const add = usePostCreateMutation({
    onSuccess() {
      form.reset()
      editor?.commands.setContent("")
      setOpened(false)
    }
  })

  const isEdit = !!post?.id
  const isLoading = isEdit ? edit.isLoading : add.isLoading

  const handleEdit = () => {
    if (!post) return
    const { values } = form
    const channelIds = post.channels

    const channelsRemove = channelIds.filter(channelId => {
      const isInitialValue = channelIds.includes(channelId)
      const isNewValue = values.channels.includes(channelId)
      return isInitialValue && !isNewValue
    })

    const channelsAdd = values.channels.filter(
      channelId => !channelIds.includes(channelId)
    )

    edit.mutate({
      id: post.id,
      data: {
        title: values.title,
        content
      },
      channels: {
        add: channelsAdd,
        remove: channelsRemove
      }
    })
  }

  const handleAdd = () => add.mutate({ ...form.values, content })

  function submit() {
    form.validate()

    if (!editor) return

    const isInvalid =
      editor.isEmpty || editor.state.doc.childCount < 2 || !editor.getText()

    if (isInvalid) {
      return form.setFieldError("content", t("validation.required_field"))
    }

    if (form.isValid()) return isEdit ? handleEdit() : handleAdd()
  }

  const handleClose = () => {
    editor?.commands.setContent(post?.content || INITIAL_CONTENT)
    form.reset()
    setOpened(false)
  }

  return (
    <DsDrawer
      opened={opened}
      onClose={handleClose}
      noPadding
      title={
        <DsText variant="body-1" weight="bold">
          {isEdit ? t("root.edit") : t("root.create_post")}
        </DsText>
      }
      size={800}
      classNames={{
        header: classes.header,
        body: classes.body
      }}
    >
      <DsStackVertical spacing="sm">
        <PostEditor editor={editor} error={form.errors?.content as string} />
        <DsStackVertical>
          <MultiSelect
            {...form.getInputProps("channels")}
            data={data}
            label={t("input.post.create.linked_channel.label")}
            placeholder={t("input.post.create.linked_channel.placeholder")}
            searchable
            dropdownPosition="top"
          />
          <DsGroup position="right">
            <DsButtonSecondary
              onClick={() => setOpened(false)}
              disabled={isLoading}
            >
              {t("button.cancel")}
            </DsButtonSecondary>
            <DsButtonPrimary onClick={submit} loading={isLoading}>
              {isEdit ? t("button.edit") : t("button.create_post")}
            </DsButtonPrimary>
          </DsGroup>
        </DsStackVertical>
      </DsStackVertical>
    </DsDrawer>
  )
}
