import { differenceInDays } from "date-fns"
import { ROLES } from "../../lib/constants"
import { Badge, Group } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { MemberType } from "../../models/member"

type MemberTagsProps = {
  member: MemberType
}

export const MemberTags = ({ member }: MemberTagsProps) => {
  const { t } = useTranslation()
  const date = new Date(member.data.created_at)

  const colorByRole = {
    [ROLES.MEMBER]: "violet",
    [ROLES.MEMBER_VIP]: "violet",
    [ROLES.MODERATOR]: "cyan",
    [ROLES.OWNER]: "blue"
  }

  return (
    <Group spacing={2} mb={2}>
      <Badge size="xs" color={colorByRole[member.role]}>
        {t(`root.${member.role}`)}
      </Badge>
      {differenceInDays(new Date(), date) < 30 && (
        <Badge size="xs" color="teal">
          {t("root.new_user")}
        </Badge>
      )}
    </Group>
  )
}
