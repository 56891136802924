import { Image, Menu, Stack } from "@mantine/core"
import { DsText } from "@raise-sistemas/ds"
import { useTranslation } from "react-i18next"

const languageOptions = [
  {
    name: "Português",
    value: "ptBR",
    flag: "https://cdn-icons-png.flaticon.com/128/206/206597.png"
  },
  {
    name: "English",
    value: "enUS",
    flag: "https://cdn-icons-png.flaticon.com/128/330/330459.png"
  }
]

export function LanguageSwitcher() {
  const { i18n } = useTranslation()

  return (
    <Stack spacing={4}>
      {languageOptions.map(languageOption => (
        <Menu.Item
          key={languageOption.value}
          onClick={() => i18n.changeLanguage(languageOption.value)}
          icon={
            <Image
              width={20}
              src={languageOption.flag}
              alt={languageOption.name}
            />
          }
        >
          <DsText
            variant="body-2"
            weight={i18n.language === languageOption.value ? "bold" : "medium"}
          >
            {languageOption.name}
          </DsText>
        </Menu.Item>
      ))}
    </Stack>
  )
}
