import {
  ActionIcon,
  Button,
  Flex,
  Group,
  SimpleGrid,
  Space,
  Stack,
  Text
} from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { IconDotsVertical, IconSearch } from "@tabler/icons"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { DsAvatar, DsTextInputBase } from "../../@raise-sistemas/ds"
import { MemberDetailDrawer } from "../../components/member/MemberDetailDrawer"
import { MemberSince } from "../../components/member/MemberSince"
import { MemberTags } from "../../components/member/MemberTags"
import { useMembersQuery } from "../../hooks/queries/useMembersQuery"
import { MemberType } from "../../models/member"

export const SettingsMembers = () => {
  const { t } = useTranslation()
  const { data: members } = useMembersQuery()
  const [opened, { close, open }] = useDisclosure(false)
  const [currentMember, setCurrentMember] = useState<MemberType | null>(null)

  const handleMemberDetail = (member: MemberType) => {
    setCurrentMember(member)
    open()
  }

  return (
    <>
      <Text fw={500} size={16}>
        {t("root.manage_members")}
      </Text>
      <Text fw={400} size={12} c="dimmed">
        {t("root.manage_members_helper_text")}
      </Text>
      <Space h={24} />
      <DsTextInputBase placeholder={t("root.search")} icon={<IconSearch />} />

      <Stack mt={52}>
        {members.map(member => {
          return (
            <Flex key={member.user_uuid} justify="space-between" gap={24}>
              <Group noWrap>
                <DsAvatar
                  name={member.data.name}
                  src={member.data.avatar}
                  size="md"
                />
                <SimpleGrid spacing={0}>
                  <Text
                    size={18}
                    weight={500}
                    mb={1}
                    sx={{
                      textOverflow: "ellipsis",
                      overflow: "hidden"
                    }}
                  >
                    {member.data.name}
                  </Text>
                  <MemberTags member={member} />
                  <Text
                    size={14}
                    color="dimmed"
                    weight={400}
                    sx={{ textOverflow: "ellipsis", overflow: "hidden" }}
                  >
                    {member.data.email}
                  </Text>
                  <MemberSince createdAt={member.data.created_at} />
                </SimpleGrid>
              </Group>
              <Group align="center" noWrap>
                <Button
                  onClick={() => handleMemberDetail(member)}
                  color="gray"
                  variant="outline"
                  compact
                  sx={{
                    fontWeight: 500,
                    fontSize: 12
                  }}
                >
                  {t("root.more_info")}
                </Button>
                <ActionIcon>
                  <IconDotsVertical size={16} />
                </ActionIcon>
              </Group>
            </Flex>
          )
        })}
      </Stack>

      <MemberDetailDrawer
        memberId={currentMember?.user_uuid || ""}
        opened={opened}
        onClose={close}
      />
    </>
  )
}
