import { getUserId } from "../helpers/getUserId"
import { supabase } from "../lib/supabase"
import { MemberDataType } from "../models/member"
import { memberById } from "./memberById"

export type ChannelEditPayload = {
  name?: string
  avatar?: string
}

export async function memberEdit(payload: ChannelEditPayload) {
  const userId = await getUserId()

  if (!userId) throw new Error("User not found")

  const { data: memberData } = await memberById(userId)

  if (!memberData) throw new Error("member not found")

  const { created_at, email, avatar, name } = memberData.data as MemberDataType

  const record = {
    data: {
      name: payload.name || name,
      avatar: payload.avatar || avatar,
      email,
      created_at
    }
  }

  const { error } = await supabase
    .from("member")
    .update(record)
    .eq("user_uuid", userId)

  if (error) throw new Error(error.message)
}
