import { getTenant } from "./getTenant"
import { getSpaceUrl } from "./getSpaceUrl"
import { hostCheck } from "../api/hostCheck"
import { toastError } from "./toastError"
import { getHost } from "./getHost"

export const navigateToSpace = async (path?: string, replace = true) => {
  const tenant = getTenant()
  const currentHost = getHost()

  if (currentHost === "app") {
    const result = await hostCheck()

    if (result && result.status !== "active") {
      return toastError({
        title: "Estamos trabalhando em publicar seu domínio.",
        message: "Te avisaremos quando isso acontecer."
      })
    }
  }

  const redirectUrl = await getSpaceUrl(tenant, path)
  if (replace) {
    return window.location.replace(redirectUrl)
  } else {
    return window.open(redirectUrl, "_blank")
  }
}
