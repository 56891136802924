import {
  DsAvatar,
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsStackVertical,
  DsTextarea
} from "@raise-sistemas/ds"
import React from "react"

type CommentBoxProps = {
  contentVisible: boolean
  form?: any
  src?: string
  name?: string
  buttonVisible?: boolean
  isEdit?: boolean
  onCLickCancel?: (e: unknown) => void
  onClickConfirm?: (e: unknown) => void
  t: (T: string) => string
}

export const CommentBox = React.forwardRef<any, CommentBoxProps>(
  (props, ref) => {
    return (
      <DsBox
        sx={{
          display: props.contentVisible ? "block" : "none"
        }}
      >
        <DsStackVertical>
          <DsGroup align="start">
            <DsAvatar src={props.src} name={props.name || ""} size="md" />
            <DsTextarea
              {...props.form?.getInputProps("content")}
              ref={ref}
              placeholder={props.t("input.comment.create.content.placeholder")}
              style={{ flex: 1 }}
              size="sm"
              area="medium"
            />
          </DsGroup>
          {props.buttonVisible && (
            <DsGroup position="right">
              <DsButtonSecondary onClick={props.onCLickCancel}>
                {props.t("button.cancel")}
              </DsButtonSecondary>
              <DsButtonPrimary
                disabled={!props.form.values.content}
                onClick={props.onClickConfirm}
              >
                {props.isEdit
                  ? props.t("button.save")
                  : props.t("button.comment")}
              </DsButtonPrimary>
            </DsGroup>
          )}
        </DsStackVertical>
      </DsBox>
    )
  }
)
