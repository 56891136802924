import { useTranslation } from "react-i18next"
import { ConfirmDeleteModal } from "../ConfirmDeleteModal"
import { showNotification } from "@mantine/notifications"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { commentDelete } from "../../api/commentDelete"
import { queries } from "../../lib/query-key-factory"
import { CommentType } from "../../models/comment"

type Props = {
  comment: CommentType
  opened: boolean
  onClose: () => void
}

export function CommentConfirmDeleteModal({ comment, opened, onClose }: Props) {
  const queryClient = useQueryClient()
  const { t } = useTranslation()

  const { isLoading, mutate } = useMutation(commentDelete, {
    onSuccess() {
      queryClient.invalidateQueries(queries.posts._def)
      queryClient.invalidateQueries(
        queries.comments.list({ postId: comment.postId })
      )
    },
    onError() {
      showNotification({
        message: t("validation.comment_delete_fail")
      })
    }
  })

  const handleDelete = () => mutate({ id: comment.id })

  return (
    <ConfirmDeleteModal
      opened={opened}
      onClose={onClose}
      title={t("validation.are_you_sure")}
      description={t("validation.delete_comment_question")}
      onDelete={handleDelete}
      isLoading={isLoading}
    />
  )
}
