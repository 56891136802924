import { Outlet } from "react-router-dom"
import { getHost } from "../helpers/getHost"
import { PageFallback } from "../pages/PageFallback"
import { PageNotFound } from "../pages/notfound/PageNotFound"
import { useTenantQuery } from "../hooks/queries/useTenantQuery"
import { useDatabasePrimaryColor } from "../hooks/theme/useDatabasePrimaryColor"

export const TenantWrapper = () => {
  const { data: tenant, isLoading } = useTenantQuery()

  const currentHost = getHost()

  const isSpace = currentHost !== "app"

  useDatabasePrimaryColor()

  if (isLoading && isSpace) return <PageFallback />
  if (!tenant && isSpace) return <PageNotFound />
  return <Outlet />
}
