import { DsImage, DsImageProps } from "../DsImage"

export type DsImageLogoProps = Pick<
  DsImageProps,
  "src" | "alt" | "className" | "classNames"
> & {
  size: "xs" | "sm" | "md" | "lg"
  radius?: "sm" | "md" | "lg"
}

const rd = {
  sm: 8,
  md: 16,
  lg: 100
}

const sizes = {
  xs: 32,
  sm: 48,
  md: 80,
  lg: 128
}

export function DsImageLogo(props: DsImageLogoProps) {
  const { size, radius, ...rest } = props
  const selectedSize = sizes[size]
  const selectedRadius = radius ? rd[radius] : 0
  return (
    <DsImage
      {...rest}
      width={selectedSize}
      height={selectedSize}
      styles={{
        image: {
          borderRadius:
            radius && radius === "lg" ? rd["lg"] : `${selectedRadius}px`
        }
      }}
    />
  )
}
