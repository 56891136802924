import { Image, ImageProps } from "@mantine/core"

export type DsImageProps = Pick<
  ImageProps,
  | "src"
  | "width"
  | "height"
  | "radius"
  | "alt"
  | "className"
  | "classNames"
  | "style"
  | "styles"
>

export function DsImage(props: DsImageProps) {
  return <Image {...props} />
}
