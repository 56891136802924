import { format } from "date-fns"
import { Text } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { useDateFnsLocale } from "../../hooks/useDateFnsLocale"

type MemberSinceProps = {
  createdAt: string
}

export const MemberSince = ({ createdAt }: MemberSinceProps) => {
  const { t } = useTranslation()
  const locale = useDateFnsLocale()

  if (!createdAt) return null

  const date = new Date(createdAt)
  return (
    <Text size={14} color="dimmed" weight={400}>
      {t("root.member_since", {
        day: format(date, "dd"),
        month: format(date, "MMMM", { locale }),
        year: format(date, "yyyy")
      })}
    </Text>
  )
}
