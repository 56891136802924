import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"
import { SpaceConfigType } from "../models/space"

export const spaceConfigGet = async () => {
  const tenant = getTenant()

  if (!tenant) throw new Error("tenant not found")

  const { error, data } = await supabase
    .from("db")
    .select("*")
    .eq("tenant", tenant)
    .eq("kind", KINDS.SPACE_CONFIG)
    .single()

  if (error) throw error

  return data as SpaceConfigType
}
