import { STORAGE_KEYS } from "../lib/constants"

const getTenantDev = () => {
  const tenant = localStorage.getItem(STORAGE_KEYS.TENANT_ID) || "dev"

  return tenant
}

const getTenantFromSubdomain = () => {
  const splittedHostname = window.location.hostname.split(".")

  if (splittedHostname.length > 2 && splittedHostname[1] !== "com") {
    return splittedHostname[0]
  }

  return "dev"
}

export const getTenant = getTenantDev
