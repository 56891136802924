import { Link } from "react-router-dom"
import { useMediaQuery } from "@mantine/hooks"
import { useTranslation } from "react-i18next"
import { DsTextInputEmail, DsTextInputPassword } from "@raise-sistemas/ds"
import {
  Button,
  Center,
  Flex,
  Grid,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  Title
} from "@mantine/core"
import { useSignUpContainer } from "./useSignUpContainer"
import { VerifyEmail } from "../../components/VerifyEmail"
import { signupCreatorImgBase64 } from "../../assets/signupCreatorImgBase64"

export const PageSignUpCreator = () => {
  const { t } = useTranslation()
  const isMobile = useMediaQuery("(max-width: 1200px)")
  const { mutation, handleSubmit, form } = useSignUpContainer()

  if (!mutation.isSuccess) {
    return (
      <Grid columns={2} m={0} align="center" h="100vh">
        <Grid.Col xs={2} md={2} lg={1} p={0}>
          <Grid columns={1} m={0} h="100vh" align="center">
            <Grid.Col>
              <Center style={{ display: "flex", flexDirection: "column" }}>
                <Image
                  height={40}
                  width={40}
                  radius="md"
                  src="https://s3.us-east-1.wasabisys.com/assets.atos6.com.br/atos6-logo"
                  alt={t("logo_image")}
                />
                <Space h="md" />
                <Title align="center" order={3} weight="500">
                  {t("auth.sign_up")}
                </Title>
                <Space h="xs" />
                <Text size="xs" align="center" color="#718096">
                  {t("auth.signup_to_participate")}
                </Text>
                <Space h="xs" />
                <Stack
                  sx={() => ({
                    width: "360px",
                    "@media (max-width: 600px)": {
                      width: "280px"
                    }
                  })}
                >
                  <TextInput
                    type="text"
                    {...form.getInputProps("name")}
                    label={t("input.name.label")}
                    placeholder={t("input.name.placeholder_first_name")}
                  />
                  <DsTextInputEmail
                    type="email"
                    {...form.getInputProps("email")}
                    label={t("input.email.label")}
                    placeholder={t("input.email.placeholder_write_email")}
                  />
                  <DsTextInputPassword
                    type="password"
                    {...form.getInputProps("password")}
                    label={t("input.password.label")}
                    placeholder={t("input.password.placeholder")}
                    isVisible
                  />
                  <DsTextInputPassword
                    type="password"
                    {...form.getInputProps("repeat_password")}
                    label={t("input.repeat_password.label")}
                    placeholder={t("input.repeat_password.placeholder")}
                  />
                  <Space h="xs" />
                  <Button onClick={handleSubmit} loading={mutation.isLoading}>
                    {t("button.create_account")}
                  </Button>
                  <Text size="xs" align="center">
                    {t("root.account_question")}{" "}
                    <Text
                      size="xs"
                      variant="link"
                      component={Link}
                      to="/sign-in"
                      align="center"
                    >
                      {t("auth.login")}
                    </Text>
                  </Text>
                </Stack>
              </Center>
            </Grid.Col>
            <Flex direction="column" align="center" w="100%">
              <Text fz="xs" c="dimmed">
                © Luminy
              </Text>
              <Text fz="xs" c="dimmed">
                ✉ ajuda@luminy.com
              </Text>
            </Flex>
          </Grid>
        </Grid.Col>
        {!isMobile && (
          <Grid.Col xs={0} md={0} lg={1} p={0}>
            <Image height="100vh" fit="cover" src={signupCreatorImgBase64} />
          </Grid.Col>
        )}
      </Grid>
    )
  }
  return (
    <VerifyEmail
      email={form.values.email}
      type="signup"
      onSubmit={handleSubmit}
    />
  )
}
