import { ModalProps } from "@mantine/core"
import {
  DsButtonDestructive,
  DsButtonSuccess,
  DsGroup,
  DsModal,
  DsText
} from "@raise-sistemas/ds"
import { useTranslation } from "react-i18next"

type ConfirmDeleteModalProps = Pick<ModalProps, "onClose" | "opened"> & {
  title: string
  description: string
  isLoading: boolean
  onDelete: () => void
}

export const ConfirmDeleteModal = (props: ConfirmDeleteModalProps) => {
  const { title, description, isLoading, onDelete, onClose, ...rest } = props

  const { t } = useTranslation()

  const handleDelete = () => {
    onDelete()
    onClose()
  }
  return (
    <DsModal
      title={title}
      onClose={onClose}
      {...rest}
      main={<DsText variant="body-2">{description}</DsText>}
      footer={
        <DsGroup position="right">
          <DsButtonDestructive onClick={onClose}>
            {t("button.cancel")}
          </DsButtonDestructive>
          <DsButtonSuccess loading={isLoading} onClick={handleDelete}>
            {t("validation.delete_confirm")}
          </DsButtonSuccess>
        </DsGroup>
      }
    />
  )
}
