import { useCallback, useEffect, useRef } from "react"
import { IconMoodHappy } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@mantine/hooks"
import * as Picmo from "picmo"
import { UnstyledButton, createStyles, Text, Box } from "@mantine/core"

type Props = {
  value: string
  onChange: (value: string) => void
  error?: string
}

const useStyles = createStyles(theme => ({
  button: {
    width: 50,
    height: 50,
    border: `1px dashed ${
      theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[5]
    }`,
    fontSize: 32,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 4
  },
  target: {
    width: "fit-content"
  }
}))

export function EmojiPicker({ value, onChange, error }: Props) {
  const { i18n } = useTranslation()
  const { t } = useTranslation()
  const { classes, theme } = useStyles()
  const currentLocale = i18n.language.substring(0, 2)
  const [opened, { close, toggle }] = useDisclosure(false)
  const emojiPickerRef = useRef<HTMLDivElement>(null)
  const pickerRef = useRef<Picmo.EmojiPicker | null>(null)
  const emoji = value ? value : <IconMoodHappy size={24} />

  const registerPicker = useCallback(() => {
    if (emojiPickerRef.current) {
      const picker = Picmo.createPicker({
        rootElement: emojiPickerRef.current,
        theme: theme.colorScheme,
        locale: currentLocale as Picmo.PickerOptions["locale"]
      })

      pickerRef.current = picker
    }
  }, [])

  const handleSelectEmoji = useCallback(
    (emoji: string) => {
      onChange(emoji)
      close()
    },
    [close, onChange]
  )

  useEffect(() => registerPicker(), [])

  useEffect(() => {
    pickerRef.current?.addEventListener("emoji:select", event => {
      handleSelectEmoji(event.emoji)
    })

    return () => {
      pickerRef.current?.removeEventListener("emoji:select", () => {
        console.log("unsubscribe")
      })
    }
  }, [handleSelectEmoji])

  return (
    <>
      <Box className={classes.target}>
        <UnstyledButton onClick={toggle} className={classes.button}>
          {emoji}
        </UnstyledButton>
        {!!error && (
          <Text color="red" size="xs" mt="xs">
            {t("root.required_emoji")}
          </Text>
        )}
      </Box>
      <Box
        ref={emojiPickerRef}
        style={{
          width: opened ? "auto" : 0,
          height: opened ? "auto" : 0,
          overflow: "hidden"
        }}
      />
    </>
  )
}
