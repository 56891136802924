import { useEffect } from "react"
import { useQuery } from "@tanstack/react-query"
import { STORAGE_KEYS } from "../../lib/constants"
import { usePrimaryColor } from "./usePrimaryColor"
import { queries } from "../../lib/query-key-factory"
import { spaceConfigEdit } from "../../api/spaceConfigEdit"
import { useUserPermissions } from "../useUserPermissions"

type MantineEvent = Event & {
  detail: {
    key: string
    value: string
  }
}

export const useDatabasePrimaryColor = () => {
  const [primaryColor, setPrimaryColor] = usePrimaryColor()
  const { CAN_EDIT_PRIMARY_COLOR } = useUserPermissions()

  useQuery({
    ...queries.spaceConfig.info(),
    onSuccess: ({ data }) => {
      if (primaryColor !== data.theme.colors.primary) {
        setPrimaryColor(data.theme.colors.primary)
      }
    },
    staleTime: 0,
    refetchOnWindowFocus: false
  })

  useEffect(() => {
    const handleUpdateColor = async (event: Event) => {
      const { detail } = event as MantineEvent

      if (detail.key === STORAGE_KEYS.PRIMARY_COLOR) {
        if (CAN_EDIT_PRIMARY_COLOR) {
          await spaceConfigEdit({ primaryColor: detail.value })
        }
      }
    }
    window.addEventListener("mantine-local-storage", handleUpdateColor)

    return () => {
      window.removeEventListener("mantine-local-storage", handleUpdateColor)
    }
  }, [])
}
