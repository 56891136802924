import { ROLES } from "../lib/constants"
import { useUser } from "./useUser"

export const useUserPermissions = (contentOwner?: string) => {
  const { roles, user_uuid, role } = useUser()
  const isContentOwner = contentOwner === user_uuid
  const isOwnerOrContentOwner = roles.owner || isContentOwner

  return {
    CAN_SEE_REPLY_ACTION_BUTTON: isOwnerOrContentOwner,
    CAN_SEE_COMMENT_ACTION_BUTTON: isOwnerOrContentOwner,
    CAN_SEE_EDIT_COMMENT_BUTTON: isContentOwner,
    CAN_SEE_EDIT_REPLY_BUTTON: isContentOwner,
    CAN_SEE_EDIT_POST_BUTTON: isContentOwner,
    CAN_SEE_POST_ACTION_BUTTON: isOwnerOrContentOwner,
    CAN_SEE_CHANNEL_ACTION_BUTTON: role === ROLES.OWNER,
    CAN_SEE_NEW_POST_BUTTON: role !== ROLES.MEMBER,
    CAN_SEE_NEW_CHANNEL_BUTTON: role !== ROLES.MEMBER,
    CAN_SEE_SETTINGS_SPACE_TAB: roles.owner,
    CAN_SEE_SETTINGS_MEMBERS_TAB: roles.owner,
    CAN_EDIT_PRIMARY_COLOR: roles.owner
  }
}
