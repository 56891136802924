import { getTenant } from "../helpers/getTenant"
import { MAIN_HOST, MS_SUBDOMAIN_URL } from "../lib/constants"

export const hostCheck = async () => {
  const tenant = getTenant()

  if (!tenant) throw new Error("tenant not found")

  const id = `${tenant}.${MAIN_HOST}`

  const response = await fetch(`${MS_SUBDOMAIN_URL}/host/${id}`)

  if (response.ok) {
    const json = await response.json()

    if (json.status === "active") {
      const event = new Event("SUBDOMAIN_ACTIVE")
      document.dispatchEvent(event)
    }

    return json as {
      name: string
      status: string
    }
  } else {
    Promise.reject(new Error("Unexpected error"))
  }
}
