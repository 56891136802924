import {
  Box,
  Button,
  Group,
  Image,
  Space,
  Stack,
  Text,
  Title
} from "@mantine/core"
import { DsButtonSecondary } from "@raise-sistemas/ds"
import { useTranslation } from "react-i18next"
import { Navigate } from "react-router-dom"
import { getHost } from "../../helpers/getHost"
import { useNavigateTo } from "../../helpers/navigateTo"

import bannerImage from "../../assets/luminy-test.png"

export const PageHomeCreator = () => {
  const { t } = useTranslation()
  const navigateTo = useNavigateTo()

  const currentHost = getHost()

  if (currentHost !== "app") return <Navigate to="/" />

  return (
    <Box
      sx={theme => ({
        backgroundColor: "#2A305D",
        minHeight: "100vh"
      })}
    >
      <Space h={18} />

      <Group spacing="md" position="apart" px={62}>
        <Text color="#E7FE58" size={24}>
          luminy
        </Text>
        <Group>
          <DsButtonSecondary onClick={() => navigateTo("/sign-in")}>
            {t("auth.login")}
          </DsButtonSecondary>
          <Button>Fale Conosco</Button>
        </Group>
      </Group>

      <Space h={180} />

      <Group pl={20} position="center" spacing={40}>
        <Stack>
          <Title size="220%" weight="400" color="white">
            SUA COMUNIDADE. <br />
            SUAS REGRAS.
          </Title>
          <Text color="dimmed" size={18}>
            Lorem ipsum dolor sit amet, <br />
            consectetur adipiscing elit.
          </Text>

          <Space h={28} />

          <Button onClick={() => navigateTo("/sign-up/creator")}>
            Criar minha comunidade
          </Button>
        </Stack>

        <Image
          radius="md"
          src={bannerImage}
          alt="Luminy Banner"
          height={462}
          width={800}
        />
      </Group>
    </Box>
  )
}
