import {
  Group,
  Menu,
  Text,
  UnstyledButton,
  UnstyledButtonProps,
  createStyles
} from "@mantine/core"
import { DsAvatar } from "@raise-sistemas/ds"
import { IconChevronRight, IconLogout, IconSettings } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { IoLinkOutline } from "react-icons/io5"
import { Link } from "react-router-dom"
import { signout } from "../api/signout"
import { getTenant } from "../helpers/getTenant"
import { navigateToSpace } from "../helpers/navigateToSpace"
import { LanguageSwitcher } from "./LanguageSwitch"
import { ThemeSwitch } from "./ThemeSwitch"

const useStyles = createStyles(theme => ({
  user: {
    display: "block",
    width: "100%",
    padding: theme.spacing.md,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,

    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[8]
          : theme.colors.gray[0]
    }
  }
}))

type UserButtonProps = UnstyledButtonProps & {
  image: string
  name: string
  email: string
  icon?: React.ReactNode
}

export function UserButton({
  image,
  name,
  email,
  icon,
  ...others
}: UserButtonProps) {
  const { classes } = useStyles()
  const { t } = useTranslation()

  return (
    <Menu shadow="md" width={280}>
      <Menu.Target>
        <UnstyledButton className={classes.user} {...others}>
          <Group>
            <DsAvatar name={name} src={image} size="md" />

            <div style={{ flex: 1 }}>
              <Text
                size="sm"
                weight={500}
                sx={theme => ({
                  color:
                    theme.colorScheme === "light"
                      ? theme.colors.gray[7]
                      : theme.colors.gray[4]
                })}
              >
                {name}
              </Text>

              <Text color="dimmed" size="xs">
                {email}
              </Text>
            </div>

            {icon || <IconChevronRight size={14} stroke={1.5} />}
          </Group>
        </UnstyledButton>
      </Menu.Target>

      <Menu.Dropdown>
        <Menu.Label>{t("root.select_language")}</Menu.Label>
        <LanguageSwitcher />
        <Menu.Divider />
        <Menu.Label>{t("root.community")}</Menu.Label>
        <Menu.Item
          icon={<IoLinkOutline size={14} />}
          onClick={() => navigateToSpace("/", false)}
        >
          {t("root.my_public_page")}
        </Menu.Item>
        <Menu.Divider />
        <Menu.Label>{t("root.settings")}</Menu.Label>
        <Menu.Item
          icon={<IconSettings size={14} />}
          component={Link}
          to="/settings"
        >
          {t("root.settings")}
        </Menu.Item>
        <ThemeSwitch />
        <Menu.Divider />
        <Menu.Item
          color="red"
          icon={<IconLogout size={14} />}
          onClick={signout}
        >
          {t("auth.sign_out")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
