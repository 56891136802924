import { useForm } from "@mantine/form"
import { useDisclosure } from "@mantine/hooks"
import {
  DsButtonPrimary,
  DsButtonUnstyled,
  DsDrawer,
  DsStackVertical,
  DsTextInputBase
} from "@raise-sistemas/ds"
import { FormEvent, PropsWithChildren, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { useChannelMutation } from "../../hooks/mutations/useChannelMutation"
import { ChannelDataType } from "../../models/channel"
import { EmojiPicker } from "../EmojiPicker"

const MAX_CHARACTERS = 24

type ChannelCreateEditDrawerProps = {
  opened?: boolean
  onClose?: () => void
  channel?: ChannelDataType & { id: string }
}

export function ChannelCreateEditDrawer(
  props: PropsWithChildren<ChannelCreateEditDrawerProps>
) {
  const { t } = useTranslation()
  const [opened, { open, close }] = useDisclosure(false)

  const isEdit = !!props.channel

  const form = useForm({
    initialValues: {
      title: "",
      emoji: ""
    },

    validate: {
      emoji: value => (value === "" ? t("root.required_emoji") : null),
      title: value => {
        if (value.trim().length < 3) return t("validation.title_min_size")
        if (value.length > MAX_CHARACTERS) return t("validation.title_max_size")
        return null
      }
    }
  })

  const mutation = useChannelMutation()

  const handleSubmit = () => {
    mutation.mutate({
      ...form.values,
      id: props.channel?.id
    })

    form.reset()
    props.onClose ? props.onClose() : close()
  }

  useEffect(() => {
    form.setValues({
      title: props.channel?.title || "",
      emoji: props.channel?.emoji || ""
    })
  }, [props.channel])

  return (
    <>
      <DsDrawer
        opened={props.opened ?? opened}
        onClose={props.onClose ?? close}
        scroll={false}
        title={isEdit ? t("root.edit") : t("root.create_channel")}
      >
        <DsStackVertical>
          <DsTextInputBase
            label={t("input.channel.create.label")}
            placeholder={t("input.channel.create.placeholder")}
            maxLength={MAX_CHARACTERS}
            description={`${
              MAX_CHARACTERS -
              form.values.title.length +
              " " +
              t("input.channel.characters_leght")
            }`}
            inputWrapperOrder={["label", "input", "description", "error"]}
            {...form.getInputProps("title")}
          />
          <EmojiPicker
            value={form.values.emoji}
            onChange={value => form.setFieldValue("emoji", value)}
            error={form.errors?.emoji ? String(form.errors?.emoji) : ""}
          />
          <DsButtonPrimary
            onClick={(e: unknown) =>
              form.onSubmit(handleSubmit)(e as FormEvent<HTMLFormElement>)
            }
          >
            {isEdit ? t("root.edit") : t("root.create_channel")}
          </DsButtonPrimary>
        </DsStackVertical>
      </DsDrawer>

      {props.children && (
        <DsButtonUnstyled onClick={open}>{props.children}</DsButtonUnstyled>
      )}
    </>
  )
}
