import { Container, ContainerProps } from "@mantine/core"

export type DsContainerProps = Pick<
  ContainerProps,
  | "children"
  | "className"
  | "classNames"
  | "styles"
  | "style"
  | "sizes"
  | "size"
  | "sx"
  | "fluid"
>

export function DsContainer(props: DsContainerProps) {
  return <Container {...props} />
}
