import { DsGroup } from "@raise-sistemas/ds"
import { ChannelPostType } from "../../models/post"
import { PostChannelButton } from "./PostChannelButton"
type PostChannelListProps = {
  channel_post: ChannelPostType[]
}

export const PostChannelList = ({ channel_post }: PostChannelListProps) => {
  return (
    <DsGroup spacing="xs">
      {channel_post?.map(({ channel }) => {
        return channel ? (
          <PostChannelButton
            key={`post-channel-button-${channel.id}`}
            channel={channel}
          />
        ) : null
      })}
    </DsGroup>
  )
}
