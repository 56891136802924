import { useQuery } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"
import { MemberDataType } from "../../models/member"

export const useMembersQuery = () => {
  const { data, ...rest } = useQuery(queries.members.list())

  const membersParsed = data?.map(member => ({
    ...member,
    data: member.data as MemberDataType
  }))

  return {
    data: membersParsed || [],
    ...rest
  }
}
