import { createStyles } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { DsAvatarAndContent, DsGroup } from "@raise-sistemas/ds"
import { useState } from "react"
import { getChannelIds } from "../../helpers/getChannelIds"
import { useUserPermissions } from "../../hooks/useUserPermissions"
import { PostModel } from "../../models/post"
import { EntityActions } from "../EntityActions"
import { PostConfirmDeleteModal } from "./PostConfirmDeleteModal"
import { PostCreateEditDrawer } from "./PostCreateEditDrawer"

const useStyles = createStyles(theme => ({
  target: {
    position: "absolute",
    top: 0,
    right: 0
  }
}))

type PostHeaderProps = {
  post: PostModel
}

export const PostHeader = ({ post }: PostHeaderProps) => {
  const { classes } = useStyles()
  const [opened, setOpened] = useState(false)
  const [postOpened, postHandler] = useDisclosure(false)
  const permissions = useUserPermissions(post.user_uuid)

  const handleDelete = () => setOpened(true)
  return (
    <>
      <DsGroup position="apart" align="start" style={{ position: "relative" }}>
        <DsAvatarAndContent
          size="md"
          name={post.member.data.name}
          content="full"
          src={post.member.data.avatar}
          id={post.id}
        />
        {permissions.CAN_SEE_POST_ACTION_BUTTON && (
          <EntityActions
            onDelete={handleDelete}
            onEdit={postHandler.open}
            classNames={{
              target: classes.target
            }}
            hideEdit={!permissions.CAN_SEE_EDIT_POST_BUTTON}
          />
        )}
      </DsGroup>
      <PostConfirmDeleteModal
        postId={post.id}
        opened={opened}
        onClose={() => setOpened(false)}
      />

      <PostCreateEditDrawer
        post={{
          ...post.data,
          id: post.id,
          channels: getChannelIds(post.channel_post)
        }}
        opened={postOpened}
        setOpened={postHandler.close}
      />
    </>
  )
}
