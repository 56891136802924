import { Link } from "@mantine/tiptap"
import { useTranslation } from "react-i18next"
import { EditorOptions, useEditor } from "@tiptap/react"
import Image from "@tiptap/extension-image"
import StarterKit from "@tiptap/starter-kit"
import Youtube from "@tiptap/extension-youtube"
import Underline from "@tiptap/extension-underline"
import Highlight from "@tiptap/extension-highlight"
import TextAlign from "@tiptap/extension-text-align"
import Typography from "@tiptap/extension-typography"
import Placeholder from "@tiptap/extension-placeholder"
import Iframe from "../components/editor/extensions/Iframe"
import LinkPreview from "../components/editor/extensions/LinkPreview"

export const usePostEditor = (options?: Partial<EditorOptions>) => {
  const { t } = useTranslation()

  return useEditor({
    extensions: [
      StarterKit,
      Link,
      LinkPreview,
      Highlight,
      Underline,
      Image,
      Typography,
      Iframe.configure({
        HTMLAttributes: {
          class: "video-container"
        }
      }),
      Placeholder.configure({
        showOnlyCurrent: false,
        placeholder: ({ editor, node }) => {
          const blockCount = editor.view.state.doc.content.childCount

          if (blockCount > 2) return ""

          if (node.type.name === "heading") {
            return t("input.post.create.title.placeholder")
          }

          return t("input.post.edit.content.placeholder")
        }
      }),
      TextAlign.configure({ types: ["heading", "paragraph"] }),
      Youtube.configure({
        controls: true,
        HTMLAttributes: {
          class: "video-container"
        }
      })
    ],
    ...options
  })
}
