import { DsText } from "@raise-sistemas/ds"
import { DsButtonBase } from "../DsButtonBase/DsButtonBase"

export type DsButtonLanguageProps = {
  onMouseEnter?: () => any
  isBold?: boolean
  languageName: string
}

export function DsButtonLanguage(props: DsButtonLanguageProps) {
  return (
    <DsButtonBase
      radius="xl"
      size="xs"
      compact
      onMouseEnter={props.onMouseEnter}
    >
      <DsText variant="body-3" weight={props.isBold ? "bold" : "medium"}>
        {props.languageName}
      </DsText>
    </DsButtonBase>
  )
}
