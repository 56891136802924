import { Center, Stack, Title, createStyles } from "@mantine/core"
import { useForm } from "@mantine/form"
import {
  DsButtonPrimary,
  DsImageLogo,
  DsSpace,
  DsStackVertical,
  DsTextInputBase
} from "@raise-sistemas/ds"
import { useMutation } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Form } from "react-router-dom"
import { forgotPassword } from "../../api/forgotPassword"
import { toastSuccess } from "../../helpers/toastSuccess"
import { useUnexpectedError } from "../../hooks/errors/useUnexpectedError"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"

const useStyles = createStyles(() => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "360px",

    ["@media (max-width: 600px)"]: {
      width: "260px"
    }
  },
  logo: {
    margin: "0 auto"
  }
}))

export const PageForgotPassword = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const data = useSpaceQuery()
  const unexpectedError = useUnexpectedError()
  const mutation = useMutation(forgotPassword, {
    onError() {
      unexpectedError(t("error.cant_finalize"))
    },
    onSuccess() {
      toastSuccess({
        title: "Próximo passo...",
        message: "Siga as instruções enviadas para o seu e-mail."
      })
    }
  })

  const form = useForm({
    initialValues: {
      email: ""
    },

    validate: {
      email: value =>
        /\S+@\S+\.\S+/.test(value) ? null : t("validation.invalid_email")
    }
  })

  const handleSubmit = () => {
    if (!form.isValid) return
    mutation.mutate(form.values)
  }

  return (
    <Center className={classes.container}>
      <DsStackVertical spacing="md">
        <DsImageLogo
          size="sm"
          radius="sm"
          className={classes.logo}
          src={data?.data?.logo}
          alt={t("logo_image")}
        />
        <DsSpace height="lg" />
        <DsSpace height="sm" />
        <Title align="center" order={3} weight="500">
          {t("auth.forgot_password")}
        </Title>
        <Form method="post">
          <Stack className={classes.form} spacing="xl">
            <DsTextInputBase
              label={t("input.email.label")}
              placeholder={t("input.email.placeholder_write_email")}
              required
              {...form.getInputProps("email")}
            />
            <DsButtonPrimary
              onClick={(e: unknown) =>
                form.onSubmit(handleSubmit)(e as FormEvent<HTMLFormElement>)
              }
              loading={mutation.isLoading}
            >
              {t("button.recover_password")}
            </DsButtonPrimary>
          </Stack>
        </Form>
      </DsStackVertical>
    </Center>
  )
}
