import { supabase } from "../lib/supabase"
import { setUserId } from "./setUserId"

export const getUserId = async () => {
  const userIdStorage = localStorage.getItem("@mycommu/userId")

  if (userIdStorage) return userIdStorage

  const { data } = await supabase.auth.getUser()

  if (data.user) {
    setUserId(data.user.id)
    return data.user.id
  }
}
