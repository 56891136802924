import { useTranslation } from "react-i18next"
import { Divider, Title } from "@mantine/core"

export const PageProducer = () => {
  const { t } = useTranslation()
  return (
    <div>
      <Title>{t("root.my_public_page")}</Title>
      <Divider my="xl" />
    </div>
  )
}
