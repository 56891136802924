import { FormEvent, useCallback } from "react"
import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@mantine/hooks"
import { VscPlayCircle } from "react-icons/vsc"
import { isVideoURL } from "../../../helpers/isVideoURL"
import { isYouTubeURL } from "../../../helpers/isYouTubeURL"
import { isVimeoVideoURL } from "../../../helpers/isVimeoVideoURL"
import { generateVimeoUrl } from "../../../helpers/generateVimeoUrl"
import { Button, createStyles, Popover, TextInput } from "@mantine/core"
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap"

const useStyles = createStyles(() => ({
  linkEditor: {
    display: "flex"
  },
  linkEditorInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  linkEditorSave: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}))

export const VideoControl = () => {
  const { classes } = useStyles()
  const { editor } = useRichTextEditorContext()
  const [opened, handlers] = useDisclosure(false)
  const { t } = useTranslation()
  const form = useForm({
    initialValues: {
      url: ""
    },
    validate: {
      url: value => {
        if (value.length === 0) return t("validation.required_field")
        if (!isVideoURL(value)) return t("validation.invalid_url")
        return null
      }
    }
  })
  const handleClose = () => {
    handlers.close()
    form.reset()
  }

  const addVideo = useCallback(() => {
    const isYouTube = isYouTubeURL(form.values.url)
    const { url } = form.values
    let src = url

    if (isVimeoVideoURL(url)) src = generateVimeoUrl(url)

    if (src !== "") {
      const method = isYouTube ? "setYoutubeVideo" : "setIframe"
      editor.chain().focus()[method]({ src }).run()
      handleClose()
    }
  }, [editor, handleClose, form.values.url])

  return (
    <Popover
      width={300}
      trapFocus
      shadow="md"
      withinPortal
      zIndex={10000}
      offset={-44}
      opened={opened}
      onClose={handleClose}
    >
      <Popover.Target>
        <RichTextEditor.Control
          aria-label="Add video"
          title="Add video"
          onClick={handlers.open}
        >
          <VscPlayCircle size={16} />
        </RichTextEditor.Control>
      </Popover.Target>
      <Popover.Dropdown
        sx={theme => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
        })}
      >
        <div className={classes.linkEditor}>
          <TextInput
            classNames={{ input: classes.linkEditorInput }}
            placeholder="YouTube/Vimeo URL"
            type="url"
            {...form.getInputProps("url")}
          />
          <Button
            variant="default"
            className={classes.linkEditorSave}
            onClick={(e: unknown) =>
              form.onSubmit(addVideo)(e as FormEvent<HTMLFormElement>)
            }
          >
            {t("button.save")}
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
