import { DsContainer, DsSpace } from "@raise-sistemas/ds"
import { EmptyState } from "../../components/EmptyState"
import { HeaderTimeline } from "../../components/HeaderTimeline"

export function PageDashboard() {
  return (
    <>
      <HeaderTimeline />
      <DsSpace height="xl" />
      <DsSpace height="xl" />
      <DsSpace height="xl" />
      <DsSpace height="xl" />
      <DsContainer fluid>
        <EmptyState />
      </DsContainer>
    </>
  )
}
