import { supabase } from "../lib/supabase"

export type NewPasswordPayload = {
  password: string
}

export const newPassword = async (payload: NewPasswordPayload) => {
  const { error } = await supabase.auth.updateUser({
    password: payload.password
  })

  if (error) throw error
}
