import { Drawer, DrawerProps, createStyles } from "@mantine/core"

type DsDrawerProps = Pick<DrawerProps, "classNames"> & {
  opened: boolean
  onClose: () => void
  title: string | React.ReactNode
  children: React.ReactNode
  scroll?: boolean
  size?: "xl" | 800
  noPadding?: boolean
}

const POST_DRAWER_HEADER_HEIGHT = 50

const useStyles = createStyles((theme, { scroll }: { scroll?: boolean }) => ({
  header: {
    height: POST_DRAWER_HEADER_HEIGHT,
    padding: "0 16px",
    marginBottom: 0
  },
  body: {
    padding: 16,
    height: `calc(100vh - ${POST_DRAWER_HEADER_HEIGHT}px)`,
    overflowY: scroll ? "scroll" : "hidden",
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "column"
  }
}))

export const DsDrawer = (props: DsDrawerProps) => {
  const {
    opened,
    onClose,
    title,
    children,
    scroll,
    size = "xl",
    noPadding
  } = props
  const { classes } = useStyles({ scroll })

  return (
    <Drawer
      opened={opened}
      onClose={onClose}
      title={title}
      padding={noPadding ? 0 : "xl"}
      size={size}
      position="right"
      zIndex={9999}
      classNames={{
        header: classes.header,
        body: classes.body
      }}
    >
      {children}
    </Drawer>
  )
}
