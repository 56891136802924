import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

export async function postDelete(postId: string) {
  const { error } = await supabase
    .from("db")
    .delete()
    .eq("kind", KINDS.POST)
    .eq("id", postId)

  if (error) throw new Error(error.message)
}
