import { MediaQuery, useMantineTheme } from "@mantine/core"
import { useDisclosure, useMediaQuery } from "@mantine/hooks"
import { IconChevronDown } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { useLocation, useParams } from "react-router-dom"
import { DsText, DsGroup } from "../@raise-sistemas/ds"
import { useAuth } from "../hooks/useAuth"
import { useCurrentChannel } from "../hooks/useCurrentChannel"
import { useUserPermissions } from "../hooks/useUserPermissions"
import { ChannelConfirmDeleteModal } from "./channel/ChannelConfirmDeleteModal"
import { ChannelCreateEditDrawer } from "./channel/ChannelCreateEditDrawer"
import { EntityActions } from "./EntityActions"

export function HeaderTimeline() {
  const [channelDrawerOpened, channelDrawerHandler] = useDisclosure(false)
  const [channelDeleteOpenend, channelDeleteHandler] = useDisclosure(false)
  const theme = useMantineTheme()
  const matches = useMediaQuery(`(min-width: ${theme.breakpoints.sm}px)`)
  const { emoji, title } = useCurrentChannel()
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const { CAN_SEE_CHANNEL_ACTION_BUTTON } = useUserPermissions()
  const params = useParams()
  const id = params?.id || ""

  const currentChannel = { id, emoji, title }

  const mainPathNames =
    pathname === "/channels/posts" || pathname === "/dashboard"

  if (!id && !mainPathNames) return <div />

  if (mainPathNames) {
    return (
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <DsText variant="heading-3">{`${t("greetings.hello_again")}, ${
          user!.data.name
        }!`}</DsText>
      </MediaQuery>
    )
  }

  //TODO: Corrigir EntityActions desaparece os itens edit e delete quando o headerTitle é muito extenso
  return (
    <>
      <DsGroup>
        <DsText variant={matches ? "heading-3" : "body-1"}>{emoji}</DsText>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <DsText variant="heading-3">{title}</DsText>
        </MediaQuery>
        {CAN_SEE_CHANNEL_ACTION_BUTTON && (
          <EntityActions
            onDelete={channelDeleteHandler.open}
            onEdit={channelDrawerHandler.open}
            customTargetIcon={<IconChevronDown size={24} />}
          />
        )}
      </DsGroup>
      {CAN_SEE_CHANNEL_ACTION_BUTTON && (
        <>
          <ChannelCreateEditDrawer
            opened={channelDrawerOpened}
            onClose={channelDrawerHandler.close}
            channel={currentChannel}
          />
          <ChannelConfirmDeleteModal
            id={id}
            opened={channelDeleteOpenend}
            onClose={channelDeleteHandler.close}
          />
        </>
      )}
    </>
  )
}
