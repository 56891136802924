import { getUser } from "./getUser"
import { KINDS, MAIN_HOST, ROLES } from "../lib/constants"
import { hostCreate } from "./hostCreate"
import { supabase } from "../lib/supabase"
import { memberCreate } from "./memberCreate"
import { spaceConfigCreate } from "./spaceConfigCreate"

type SpaceCreatePayload = {
  tenant: string
  color: string
  data: {
    title: string
    description: string
    logo: string
    qty_members: number
  }
}

export async function spaceCreate(payload: SpaceCreatePayload) {
  const userData = await getUser()

  const { user } = userData.data

  if (!user) throw new Error("User not found")

  const memberPayload = {
    data: {
      email: user.email || "",
      name: user.user_metadata?.name || "",
      avatar: user.user_metadata?.avatar || ""
    },
    role: ROLES.OWNER,
    user_uuid: user?.id,
    tenant: payload.tenant
  }

  const spacePayload = {
    user_uuid: user.id,
    tenant: payload.tenant,
    kind: KINDS.SPACE,
    data: payload.data
  }

  const spaceConfigCreatePayload = {
    data: {
      theme: {
        colors: {
          primary: payload.color
        }
      }
    },
    tenant: payload.tenant,
    user_uuid: user.id
  }

  const { error } = await memberCreate(memberPayload)

  if (error) throw new Error("Unable to add member")

  const spaceCreatePromise = supabase.from("db").insert(spacePayload)

  const spaceConfigCreatePromise = spaceConfigCreate(spaceConfigCreatePayload)

  const [spaceCreateResponse, spaceConfigCreateResponse] =
    await Promise.allSettled([spaceCreatePromise, spaceConfigCreatePromise])

  if (
    spaceCreateResponse.status === "rejected" ||
    spaceConfigCreateResponse.status === "rejected"
  ) {
    throw new Error("Unable to create space")
  }

  await hostCreate({
    id: `${payload.tenant}.${MAIN_HOST}`,
    tenant: payload.tenant
  })
}
