import { getTenant } from "../helpers/getTenant"
import { CommentType } from "src/models/comment"
import { supabase } from "../lib/supabase"
import { getUserId } from "../helpers/getUserId"
import { KINDS } from "../lib/constants"

export type CommentListPayload = {
  page?: number
  limit?: number
  postId: string
}

export async function commentList({
  page = 1,
  limit = 10,
  postId
}: CommentListPayload) {
  const currentPage = Math.max(0, page - 1)
  const rangeStart = currentPage * limit
  const rangeEnd = rangeStart + limit - 1

  const userId = await getUserId()

  const { data } = await supabase
    .from("db")
    .select(
      `
      id::text,
      postId:parent_id::text,
      data->content,
      user_uuid,
      member(data->name,data->avatar),
      likes:db!parent_id(id::text),
      likesCount:db!parent_id(count),
      repliesCount:db!parent_id(count),
      comment_reply:db!parent_id(id::text, data->content, member(data->name, data->avatar))
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST_COMMENT)
    .eq("parent_id", postId)
    .eq("likes.kind", KINDS.COMMENT_LIKE)
    .eq("likesCount.kind", KINDS.COMMENT_LIKE)
    .eq("likes.user_uuid", userId)
    .eq("comment_reply.kind", KINDS.COMMENT_REPLY)
    .eq("repliesCount.kind", KINDS.COMMENT_REPLY)
    .limit(20, { foreignTable: KINDS.COMMENT_REPLY })
    .order("id", { ascending: false })
    .range(rangeStart, rangeEnd)
  return data ? (data as unknown as CommentType[]) : []
}
