import { getSession } from "./getSession"
import { MAIN_HOST, MS_SUBDOMAIN_URL } from "../lib/constants"

export const hostCheckAvailable = async (tenant: string) => {
  if (!tenant) throw new Error("tenant not found")

  const id = `${tenant}.${MAIN_HOST}`

  const { access_token } = await getSession()

  const response = await fetch(
    `${MS_SUBDOMAIN_URL}/host/${id}/${tenant}/available`,
    {
      method: "GET",
      headers: {
        Authorization: `Bearer ${access_token}`
      }
    }
  )

  if (response.ok) {
    const json = await response.json()
    return json.available as boolean
  }

  return false
}
