import { useTranslation } from "react-i18next"
import { toastError } from "../../helpers/toastError"

export const useUnexpectedError = () => {
  const { t } = useTranslation()

  return (message = "") => {
    toastError({
      title: t("error.message.unexpected_error"),
      message
    })
  }
}
