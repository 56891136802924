import { postLike } from "../../api/postLike"
import { queries } from "../../lib/query-key-factory"
import { useMutation, useQueryClient } from "@tanstack/react-query"

export const usePostLikeMutation = () => {
  const queryClient = useQueryClient()
  return useMutation({
    mutationFn: postLike,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: queries.posts._def })
    }
  })
}
