import { Button, ButtonProps } from "@mantine/core"
import { ButtonHTMLAttributes } from "react"

export type DSColors = Pick<ButtonProps, "color">

export type DsButtonBaseProps = Pick<
  ButtonProps,
  | "children"
  | "loading"
  | "disabled"
  | "leftIcon"
  | "compact"
  | "p"
  | "radius"
  | "styles"
> &
  Pick<ButtonHTMLAttributes<HTMLButtonElement>, "onClick"> & {
    color?: "violet" | "gray" | "red" | "green"
    variant?: "light" | "outline" | "filled" | "subtle"
    size?: "lg" | "md" | "sm" | "xs"
    onMouseEnter?: () => any
  }

export function DsButtonBase(props: DsButtonBaseProps) {
  return <Button {...props}>{props.children}</Button>
}
