import { IconAdjustments } from "@tabler/icons"
import { ActionIcon, Affix, Menu, Popover } from "@mantine/core"
import { DebugLanguageSwitcher } from "./debug/DebugLanguageSwitcher"
import { ThemeSwitch } from "./ThemeSwitch"
import { AppColorSwatch } from "./AppColorSwatch"

export function DebugMenu() {
  return (
    <Affix position={{ bottom: 60, right: 20 }}>
      <Popover
        width={160}
        trapFocus
        position="bottom"
        withArrow
        shadow="md"
        closeOnClickOutside={false}
      >
        <Popover.Target>
          <ActionIcon>
            <IconAdjustments size={18} />
          </ActionIcon>
        </Popover.Target>
        <Popover.Dropdown
          sx={theme => ({
            background:
              theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
          })}
        >
          <Menu>
            <DebugLanguageSwitcher />
            <ThemeSwitch />
            <AppColorSwatch />
          </Menu>
        </Popover.Dropdown>
      </Popover>
    </Affix>
  )
}
