import { Skeleton } from "@mantine/core"
import { DsBox, DsContainer, DsStackVertical } from "@raise-sistemas/ds"

export const SkeletonChannelPosts = () => {
  return (
    <DsContainer fluid>
      <DsStackVertical spacing="xl">
        <DsBox>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={16} radius="xl" />
          <Skeleton height={16} mt={6} radius="xl" />
          <Skeleton height={16} mt={6} width="70%" radius="xl" />
        </DsBox>

        <DsBox>
          <Skeleton height={50} circle mb="xl" />
          <Skeleton height={16} radius="xl" />
          <Skeleton height={16} mt={6} radius="xl" />
          <Skeleton height={16} mt={6} width="70%" radius="xl" />
        </DsBox>
      </DsStackVertical>
    </DsContainer>
  )
}
