export type HeartOutlineProps = {
  color?: string
  size?: number
}

export function HeartOutline(props: HeartOutlineProps) {
  return (
    <svg
      width={props.size}
      height={props.size}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.7858 1.125C9.24991 1.125 7.99991 3.625 7.99991 3.625C7.99991 3.625 6.74991 1.125 4.21397 1.125C2.15304 1.125 0.521005 2.84922 0.499911 4.90664C0.456942 9.17734 3.8878 12.2145 7.64835 14.7668C7.75202 14.8373 7.87452 14.8751 7.99991 14.8751C8.1253 14.8751 8.2478 14.8373 8.35147 14.7668C12.1116 12.2145 15.5425 9.17734 15.4999 4.90664C15.4788 2.84922 13.8468 1.125 11.7858 1.125Z"
        stroke={props.color}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
