import { getTenant } from "../helpers/getTenant"
import { supabase } from "../lib/supabase"
import { uid } from "../uid"

export const uploadImage = async (image: File) => {
  const tenant = getTenant()

  const { data } = await supabase.storage
    .from("avatars")
    .upload(`${tenant}/${uid()}.png`, image)

  if (data) {
    const { data: urlData } = supabase.storage
      .from("avatars")
      .getPublicUrl(data.path)

    return urlData.publicUrl
  }

  return null
}
