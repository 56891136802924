import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"
import { SpaceType } from "../models/space"

export async function spaceById() {
  const { data } = await supabase
    .from("db")
    .select("id::text, data")
    .eq("tenant", getTenant())
    .eq("kind", KINDS.SPACE)
    .single()
  return data ? (data as unknown as SpaceType) : null
}
