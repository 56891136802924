import {
  ActionIcon,
  Box,
  Button,
  Group,
  NavLink,
  Navbar,
  NavbarProps,
  ScrollArea,
  Space,
  Tooltip,
  createStyles
} from "@mantine/core"
import { useMediaQuery } from "@mantine/hooks"
import { DsImageLogo, DsText } from "@raise-sistemas/ds"
import { IconBell, IconPlus, IconSelector } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useChannelsQuery } from "../hooks/queries/useChannelsQuery"
import { useSpaceQuery } from "../hooks/queries/useSpaceQuery"
import { useAuth } from "../hooks/useAuth"
import { useUserPermissions } from "../hooks/useUserPermissions"
import { AppNavLink } from "./AppNavLink"
import { UserButton } from "./UserButton"
import { ChannelCreateEditDrawer } from "./channel/ChannelCreateEditDrawer"

const useStyles = createStyles(theme => ({
  navbar: {
    zIndex: 400,
    paddingTop: 0,
    paddingLeft: 16,
    paddingRight: 15,
    top: 0,
    minHeight: "100vh"
  },
  channelsHeader: {
    padding: `8px ${theme.spacing.xs}px`,
    marginBottom: 5
  },

  channelsHeaderTitle: {
    color:
      theme.colorScheme === "light"
        ? theme.colors.gray[7]
        : theme.colors.gray[1]
  },
  menuDropdown: {
    borderWidth: 0,
    zIndex: 401,
    background:
      theme.colorScheme === "dark" ? theme.colors.dark[8] : theme.colors.white
  }
}))

type AppNavProps = {
  onClose: () => void
} & Omit<NavbarProps, "children">

export const AppNav = (props: AppNavProps) => {
  const { onClose } = props
  const isMobile = useMediaQuery("(max-width: 768px)")
  const { classes } = useStyles()
  const { user } = useAuth()
  const { t } = useTranslation()
  const channels = useChannelsQuery()
  const space = useSpaceQuery(true)
  const navitate = useNavigate()
  const permissions = useUserPermissions()

  const handleClose = () => isMobile && onClose()

  const channelLinks = channels.map(channel => (
    <AppNavLink
      key={`channel-item-navlink-${channel.id}`}
      to={`/channels/${channel.id}/posts`}
      title={channel.data.title}
      icon={channel.data.emoji}
      onClick={handleClose}
    />
  ))

  return (
    <>
      <Navbar width={{ sm: 350 }} p="md" className={classes.navbar} {...props}>
        <Navbar.Section
          sx={() => ({
            "@media only screen and (max-width: 600px)": {
              marginTop: "64px"
            }
          })}
        >
          <NavLink
            styles={{
              label: {
                fontSize: "16px",
                fontWeight: 500
              }
            }}
            onClick={() => navitate("/channels/posts")}
            label={space?.data?.title}
            variant="subtle"
            icon={<DsImageLogo src={space?.data?.logo} size="sm" radius="sm" />}
          ></NavLink>
        </Navbar.Section>
        <Space h={8} />
        <Navbar.Section mt="md">
          <Button
            leftIcon={<IconBell />}
            variant="subtle"
            size="md"
            styles={theme => ({
              root: {
                display: "flex",
                width: "100%",
                border: 0,
                padding: 8,
                height: 42,
                color:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[0]
                    : theme.colors.gray[6]
              },
              leftIcon: {
                marginRight: 15
              }
            })}
          >
            <DsText variant="body-2">{t("root.notifications")}</DsText>
          </Button>
        </Navbar.Section>
        <Navbar.Section mt="md">
          <Group className={classes.channelsHeader} position="apart">
            <DsText
              variant="body-1"
              weight="semi-bold"
              className={classes.channelsHeaderTitle}
            >
              {t("root.community_channels")}
            </DsText>
            {permissions.CAN_SEE_NEW_CHANNEL_BUTTON && (
              <ChannelCreateEditDrawer>
                <Group position="center">
                  <Tooltip
                    label={t("root.create_channel")}
                    withArrow
                    position="right"
                  >
                    <ActionIcon
                      variant="default"
                      size="sm"
                      radius="lg"
                      component="div"
                    >
                      <IconPlus size={12} stroke={1.5} />
                    </ActionIcon>
                  </Tooltip>
                </Group>
              </ChannelCreateEditDrawer>
            )}
          </Group>
        </Navbar.Section>
        <ScrollArea offsetScrollbars style={{ flex: 1 }}>
          <Box onClick={handleClose}>
            <AppNavLink to="/channels/posts" title={t("root.all_posts")} />
            {channelLinks}
          </Box>
        </ScrollArea>

        <Space h={8} />

        <Navbar.Section>
          <UserButton
            image={user?.data.avatar || ""}
            name={user?.data.name || ""}
            email={user?.data.email || ""}
            icon={<IconSelector size={14} stroke={1.5} />}
          />
        </Navbar.Section>
      </Navbar>
    </>
  )
}
