import { MantineSize, Space } from "@mantine/core"

export type DsSpaceProps = {
  height?: MantineSize

  width?: MantineSize
}

export function DsSpace(props: DsSpaceProps) {
  const { height, width } = props

  return <Space h={height} w={width} />
}
