import { Stack, createStyles } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import { showNotification } from "@mantine/notifications"
import {
  DsAvatarAndContent,
  DsBox,
  DsGroup,
  DsSpace,
  DsStackVertical,
  DsText
} from "@raise-sistemas/ds"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { commentEdit } from "../../api/commentEdit"
import { commentLike } from "../../api/commentLike"
import { commentReply } from "../../api/commentReplyCreate"
import { useUserPermissions } from "../../hooks/useUserPermissions"
import { queries } from "../../lib/query-key-factory"
import { CommentType } from "../../models/comment"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { EntityActions } from "../EntityActions"
import { CommentConfirmDeleteModal } from "./CommentConfirmDeleteModal"
import { CommentForm } from "./CommentForm"
import { CommentReply } from "./CommentReply"

export type CommentProps = {
  comment: CommentType
}

const useStyles = createStyles(theme => ({
  comment: {
    background: theme.fn.variant({ variant: "light", color: "gray" })
      .background,
    flex: 1,
    padding: 10,
    borderRadius: 8
  }
}))

export function Comment({ comment }: CommentProps) {
  const permissions = useUserPermissions(comment.user_uuid)
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const formRef = useRef<HTMLInputElement>(null)
  const [newContent, setNewContent] = useState("")
  const [isEdit, isEditHandler] = useDisclosure(false)
  const [formOpened, formHandler] = useDisclosure(false)
  const [confirmDeleteOpened, confirmDeleteHandler] = useDisclosure(false)
  const { classes } = useStyles()
  const { data: replies } = useQuery(
    queries.replies.list({ commentId: comment.id })
  )

  useEffect(() => setNewContent(comment.content), [isEdit])

  // Mutations
  const commentEditMutation = useMutation(commentEdit)
  const commentLikeMutation = useMutation(commentLike)
  const commentReplyMutation = useMutation(commentReply)

  // Handlers
  const handleCommentEdit = (content: string) => {
    return commentEditMutation.mutate(
      {
        id: comment.id,
        content
      },
      {
        onSuccess() {
          isEditHandler.close()
          queryClient.invalidateQueries(
            queries.comments.list({ postId: comment.postId })
          )
        },
        onError() {
          showNotification({
            message: t("validation.comment_edit_fail")
          })
        }
      }
    )
  }

  const handleReply = (content: string) => {
    commentReplyMutation.mutate(
      {
        content,
        parent_id: comment.id
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.comments.list._def)
          queryClient.invalidateQueries(queries.replies.list._def)
          formHandler.close()
        }
      }
    )
  }

  const handleCommentLike = (liked: boolean) => {
    commentLikeMutation.mutate(
      {
        commentId: comment.id,
        liked
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queries.comments._def)
        }
      }
    )
  }

  const handleOpenForm = () => {
    formHandler.open()
    setTimeout(() => {
      formRef.current?.focus()
    }, 100)
  }

  return (
    <>
      <DsGroup position="apart" align="flex-start">
        <DsGroup align="flex-start" style={{ width: "100%" }}>
          <DsBox className={classes.comment}>
            <DsSpace height="sm" />
            <DsStackVertical spacing="xs">
              <DsGroup position="apart">
                <DsBox>
                  <DsAvatarAndContent
                    content="full"
                    name={comment.member.name}
                    id={comment.id}
                    size="md"
                    src={comment.member.avatar}
                  />
                </DsBox>
                {permissions.CAN_SEE_COMMENT_ACTION_BUTTON && (
                  <EntityActions
                    onDelete={confirmDeleteHandler.open}
                    onEdit={() => {
                      formRef.current?.focus()
                      isEditHandler.open()
                    }}
                    hideEdit={!permissions.CAN_SEE_EDIT_COMMENT_BUTTON}
                  />
                )}
              </DsGroup>

              <CommentForm
                ref={formRef}
                isVisible={isEdit}
                actionAlwaysVisible={!!newContent}
                onHidden={isEditHandler.close}
                onSubmit={handleCommentEdit}
                defaultValue={comment.content}
              />
            </DsStackVertical>
            {/* TODO: Rever a necessidade de utilizarmos: dangerouslySetInnerHTML={{__html: comment.content}} 
            Olhar o seguinte link: https://stackoverflow.com/questions/29044518/safe-alternative-to-dangerouslysetinnerhtml                            
            */}
            <Stack ml={55}>
              {!isEdit && (
                <DsText variant="body-2" isWordBreak weight="regular">
                  {comment.content}
                </DsText>
              )}
              <ButtonCommentGroup
                countLike={comment.likesCount?.[0].count}
                liked={comment.likes.length > 0}
                onLike={handleCommentLike}
                onComment={handleOpenForm}
                countComment={comment.repliesCount?.[0].count}
              />

              <CommentForm
                ref={formRef}
                actionAlwaysVisible
                onHidden={formHandler.close}
                onSubmit={handleReply}
                isVisible={formOpened}
              />

              {replies?.map(reply => (
                <CommentReply key={`comment-reply-${reply.id}`} reply={reply} />
              ))}
            </Stack>
          </DsBox>
        </DsGroup>
      </DsGroup>

      <CommentConfirmDeleteModal
        comment={comment}
        opened={confirmDeleteOpened}
        onClose={confirmDeleteHandler.close}
      />
    </>
  )
}
