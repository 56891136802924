import { Button, Center, Grid, Space, Text, Title } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"

import { DsImageLogo } from "@raise-sistemas/ds"
import { getHost } from "../helpers/getHost"
import { useSpaceQuery } from "../hooks/queries/useSpaceQuery"
import { useCountdown } from "../hooks/useCountdown"
import { DEFAULT_SPACE_LOGO } from "../lib/constants"

type VerifyEmailProps = {
  email: string
  type: "signup" | "forgot_password"
  onSubmit: () => void
}

export const VerifyEmail = ({ email, type, onSubmit }: VerifyEmailProps) => {
  const { t } = useTranslation()
  const data = useSpaceQuery()
  const { timeLeft, formatedTime, resetCountdown } = useCountdown(60)

  const handleSubmit = () => {
    resetCountdown()
    onSubmit()
  }

  const currentHost = getHost()

  return (
    <Grid columns={1} m={0} align="center" h="100vh">
      <Grid.Col lg={1} p={0}>
        <Center style={{ display: "flex", flexDirection: "column" }}>
          <DsImageLogo
            size="sm"
            radius="sm"
            src={currentHost === "app" ? DEFAULT_SPACE_LOGO : data?.data?.logo}
            alt={t("logo_image")}
          />
          <Space h="md" />
          <Title align="center" order={3} weight="500">
            {t("auth.email_verify")}
          </Title>
          <Space h="xs" />
          <Text size="xs" align="center" color="#718096">
            {t("auth.verify_link_sent")}
          </Text>
          <Space h="xs" />
          <Text size="xs" align="center">
            {email}
          </Text>
          <Space h="xs" />
          <Button onClick={handleSubmit} disabled={timeLeft > 0}>
            {t("auth.send_again")} {timeLeft > 0 && formatedTime}
          </Button>

          <Space h="xs" />

          {type === `signup` && (
            <Text size="xs" align="center">
              {t("auth.verified")}{" "}
              <Text
                size="xs"
                variant="link"
                component={Link}
                to="/sign-in"
                align="center"
              >
                {t("auth.login")}
              </Text>
            </Text>
          )}
        </Center>
      </Grid.Col>
    </Grid>
  )
}
