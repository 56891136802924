import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { useAuth } from "./useAuth"

type InitialValues = {
  title?: string
  content?: string
  channels?: string[]
}

export const usePostForm = (initialValues?: InitialValues) => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const form = useForm({
    initialValues: {
      title: initialValues?.title || "",
      content: initialValues?.content || "",
      channels: initialValues?.channels || [],
      user_uuid: user!.user_uuid
    },

    validate: {
      channels: value =>
        value.length < 1 ? t("validation.channel_min_requisite") : null
    }
  })

  return form
}
