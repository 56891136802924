import { rand } from "./rand"

// TODO: ao inicializar o app react, receber do backend o último milisegundo gerado + 1
let last_time = Date.now()
let last_rands: BigInt[] = []
// retorna uma string para não precisar trabalhar com BigInt
// |-----------| 13 numbers timestmap: new Date(1662496201761) => 2022-09-06T20:30:01.761Z
// 1662496201761245192 19 chars
//              |----| 6 random numbers 0 to 999_999
// BigInt(1662496201761245192n).toString(36) => "cmplpvj8qj9k" 12 caracters alpha numéricos
export function uid() {
  // o uid começa com o timestamp atual (até o milisegundo)
  const now = Date.now()
  // se o último gerado for num milisegundo diferente, reseta
  if (now !== last_time) {
    last_time = now
    last_rands = []
  }
  // gera um aleatório de 0 à 999_999
  // tenta dinovo se repetir em last_rands
  let bigRand = 0n
  const shift = 1_000_000n
  do {
    bigRand = BigInt(rand()) % shift
  } while (last_rands.includes(bigRand))
  last_rands.push(bigRand)
  const time = BigInt(now) * shift
  const id = time + bigRand

  return id.toString()
}
