import { useCallback, useEffect, useState } from "react"
import { useDebounce } from "./useDebounce"
import { isValidSlug } from "../helpers/isValidSlug"
import { hostCheckAvailable } from "../api/hostCheckAvailable"

export const useAvailableTenant = () => {
  const [tenant, setTenant] = useState("")
  const [isDirty, setIsDirty] = useState(false)
  const tenantDebounced = useDebounce(tenant, 1000)
  const [isLoading, setIsLoading] = useState(false)
  const [availableTenant, setAvailableTenant] = useState(false)

  const getFieldError = useCallback(() => {
    if (tenantDebounced.length < 4) return "Deve ter no mínimo 4 caractéres."
    if (!isValidSlug(tenantDebounced)) return "Subdomínio inválido"
    if (!availableTenant && !isLoading) return "Subdomínio não disponível."
  }, [tenantDebounced, isLoading, availableTenant])

  const registerField = {
    isLoading,
    value: tenant,
    error: isDirty ? getFieldError() : null,
    onChange: (value: string) => {
      setTenant(value)
      setIsDirty(true)
    }
  }

  const validate = () => {
    setIsDirty(true)

    const hasError = !!getFieldError()
    return { hasError }
  }

  useEffect(() => {
    if (tenantDebounced.length > 3) {
      setIsDirty(true)
      setIsLoading(true)
      setAvailableTenant(false)
      hostCheckAvailable(tenantDebounced)
        .then(available => setAvailableTenant(available))
        .finally(() => setIsLoading(false))
    }
  }, [tenantDebounced])

  return {
    isLoading,
    registerField,
    tenant: tenantDebounced,
    availableTenant,
    validate,
    setTenant
  }
}
