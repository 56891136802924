import { Button, Divider, Flex, Space, Text, TextInput } from "@mantine/core"
import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { Link } from "react-router-dom"
import { DsAvatar } from "@raise-sistemas/ds"
import { ImageUpload } from "../../components/ImageUpload"
import { useMemberEditMutation } from "../../hooks/mutations/useMemberEditMutation"
import { useAuth } from "../../hooks/useAuth"

export const SettingsUser = () => {
  const { user } = useAuth()
  const { t } = useTranslation()

  const form = useForm({
    initialValues: {
      name: user?.data.name || ""
    }
  })

  const mutation = useMemberEditMutation()

  const handleUploadAvatar = (url: string | null) => {
    if (!user) return

    if (url) {
      mutation.mutate({
        avatar: url
      })
    }
  }

  return (
    <>
      <Text fw={500} size={16}>
        {t("root.personal_info")}
      </Text>
      <Text fw={400} size={12} c="dimmed">
        {t("root.personal_info_helper_text")}
      </Text>
      <Space h={24} />
      <Divider my="sm" />
      <Space h={24} />
      <TextInput
        label={t("input.name.label")}
        placeholder={t("input.name.placeholder_first_name")}
        value={form.values.name}
        {...form.getInputProps("name")}
      />

      <Space h={26} />
      <Text fw={500} size={14}>
        {t("root.photo")}
      </Text>
      <Space h={8} />
      <Flex gap="md">
        <DsAvatar
          src={user?.data.avatar}
          name={user?.data.name || ""}
          size="md"
        />
        <ImageUpload onUpload={handleUploadAvatar} />
      </Flex>
      <Space h={24} />
      <Text fw={500} size={14}>
        {t("root.safety")}
      </Text>
      <Space h={8} />
      <Button variant="outline" fullWidth component={Link} to="/new-password">
        {t("button.reset_password")}
      </Button>
      <Space h={48} />
      <Flex justify="flex-end">
        <Button
          loading={mutation.isLoading}
          onClick={(e: any) =>
            form.onSubmit(values => mutation.mutate(values))(e)
          }
        >
          {t("button.save")}
        </Button>
      </Flex>
    </>
  )
}
