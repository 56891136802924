import { MantineNumberSize } from "@mantine/core"
import { DsAvatar, DsGroup, DsStackVertical, DsText } from "@raise-sistemas/ds"
import { useDateDistanceToNow } from "../../../../hooks/useDateDistanceToNow"

export type DsAvatarAndContentProps = {
  name: string
  src?: string
  id?: string
  size: "sm" | "md" | "lg"
  content:
    | ("avatar" | "title" | "subtitle" | "full")[]
    | "avatar"
    | "title"
    | "subtitle"
    | "full"
}

const viewerComponent = (
  content: string | string[],
  component: string
): Boolean => {
  const isArray = Array.isArray(content)
  let visible: boolean
  if (isArray) {
    visible = content.includes(component) || content.includes("full")
  } else {
    visible = content === component || content === "full"
  }
  return visible
}

const defaultProps: {
  [key: string]: {
    avatar: MantineNumberSize
    title: {
      color?: string
      variant:
        | "heading-1"
        | "heading-2"
        | "heading-3"
        | "body-1"
        | "body-2"
        | "body-3"
      weight?: "regular" | "medium" | "semi-bold" | "bold"
    }
    description: {
      color?: string
      weight?: "regular" | "medium" | "semi-bold" | "bold"
      variant:
        | "heading-1"
        | "heading-2"
        | "heading-3"
        | "body-1"
        | "body-2"
        | "body-3"
    }
  }
} = {
  sm: {
    avatar: "sm",
    title: {
      variant: "body-3"
    },
    description: {
      variant: "body-3",
      color: "#A0AEC0",
      weight: "regular"
    }
  },
  md: {
    avatar: "md",
    title: {
      variant: "body-3",
      weight: "bold"
    },
    description: {
      variant: "body-3",
      color: "#A0AEC0",
      weight: "regular"
    }
  },
  lg: {
    avatar: "lg",
    title: {
      variant: "body-1",
      weight: "bold"
    },
    description: {
      variant: "body-1",
      color: "#A0AEC0"
    }
  }
}

export function DsAvatarAndContent(props: DsAvatarAndContentProps) {
  const { dateDistanceToNow } = useDateDistanceToNow()
  const { content, size } = props

  return (
    <DsGroup>
      {viewerComponent(content, "avatar") ? (
        <DsAvatar
          size={defaultProps[size].avatar}
          name={props.name}
          src={props.src}
        />
      ) : null}
      <DsStackVertical spacing={0}>
        {viewerComponent(content, "title") ? (
          <DsText
            weight={defaultProps[size].title.weight}
            variant={defaultProps[size].title.variant}
          >
            {props.name}
          </DsText>
        ) : null}
        {viewerComponent(content, "subtitle") ? (
          props.id ? (
            <>
              <DsText
                variant={defaultProps[size].description.variant}
                color={defaultProps[size].description.color}
                weight={defaultProps[size].description.weight}
              >
                {dateDistanceToNow(props.id)}
              </DsText>
            </>
          ) : null
        ) : null}
      </DsStackVertical>
    </DsGroup>
  )
}
