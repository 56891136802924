import { Divider, Stack, TypographyStylesProvider } from "@mantine/core"
import {
  DsBox,
  DsContainer,
  DsDrawer,
  DsGroup,
  DsText,
  DsStackVertical
} from "@raise-sistemas/ds"
import { PostHeader } from "./PostHeader"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { CommentForm } from "../comment/CommentForm"
import { Comments } from "../comment/Comments"
import { useTranslation } from "react-i18next"
import { useCommentCreateMutation } from "../../hooks/mutations/useCommentCreateMutation"
import { usePostLikeMutation } from "../../hooks/mutations/usePostLikeMutation"
import { useRef } from "react"
import { PostModel } from "../../models/post"
import { PostChannelList } from "./PostChannelList"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"

type PostViewDrawerProps = {
  opened: boolean
  onClose: () => void
  post: PostModel
}

export const PostViewDrawer = ({ post, ...rest }: PostViewDrawerProps) => {
  const { t } = useTranslation()
  const mutation = useCommentCreateMutation()

  const { mutate: likeMutate } = usePostLikeMutation()

  const { data: commentsData } = useQuery({
    ...queries.comments.list({
      page: 1,
      limit: 50,
      postId: post.id
    }),
    enabled: rest.opened
  })

  const comments = commentsData || []
  const commentFormRef = useRef<HTMLInputElement>(null)

  const handleFocusComment = () => {
    commentFormRef.current?.focus()
  }

  const handleComment = (content: string) => {
    if (!post) return
    mutation.mutate({
      content,
      parent_id: post.id
    })
  }

  return (
    <DsDrawer
      size={800}
      scroll
      noPadding
      title={
        <DsGroup>
          <DsText weight="bold" variant="body-1">
            Post em
            {/* traduzir depois */}
          </DsText>
          <PostChannelList channel_post={post.channel_post} />
        </DsGroup>
      }
      {...rest}
    >
      <DsContainer fluid style={{ width: "100%" }}>
        <DsBox>
          <Stack>
            <PostHeader post={post} />
            <Stack align="flex-start">
              <TypographyStylesProvider style={{ wordBreak: "break-word" }}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.data.content
                  }}
                />
              </TypographyStylesProvider>
            </Stack>
          </Stack>

          <Divider mt={16} />

          <Stack py={24} spacing={24}>
            <ButtonCommentGroup
              countLike={post.likesCount}
              liked={post.liked}
              onLike={() => likeMutate({ postId: post.id, liked: post.liked })}
              countComment={post.commentsCount}
              position="left"
              onComment={handleFocusComment}
            />
            <DsStackVertical spacing="sm">
              <CommentForm ref={commentFormRef} onSubmit={handleComment} />
              <Comments comments={comments} />
            </DsStackVertical>
          </Stack>
        </DsBox>
      </DsContainer>
    </DsDrawer>
  )
}
