import { getUserId } from "../helpers/getUserId"
import { getTenant } from "../helpers/getTenant"
import { supabase } from "../lib/supabase"
import { uid } from "../lib/uid"
import { KINDS } from "../lib/constants"

export type ChannelCreatePayload = {
  title: string
  emoji: string
}

export async function channelCreate(payload: ChannelCreatePayload) {
  const userId = await getUserId()

  if (!userId) throw new Error("User not found")

  const record = {
    id: uid(),
    tenant: getTenant(),
    kind: KINDS.CHANNEL,
    data: {
      emoji: payload.emoji,
      title: payload.title
    },
    user_uuid: userId
  }

  const { error } = await supabase.from("db").insert(record)

  if (error) throw new Error(error.message)
}
