import { DsButtonLanguage, DsStackVertical } from "@raise-sistemas/ds"
import { useTranslation } from "react-i18next"

const languageOptions = [
  {
    name: "Português",
    value: "ptBR"
  },
  {
    name: "English",
    value: "enUS"
  }
]

export function DebugLanguageSwitcher() {
  const { i18n } = useTranslation()

  return (
    <DsStackVertical spacing="xs">
      {languageOptions.map(languageOption => (
        <DsButtonLanguage
          languageName={languageOption.name}
          isBold={i18n.language === languageOption.value}
          onMouseEnter={() => i18n.changeLanguage(languageOption.value)}
          key={languageOption.value}
        />
      ))}
    </DsStackVertical>
  )
}
