import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import { DsTextInputEmail, DsTextInputPassword } from "@raise-sistemas/ds"
import {
  Button,
  Center,
  Image,
  Space,
  Stack,
  Text,
  TextInput,
  Title
} from "@mantine/core"
import { useSignUpContainer } from "./useSignUpContainer"
import { VerifyEmail } from "../../components/VerifyEmail"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"
import { getHost } from "../../helpers/getHost"
import { DEFAULT_SPACE_LOGO } from "../../lib/constants"

export const PageSignUpMember = () => {
  const data = useSpaceQuery()
  const { t } = useTranslation()
  const { form, handleSubmit, mutation } = useSignUpContainer()

  const currentHost = getHost()

  if (!mutation.isSuccess) {
    return (
      <Center
        style={{ height: "100vh", display: "flex", flexDirection: "column" }}
      >
        <div style={{ marginLeft: "auto", marginRight: "auto" }}>
          <Image
            height={40}
            width={40}
            radius="md"
            src={currentHost === "app" ? DEFAULT_SPACE_LOGO : data?.data?.logo}
            alt={t("logo_image")}
          />
        </div>

        <Space h="md" />
        <Title align="center" order={3} weight="500">
          {t("auth.sign_up")}
        </Title>
        <Space h="xs" />
        <Text size="xs" align="center" color="#718096">
          {t("auth.signup_to_participate")}
        </Text>

        <Space h="xs" />

        <Stack
          sx={() => ({
            width: "360px",
            "@media (max-width: 600px)": {
              width: "280px"
            }
          })}
        >
          <TextInput
            type="text"
            {...form.getInputProps("name")}
            label={t("input.name.label")}
            placeholder={t("input.name.placeholder_first_name")}
          />
          <DsTextInputEmail
            {...form.getInputProps("email")}
            label={t("input.email.label")}
            placeholder={t("input.email.placeholder_write_email")}
          />
          <DsTextInputPassword
            {...form.getInputProps("password")}
            isVisible
            label={t("input.password.label")}
            placeholder={t("input.password.placeholder")}
          />
          <DsTextInputPassword
            {...form.getInputProps("repeat_password")}
            label={t("input.repeat_password.label")}
            placeholder={t("input.repeat_password.placeholder")}
          />

          <Space h="xs" />

          <Button onClick={handleSubmit} loading={mutation.isLoading}>
            {t("button.create_account")}
          </Button>

          <Text size="xs" align="center">
            {t("root.account_question")}{" "}
            <Text
              size="xs"
              variant="link"
              component={Link}
              to="/sign-in"
              align="center"
            >
              {t("auth.login")}
            </Text>
          </Text>
        </Stack>
      </Center>
    )
  }
  return (
    <VerifyEmail
      email={form.values.email}
      type="signup"
      onSubmit={handleSubmit}
    />
  )
}
