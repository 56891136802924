import { Title, TitleProps } from "@mantine/core"

export type DsTextHeadingProps = Pick<
  TitleProps,
  "children" | "underline" | "order" | "color" | "style" | "className"
> & {
  weight?: "regular" | "medium" | "semi-bold" | "bold" | number
  size?: "xs" | "sm" | "md" | number
}

export function DsTextHeading(props: DsTextHeadingProps) {
  return <Title {...props} />
}
