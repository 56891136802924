import { BubbleMenu } from "@tiptap/react"
import { BsCodeSlash } from "react-icons/bs"
import { useTranslation } from "react-i18next"
import { FiEdit3, FiType } from "react-icons/fi"
import { Box, createStyles, Group, Text } from "@mantine/core"
import { ImageControl } from "../editor/extensions/PhotoControl"
import { VideoControl } from "../editor/extensions/VideoControl"
import { RichTextEditor, RichTextEditorProps } from "@mantine/tiptap"
import { LinkPreviewControl } from "../editor/extensions/LinkPreviewControl"

type PostEditorProps = Pick<RichTextEditorProps, "editor"> & {
  error?: string | null
}

const useStyles = createStyles(theme => {
  const isDark = theme.colorScheme === "dark"
  const borderColor = isDark ? theme.colors.dark[5] : theme.colors.gray[3]
  return {
    root: {
      border: 0,
      padding: 0
    },
    rootError: {
      padding: 16,
      border: `1px solid ${theme.colors.red[5]}`
    },
    toolbar: {
      borderBottom: 0,
      borderRadius: 0,
      border: `1px solid ${borderColor}`,
      borderWidth: "1px 0px 1px 0px",
      padding: "8px 0",
      flexWrap: "nowrap",
      overflowX: "scroll",
      WebkitOverflowScrolling: "touch",

      "&::-webkit-scrollbar": {
        display: "none"
      }
    },
    content: {
      padding: "16px 0"
    },
    controlsGroup: {
      flexWrap: "nowrap"
    },
    control: {
      border: 0,
      width: 35,
      height: 35,
      backgroundColor: isDark ? theme.colors.dark[7] : "white",

      "&>svg": {
        width: 18,
        height: 18
      }
    },
    divider: {
      width: 1,
      height: 24,
      display: "block",
      background: borderColor
    }
  }
})

export const PostEditor = ({ editor, error }: PostEditorProps) => {
  const { t } = useTranslation()
  const { classes } = useStyles()

  return (
    <Box>
      <RichTextEditor
        editor={editor}
        classNames={{
          root: !!error ? classes.rootError : classes.root,
          toolbar: classes.toolbar,
          content: classes.content,
          controlsGroup: classes.controlsGroup,
          control: classes.control
        }}
        labels={{
          linkEditorSave: t("button.save"),
          linkEditorExternalLink: t("tooltip.target_blank"),
          linkEditorInternalLink: t("tooltip.target_self")
        }}
      >
        <RichTextEditor.Toolbar sticky stickyOffset={-20}>
          <Group spacing={2} className={classes.controlsGroup}>
            <RichTextEditor.H2 icon={() => <FiType />} />
            <RichTextEditor.H3 icon={() => <FiType size={12} />} />
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.Underline />
            <RichTextEditor.Strikethrough />
            <RichTextEditor.Blockquote />
          </Group>

          <Box className={classes.divider} />

          <Group spacing={2} className={classes.controlsGroup}>
            <RichTextEditor.Highlight
              icon={() => <FiEdit3 strokeWidth={1.2} />}
            />
            <RichTextEditor.AlignLeft />
            <RichTextEditor.AlignCenter />
            <RichTextEditor.AlignRight />
            <RichTextEditor.BulletList />
            <RichTextEditor.OrderedList />
          </Group>

          <Box className={classes.divider} />

          <Group spacing={2} className={classes.controlsGroup}>
            <ImageControl />
            <VideoControl />
            <RichTextEditor.Link />
            <LinkPreviewControl />
          </Group>

          <Box className={classes.divider} />

          <Group spacing={2} className={classes.controlsGroup}>
            <RichTextEditor.Hr />
            <RichTextEditor.Code icon={() => <BsCodeSlash />} />
          </Group>
        </RichTextEditor.Toolbar>

        {editor && (
          <BubbleMenu editor={editor}>
            <RichTextEditor.ControlsGroup>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Link />
              <RichTextEditor.Unlink />
            </RichTextEditor.ControlsGroup>
          </BubbleMenu>
        )}

        <RichTextEditor.Content />
      </RichTextEditor>
      {!!error && (
        <Text color="red" size="xs">
          {error}
        </Text>
      )}
    </Box>
  )
}
