import { DsGroup, DsGroupProps } from "@raise-sistemas/ds"
import { ButtonComment } from "./ButtonComment"
import { ButtonLike } from "./ButtonLike"

export type ButtonCommentGroupProps = Pick<DsGroupProps, "position"> & {
  visibleContent?: "like" | "full"
  countLike: number
  liked: boolean
  onLike: (liked: boolean) => Promise<void> | void
  countComment?: number
  onComment?: () => void
}

export function ButtonCommentGroup(props: ButtonCommentGroupProps) {
  const { position, ...rest } = props
  return (
    <DsGroup position={position} spacing={20}>
      <ButtonLike
        count={rest.countLike}
        liked={rest.liked}
        onLike={rest.onLike}
      />
      {rest.visibleContent === "like" ? null : (
        <ButtonComment count={rest.countComment} onClick={rest.onComment} />
      )}
    </DsGroup>
  )
}
