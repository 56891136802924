import { useAuth } from "../hooks/useAuth"
import { Navigate } from "react-router-dom"
import { PageFallback } from "./PageFallback"

export const PageOAuth = () => {
  const { signed, isLoading } = useAuth()

  if (isLoading) return <PageFallback />

  if (!signed) return <Navigate to="/sign-in" />

  return <Navigate to="/channels/posts" />
}
