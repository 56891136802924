import { IconMoon, IconSun } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { useMantineColorScheme, Button, Menu } from "@mantine/core"

export function ThemeSwitch() {
  const { colorScheme, toggleColorScheme } = useMantineColorScheme()
  const { t } = useTranslation()
  const Icon = colorScheme === "dark" ? IconSun : IconMoon
  const text =
    colorScheme === "dark" ? t("root.theme_light") : t("root.theme_dark")

  return (
    <Menu.Item
      aria-label="Toggle theme"
      onClick={() => toggleColorScheme()}
      title="Ctrl + J"
      icon={<Icon size={14} />}
    >
      {text}
    </Menu.Item>
  )
}
