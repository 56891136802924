import { DsButtonBadge } from "@raise-sistemas/ds"
import { useNavigateTo } from "../../../src/helpers/navigateTo"
import { ChannelType } from "../../models/channel"

type PostChannelButtonProps = {
  channel: ChannelType
}

export const PostChannelButton = ({ channel }: PostChannelButtonProps) => {
  const navigate = useNavigateTo()
  return (
    <DsButtonBadge
      title={channel.data.title}
      emoji={channel.data.emoji}
      onClick={() => navigate(`/channels/${channel.id}/posts`)}
    />
  )
}
