import { Session } from "@supabase/supabase-js"
import {
  createContext,
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useEffect,
  useState
} from "react"
import { memberCreate } from "../api/memberCreate"
import { supabase } from "../lib/supabase"
import { setUserId } from "../helpers/setUserId"
import { MemberType } from "../models/member"
import { memberById } from "../api/memberById"
import { useUnexpectedError } from "./errors/useUnexpectedError"
import { getHost } from "../helpers/getHost"
import { spaceCreatorByUserId } from "../api/spaceCreatorByUserId"
import { STORAGE_KEYS } from "../lib/constants"

type User = MemberType | null

type AuthContextData = {
  signed: boolean
  isLoading: boolean
  user?: User
  setUser: Dispatch<SetStateAction<User | undefined>>
}

const AuthContext = createContext({} as AuthContextData)

const AuthProvider = ({ children }: PropsWithChildren<unknown>) => {
  const [user, setUser] = useState<User>()
  const [isLoading, setIsLoading] = useState(true)
  const unexpectedError = useUnexpectedError()
  const currentHost = getHost()

  const values = {
    signed: !!user,
    isLoading,
    user,
    setUser
  }

  const userParse = (user: Session["user"]) => {
    return {
      avatar: user.user_metadata?.avatar_url || "",
      name: user.user_metadata?.name || "",
      email: user.email || "",
      created_at: new Date().toISOString()
    }
  }

  const getTenant = async () => {
    try {
      const space = await spaceCreatorByUserId()
      return space.tenant
    } catch (error) {
      return ""
    }
  }

  const handleSignIn = async (session: Session | null) => {
    if (!session) return

    if (currentHost === "app") {
      const data = userParse(session.user)
      setUserId(session.user.id)

      const tenant = await getTenant()

      setUser({
        data,
        tenant,
        role: "owner",
        user_uuid: session.user.id
      })

      if (tenant) localStorage.setItem(STORAGE_KEYS.TENANT_ID, tenant)

      return
    }

    const { data: memberData, error: memberError } = await memberById(
      session.user.id
    )

    if (memberData) {
      setUserId(memberData.user_uuid)
      setUser(memberData as MemberType)
    }

    if (memberError) {
      const { error: joinError } = await memberCreate({
        role: "member",
        user_uuid: session.user.id,
        data: userParse(session.user)
      })

      if (joinError) unexpectedError()
    }
  }

  useEffect(() => {
    ;(async () => {
      const { data } = await supabase.auth.getSession()
      await handleSignIn(data.session)
      setIsLoading(false)
    })()

    const { data } = supabase.auth.onAuthStateChange(async (event, session) => {
      if (event === "SIGNED_IN" || event === "TOKEN_REFRESHED") {
        await handleSignIn(session)
      }

      if (event === "SIGNED_OUT") setUser(null)
    })

    return () => {
      data.subscription.unsubscribe()
    }
  }, [])

  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>
}

const useAuth = () => {
  const context = useContext(AuthContext)

  if (!context) throw new Error("useAuth must be used within an AuthProvider")

  return context
}

export { AuthProvider, AuthContext, useAuth }
