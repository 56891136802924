import { supabase } from "../lib/supabase"
import { getHost } from "../helpers/getHost"

const getTenantOrThrow = async () => {
  const host = getHost()

  const { data, error } = await supabase
    .from("tenant")
    .select("*")
    .eq("id", host)
    .single()

  if (error) throw error
  return data.id
}

const getTenantByHostOrThrow = async () => {
  const host = getHost()

  const { data, error } = await supabase
    .from("host")
    .select("*")
    .eq("id", host)
    .single()

  if (error) throw error
  return data.tenant
}

export const tenantByHost = async () => {
  const host = getHost()
  return host.includes(".") ? getTenantByHostOrThrow() : getTenantOrThrow()
}
