import {
  Divider,
  Stack,
  TypographyStylesProvider,
  createStyles
} from "@mantine/core"
import { useTranslation } from "react-i18next"
import { usePostLikeMutation } from "../../hooks/mutations/usePostLikeMutation"
import { PostModel } from "../../models/post"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { PostHeader } from "./PostHeader"
import { PostViewDrawer } from "./PostViewDrawer"
import { useDisclosure } from "@mantine/hooks"

type PostItemProps = {
  post: PostModel
}

const useStyles = createStyles(theme => ({
  card: {
    position: "relative",
    padding: "0 16px",
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      padding: "0 32px"
    }
  }
}))

export function PostItem({ post }: PostItemProps) {
  const { t } = useTranslation()
  const { classes } = useStyles()
  const { mutate: likeMutate } = usePostLikeMutation()

  const [opened, handler] = useDisclosure(false)

  return (
    <>
      <Stack className={classes.card} my={16}>
        <PostHeader post={post} />
        <TypographyStylesProvider style={{ wordBreak: "break-word" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: post.data.content
            }}
          />
        </TypographyStylesProvider>
        <Divider color="gray.5" />
        <ButtonCommentGroup
          position="left"
          countLike={post.likesCount}
          liked={post.liked}
          onLike={liked => likeMutate({ postId: post.id, liked })}
          countComment={post.commentsCount}
          onComment={handler.open}
        />
      </Stack>
      <PostViewDrawer opened={opened} onClose={handler.close} post={post} />
    </>
  )
}
