import { getTenant } from "../helpers/getTenant"
import { supabase } from "../lib/supabase"

export const hasUser = async (email: string) => {
  const tenant = getTenant()
  const { count } = await supabase
    .from("member")
    .select("*", { count: "exact", head: true })
    .eq("tenant", tenant)
    .contains("data", { email })

  return count ? count > 0 : false
}
