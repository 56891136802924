import { BASE_URL } from "../env"
import { supabase } from "../lib/supabase"

export type ForgotPasswordPayload = {
  email: string
}

export const forgotPassword = async (payload: ForgotPasswordPayload) => {
  const { error } = await supabase.auth.resetPasswordForEmail(payload.email, {
    redirectTo: `${BASE_URL}/new-password`
  })

  if (error) throw error
}
