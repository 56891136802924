import { Text, TextProps } from "@mantine/core"

export type DsTextBodyProps = Pick<
  TextProps,
  "children" | "underline" | "color" | "style" | "className"
> & {
  weight?: "regular" | "medium" | "semi-bold" | "bold" | number
  size?: "xs" | "sm" | "md" | number
}

export function DsTextBody(props: DsTextBodyProps) {
  return <Text weight={props.weight} {...props} />
}
