import { DsButtonBase, DsButtonBaseProps } from "../DsButtonBase/DsButtonBase"

export type DsButtonIconProps = Pick<
  DsButtonBaseProps,
  "children" | "onClick" | "leftIcon"
>

export function DsButtonIcon(props: DsButtonIconProps) {
  return <DsButtonBase {...props} p={0} variant="subtle" compact />
}
