export const STORAGE_KEYS = {
  USER_ID: "@luminy/user-id",
  TENANT_ID: "@luminy/tenant-id",
  PRIMARY_COLOR: "@luminy/primary-color"
}

export const ROLES = {
  OWNER: "owner",
  MEMBER: "member",
  MEMBER_VIP: "member_vip",
  MODERATOR: "moderator"
}

export const DEFAULT_SPACE_LOGO =
  "https://s3.us-east-1.wasabisys.com/assets.atos6.com.br/atos6-logo"

export const KINDS = {
  MEMBER_CONFIG: "member_config",
  CHANNEL_POST: "channel_post",
  POST_COMMENT: "post_comment",
  COMMENT_LIKE: "comment_like",
  COMMENT_REPLY: "comment_reply",
  POST: "post",
  POST_LIKE: "post_like",
  SPACE: "space",
  REPLY_LIKE: "reply_like",
  SPACE_CONFIG: "space_config",
  CHANNEL: "channel"
}

export const IS_PRODUCTION = import.meta.env.PROD

export const HOSTS_DEV = ["localhost", "lvh.me", "127.0.0.1"]

export const MS_SUBDOMAIN_URL = "https://ms-deno-lab.deno.dev"

export const MAIN_HOST = "mycomu.com"
