import { Stack, createStyles } from "@mantine/core"
import {
  Dropzone,
  DropzoneProps,
  FileWithPath,
  IMAGE_MIME_TYPE
} from "@mantine/dropzone"
import { DsText } from "@raise-sistemas/ds"
import { uploadImage } from "../api/uploadImage"
import { IconCloudUpload, IconUpload, IconX } from "@tabler/icons"

const useStyles = createStyles(theme => ({
  container: {
    minHeight: 126,
    width: "100%",
    pointerEvents: "none"
  },
  iconAccept: {
    color:
      theme.colors[theme.primaryColor][theme.colorScheme === "dark" ? 4 : 6]
  },
  iconReject: {
    color: theme.colors.red[theme.colorScheme === "dark" ? 4 : 6]
  }
}))

type ImageUploadProps = Partial<DropzoneProps> & {
  onUpload: (url: string | null) => void
}

export const ImageUpload = ({ onUpload, ...rest }: ImageUploadProps) => {
  const { classes } = useStyles()

  const handleUpload = async (files: FileWithPath[]) => {
    const image = files[0]

    const url = await uploadImage(image)

    return onUpload(url)
  }

  return (
    <Dropzone
      onDrop={handleUpload}
      onReject={files => console.log("rejected files", files)}
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Exponentiation
      // Equivalente a 3 * 1024 * 1024
      maxSize={3 * 1024 ** 2}
      accept={IMAGE_MIME_TYPE}
      {...rest}
    >
      <Stack align="center" className={classes.container}>
        <Dropzone.Accept>
          <IconUpload size={40} stroke={1.5} className={classes.iconAccept} />
        </Dropzone.Accept>

        <Dropzone.Reject>
          <IconX size={40} stroke={1.5} className={classes.iconReject} />
        </Dropzone.Reject>

        <Dropzone.Idle>
          <IconCloudUpload size={40} stroke={1.5} />
        </Dropzone.Idle>

        <DsText variant="body-2">Clique para fazer o upload ou arraste</DsText>
        <DsText variant="body-3">JPG ou PNG</DsText>
      </Stack>
    </Dropzone>
  )
}
