import { FormEvent, useCallback } from "react"
import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { useDisclosure } from "@mantine/hooks"
import { VscLink } from "react-icons/vsc"
import { Button, createStyles, Popover, TextInput } from "@mantine/core"
import { RichTextEditor, useRichTextEditorContext } from "@mantine/tiptap"
import { isValidUrl } from "../../../helpers/isValidUrl"

const useStyles = createStyles(() => ({
  linkEditor: {
    display: "flex"
  },
  linkEditorInput: {
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
    borderRight: 0
  },
  linkEditorSave: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0
  }
}))

export const LinkPreviewControl = () => {
  const { classes } = useStyles()
  const { editor } = useRichTextEditorContext()
  const [opened, handlers] = useDisclosure(false)
  const { t } = useTranslation()
  const form = useForm({
    initialValues: {
      url: ""
    },
    validate: {
      url: value => (isValidUrl(value) ? null : "URL inválida")
    }
  })
  const handleClose = () => {
    handlers.close()
    form.reset()
  }

  const addLinkPreview = useCallback(async () => {
    const options = {
      method: "GET",
      headers: {
        "X-RapidAPI-Key": "a317de5373mshf84d6b9bd9ee201p1e2d01jsnd88f4b45b0bd",
        "X-RapidAPI-Host": "link-preview4.p.rapidapi.com"
      }
    }

    const response = await fetch(
      `https://link-preview4.p.rapidapi.com/min?url=${form.values.url}&oembed=false`,
      options
    )

    const { title, description, cover, url } = await response.json()

    editor
      .chain()
      .focus()
      .setLinkPreview({ title, description, cover, href: url })
      .run()
  }, [editor, handleClose, form.values.url])

  return (
    <Popover
      width={300}
      trapFocus
      shadow="md"
      withinPortal
      zIndex={10000}
      offset={-44}
      opened={opened}
      onClose={handleClose}
    >
      <Popover.Target>
        <RichTextEditor.Control
          aria-label="Add link"
          title="Add link"
          onClick={handlers.open}
        >
          <VscLink size={16} />
        </RichTextEditor.Control>
      </Popover.Target>
      <Popover.Dropdown
        sx={theme => ({
          background:
            theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white
        })}
      >
        <div className={classes.linkEditor}>
          <TextInput
            classNames={{ input: classes.linkEditorInput }}
            placeholder="https://example.com"
            type="url"
            {...form.getInputProps("url")}
          />
          <Button
            variant="default"
            className={classes.linkEditorSave}
            onClick={(e: unknown) =>
              form.onSubmit(addLinkPreview)(e as FormEvent<HTMLFormElement>)
            }
          >
            {t("button.save")}
          </Button>
        </div>
      </Popover.Dropdown>
    </Popover>
  )
}
