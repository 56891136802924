import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

type CommentEditPayload = {
  content: string
  id: string
}

export async function commentEdit({ content, id }: CommentEditPayload) {
  const { error } = await supabase
    .from("db")
    .update({
      data: {
        content: content
      }
    })
    .eq("kind", KINDS.POST_COMMENT)
    .eq("id", id)

  if (error) throw error
}
