import { Box, BoxProps } from "@mantine/core"

export type DsBoxProps = Pick<
  BoxProps,
  "classNames" | "children" | "sx" | "styles" | "className" | "style"
> & {
  id?: string
  component?: any
}

export function DsBox(props: DsBoxProps) {
  return <Box {...props} />
}
