import { Group, GroupProps } from "@mantine/core"

export type DsGroupProps = Pick<
  GroupProps,
  | "align"
  | "style"
  | "styles"
  | "classNames"
  | "className"
  | "spacing"
  | "children"
  | "position"
  | "noWrap"
>

export function DsGroup(props: DsGroupProps) {
  return <Group {...props} />
}
