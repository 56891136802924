import { CommentType } from "../../models/comment"

import { DsBox, DsStackVertical } from "@raise-sistemas/ds"
import { Comment } from "./Comment"

type CommentsProps = {
  comments: CommentType[]
}

export function Comments({ comments }: CommentsProps) {
  return (
    <DsBox id="comments" component="section">
      <DsStackVertical spacing="xs">
        {comments.map((comment, index) => {
          return (
            <DsBox key={`comment-${index}`}>
              <Comment comment={comment} />
            </DsBox>
          )
        })}
      </DsStackVertical>
    </DsBox>
  )
}
