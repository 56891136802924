import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"

export type ChannelEditPayload = {
  id: string
  title: string
  emoji: string
}

export async function channelEdit(payload: ChannelEditPayload) {
  const record = {
    tenant: getTenant(),
    kind: KINDS.CHANNEL,
    data: {
      emoji: payload.emoji,
      title: payload.title
    }
  }

  const { error } = await supabase
    .from("db")
    .update(record)
    .eq("id", payload.id)

  if (error) throw new Error(error.message)
}
