import { Modal, ModalProps, createStyles } from "@mantine/core"
import { DsStackVertical } from "@raise-sistemas/ds"

export type DsModalProps = Pick<ModalProps, "title" | "opened" | "onClose"> & {
  main?: string | React.ReactNode
  footer?: string | React.ReactNode
}

const useStyles = createStyles(theme => ({
  modal: {
    display: "flex",
    gap: theme.spacing.lg,
    flexDirection: "column"
  }
}))

export function DsModal(props: DsModalProps) {
  const { classes } = useStyles()
  return (
    <Modal className={classes.modal} centered zIndex="1000" {...props}>
      <DsStackVertical spacing="xl">
        {props.main ? props.main : null}
        {props.footer ? props.footer : null}
      </DsStackVertical>
    </Modal>
  )
}
