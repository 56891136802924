import { ReactNode } from "react"
import { useTranslation } from "react-i18next"
import { ActionIcon, Menu } from "@mantine/core"
import { IoEllipsisVertical } from "react-icons/io5"

type Props = {
  onDelete: () => void
  onEdit: () => void
  customTargetIcon?: ReactNode
  classNames?: {
    target: string
  }
  hideEdit?: boolean
}

export const EntityActions = (props: Props) => {
  const { onDelete, onEdit, classNames, hideEdit = false } = props
  const { t } = useTranslation()

  return (
    <Menu width={240} shadow="md" position={"bottom"}>
      <Menu.Target>
        <ActionIcon className={classNames?.target}>
          {<IoEllipsisVertical size={16} />}
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        {!hideEdit && (
          <>
            <Menu.Item onClick={onEdit}>{t("button.edit")}</Menu.Item>
            <Menu.Divider />
          </>
        )}
        <Menu.Item color="red" onClick={onDelete}>
          {t("button.delete")}
        </Menu.Item>
      </Menu.Dropdown>
    </Menu>
  )
}
