import { SettingsUser } from "./SettingsUser"
import { useTranslation } from "react-i18next"
import { SettingsSpace } from "./SettingsSpace"
import { SettingsMembers } from "./SettingsMembers"
import { Container, Space, Tabs, Title, useMantineTheme } from "@mantine/core"
import { useUserPermissions } from "../../hooks/useUserPermissions"

export function PageSettings() {
  const { t } = useTranslation()
  const permissions = useUserPermissions()

  const { primaryColor } = useMantineTheme()

  return (
    <Container>
      <Title fw={500} size={32}>
        {t("root.settings")}
      </Title>

      <Space h={32} />

      <Tabs color={primaryColor} radius="xs" defaultValue="user">
        <Tabs.List>
          <Tabs.Tab value="user">{t("root.personal_info")}</Tabs.Tab>
          {permissions.CAN_SEE_SETTINGS_MEMBERS_TAB &&
            permissions.CAN_SEE_SETTINGS_SPACE_TAB && (
              <>
                <Tabs.Tab value="space">{t("root.community_info")}</Tabs.Tab>
                <Tabs.Tab value="members">{t("root.members")}</Tabs.Tab>
              </>
            )}
        </Tabs.List>

        <Tabs.Panel value="user" py={56}>
          <SettingsUser />
        </Tabs.Panel>

        {permissions.CAN_SEE_SETTINGS_MEMBERS_TAB &&
          permissions.CAN_SEE_SETTINGS_SPACE_TAB && (
            <>
              <Tabs.Panel value="space" py={56}>
                <SettingsSpace />
              </Tabs.Panel>

              <Tabs.Panel value="members" py={56}>
                <SettingsMembers />
              </Tabs.Panel>
            </>
          )}
      </Tabs>
    </Container>
  )
}
