import { TextProps } from "@mantine/core"
import { fontSize, weights } from "../DsTokens"
import { DsTextBody, DsTextBodyProps } from "./DsTextBody/DsTextBody"
import {
  DsTextHeading,
  DsTextHeadingProps
} from "./DsTextHeading/DsTextHeading"

export type DsTextProps = Pick<TextProps, "className"> & {
  children: string | number
  isWordBreak?: boolean
  weight?: "regular" | "medium" | "semi-bold" | "bold"
  color?: string
  variant:
    | "heading-1"
    | "heading-2"
    | "heading-3"
    | "body-1"
    | "body-2"
    | "body-3"
}
const textBodyVariables = ["body"]

export function DsText(props: DsTextProps) {
  const { children, variant, isWordBreak, color, weight, className } = props
  const variants = {
    "heading-1": {
      size: fontSize["font-size-48"],
      order: 1,
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextHeadingProps,
    "heading-2": {
      size: fontSize["font-size-32"],
      order: 2,
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextHeadingProps,
    "heading-3": {
      size: fontSize["font-size-24"],
      order: 3,
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextHeadingProps,
    "body-1": {
      size: fontSize["font-size-16"],
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextBodyProps,
    "body-2": {
      size: fontSize["font-size-14"],
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextBodyProps,
    "body-3": {
      size: fontSize["font-size-12"],
      weight: weight ? weights[weight] : "regular",
      color: color && color,
      style: { wordBreak: isWordBreak ? "break-word" : "normal" }
    } as DsTextBodyProps
  }
  const newProps = { ...variants[variant], children, className }
  return (
    <>
      {textBodyVariables.includes(variant) ? (
        <DsTextBody {...newProps} />
      ) : (
        <DsTextHeading {...newProps} />
      )}
    </>
  )
}
