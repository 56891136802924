import { useQueryClient } from "@tanstack/react-query"
import { useEffect } from "react"
import { useParams, Navigate, useRevalidator } from "react-router-dom"
import { hasTenant } from "../api/hasTenant"

export const PageTenant = () => {
  const params = useParams() as { tenant: string }
  const queryClient = useQueryClient()
  const revalidator = useRevalidator()

  useEffect(() => {
    async function load() {
      const value = await hasTenant(params.tenant)
      if (value) {
        window.sessionStorage.setItem("tenant", params.tenant)
        queryClient.removeQueries()
        revalidator.revalidate()
      }
    }

    load()
  }, [])

  return <Navigate to="/channels/posts" />
}
