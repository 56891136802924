import { Stack, StackProps } from "@mantine/core"

export type DsStackBaseProps = Pick<
  StackProps,
  "classNames" | "styles" | "children" | "className"
> & {
  spacing?: number | "xs" | "sm" | "md" | "lg" | "xl"
  align?: React.CSSProperties["alignItems"]
  justify?: React.CSSProperties["justifyContent"]
}

export function DsStackBase(props: DsStackBaseProps) {
  return <Stack {...props} />
}
