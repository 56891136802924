import { Stack } from "@mantine/core"
import { useDisclosure } from "@mantine/hooks"
import {
  DsAvatarAndContent,
  DsBox,
  DsButtonPrimary,
  DsButtonSecondary,
  DsGroup,
  DsStackVertical,
  DsText,
  DsTextarea
} from "@raise-sistemas/ds"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { commentReplyEdit } from "../../api/commentReplyEdit"
import { replyLike } from "../../api/replyLike"
import { toastError } from "../../helpers/toastError"
import { useUserPermissions } from "../../hooks/useUserPermissions"
import { queries } from "../../lib/query-key-factory"
import { ReplyType } from "../../models/comment"
import { ButtonCommentGroup } from "../ButtonCommentGroup"
import { EntityActions } from "../EntityActions"
import { CommentReplyConfirmDeleteModal } from "./CommentReplyConfirmDeleteModal"

export type ReplyProps = {
  reply: ReplyType
}

export function CommentReply({ reply }: ReplyProps) {
  const permissions = useUserPermissions(reply.user_uuid)
  const { t } = useTranslation()
  const [isEdit, setIsEdit] = useState(false)
  const [newContent, setNewContent] = useState("")
  const [opened, handler] = useDisclosure(false)
  const queryClient = useQueryClient()
  const replyLikeMutation = useMutation({
    mutationFn: replyLike,
    onSuccess: () => {
      queryClient.invalidateQueries(queries.replies.list._def)
    }
  })

  const editReply = useMutation(commentReplyEdit, {
    onError() {
      toastError({
        message: t("error.message.reply_edit_fail")
      })
    },
    onSuccess() {
      setIsEdit(false)
      queryClient.invalidateQueries(queries.comments._def)
      queryClient.invalidateQueries(queries.replies.list._def)
    }
  })

  const handleEdit = () => setIsEdit(true)

  const handleSubmit = async () => {
    editReply.mutate({
      id: reply.id,
      content: newContent
    })
  }
  const handleCancel = () => {
    setNewContent("")
    setIsEdit(false)
  }

  useEffect(() => setNewContent(reply.content), [isEdit])

  return (
    <>
      <DsGroup position="apart" align="flex-start">
        <DsGroup align="flex-start" style={{ width: "100%" }}>
          <DsBox style={{ flex: 1 }}>
            <DsStackVertical spacing="md">
              <DsGroup position="apart">
                <DsBox>
                  <DsAvatarAndContent
                    content="full"
                    name={reply.member.name}
                    id={reply.id}
                    size="md"
                    src={reply.member.avatar}
                  />
                </DsBox>

                {permissions.CAN_SEE_REPLY_ACTION_BUTTON && (
                  <EntityActions
                    onDelete={handler.open}
                    onEdit={handleEdit}
                    hideEdit={!permissions.CAN_SEE_EDIT_REPLY_BUTTON}
                  />
                )}
              </DsGroup>
              <DsStackVertical spacing="md">
                {isEdit && (
                  <DsBox>
                    <DsStackVertical spacing="sm">
                      <DsTextarea
                        value={newContent}
                        onChange={event => setNewContent(event.target.value)}
                      />
                      {!!newContent && (
                        <>
                          <DsGroup position="right">
                            <DsButtonSecondary onClick={handleCancel}>
                              {t("button.cancel")}
                            </DsButtonSecondary>
                            <DsButtonPrimary onClick={handleSubmit}>
                              {t("button.save")}
                            </DsButtonPrimary>
                          </DsGroup>
                        </>
                      )}
                    </DsStackVertical>
                  </DsBox>
                )}
                <Stack ml={55}>
                  {!isEdit && (
                    <DsText variant="body-2" weight="regular" isWordBreak>
                      {reply.content}
                    </DsText>
                  )}

                  <ButtonCommentGroup
                    visibleContent="like"
                    countLike={reply.likesCount?.[0].count}
                    liked={reply.likes.length > 0}
                    onLike={liked =>
                      replyLikeMutation.mutate({ liked, replyId: reply.id })
                    }
                  />
                </Stack>
              </DsStackVertical>
            </DsStackVertical>
          </DsBox>
        </DsGroup>
      </DsGroup>
      <CommentReplyConfirmDeleteModal
        reply={reply}
        opened={opened}
        onClose={handler.close}
      />
    </>
  )
}
