import { ColorScheme } from "@mantine/core"
import { useLocalStorage } from "@mantine/hooks"

export const useColorScheme = () => {
  return useLocalStorage<ColorScheme>({
    key: "luminy-color-scheme",
    defaultValue: "light",
    getInitialValueInEffect: true
  })
}
