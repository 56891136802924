import { useForm } from "@mantine/form"
import { useTranslation } from "react-i18next"
import { SigninPayload } from "../api/signIn"

export const useSignInMemberForm = (initialValues?: SigninPayload) => {
  const { t } = useTranslation()
  const form = useForm({
    initialValues: {
      email: initialValues?.email || "",
      password: initialValues?.password || ""
    },

    validate: {
      email: value =>
        /^\S+@\S+$/.test(value) ? null : t("input.email.invalid_email"),
      password: value =>
        value.length < 6 ? t("input.password.invalid_password") : null
    }
  })

  return form
}
