import {
  CheckIcon,
  ColorSwatch,
  createStyles,
  SimpleGrid,
  useMantineTheme
} from "@mantine/core"
import { usePrimaryColor } from "../hooks/theme/usePrimaryColor"

const useStyles = createStyles(() => ({
  mainButton: {
    width: 24,
    height: 24,
    borderRadius: 8
  },
  button: {
    "&:hover": {
      cursor: "pointer"
    }
  }
}))

type AppColorSwatchGridProps = {
  value: string
  onChange: (value: string) => void
}

export const AppColorSwatchGrid = (props: AppColorSwatchGridProps) => {
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const [primaryColor, setPrimaryColor] = usePrimaryColor()
  const swatches = Object.keys(theme.colors).map(color => (
    <ColorSwatch
      key={color}
      component="button"
      color={theme.colors[color][6]}
      onClick={() => setPrimaryColor(color)}
      className={classes.button}
    >
      {primaryColor === color && <CheckIcon color="white" width={10} />}
    </ColorSwatch>
  ))

  return (
    <SimpleGrid
      cols={14}
      breakpoints={[
        { maxWidth: "md", cols: 14, spacing: "md" },
        { maxWidth: "sm", cols: 7, spacing: "xs" },
        { maxWidth: "xs", cols: 7, spacing: "xs" }
      ]}
    >
      {swatches}
    </SimpleGrid>
  )
}
