import { useMutation } from "@tanstack/react-query"
import { memberEdit } from "../../api/memberEdit"
import { useAuth } from "../useAuth"

export const useMemberEditMutation = () => {
  const { setUser } = useAuth()

  return useMutation({
    mutationFn: memberEdit,
    onSuccess(data, variables) {
      setUser(currentUser => {
        if (currentUser) {
          return {
            ...currentUser,
            data: {
              ...currentUser?.data,
              name: variables.name || currentUser.data.name,
              avatar: variables.avatar || currentUser.data.avatar
            }
          }
        }
      })
    },
    onError: err => {
      console.log(err)
    }
  })
}
