export type ChatBoxOutlineProps = {
  color?: string
  size?: number
}

export function ChatBoxOutline(props: ChatBoxOutlineProps) {
  return (
    <svg
      width={props.size ? props.size + 1 : 21}
      height={props.size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.6553 2.5H4.78027C4.20062 2.50165 3.64518 2.73265 3.2353 3.14253C2.82542 3.5524 2.59442 4.10785 2.59277 4.6875V12.1875C2.59442 12.7672 2.82542 13.3226 3.2353 13.7325C3.64518 14.1424 4.20062 14.3734 4.78027 14.375H6.34277V17.5L10.0037 14.4477C10.0599 14.4007 10.1309 14.375 10.2041 14.375H16.6553C17.2349 14.3734 17.7904 14.1424 18.2002 13.7325C18.6101 13.3226 18.8411 12.7672 18.8428 12.1875V4.6875C18.8411 4.10785 18.6101 3.5524 18.2002 3.14253C17.7904 2.73265 17.2349 2.50165 16.6553 2.5V2.5Z"
        stroke={props.color}
        strokeLinejoin="round"
      />
    </svg>
  )
}
