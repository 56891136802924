import {
  Button,
  CheckIcon,
  ColorSwatch,
  createStyles,
  Popover,
  SimpleGrid,
  useMantineTheme
} from "@mantine/core"
import { IconColorPicker } from "@tabler/icons"
import { useTheme } from "../hooks/theme/useTheme"
import { usePrimaryColor } from "../hooks/theme/usePrimaryColor"

const useStyles = createStyles(() => ({
  mainButton: {
    width: 24,
    height: 24,
    borderRadius: 8
  },
  button: {
    "&:hover": {
      cursor: "pointer"
    }
  }
}))
export const AppColorSwatch = () => {
  const { classes } = useStyles()
  const theme = useMantineTheme()
  const [primaryColor, setPrimaryColor] = usePrimaryColor()
  const { toggleColorScheme } = useTheme()
  const swatches = Object.keys(theme.colors).map(color => (
    <ColorSwatch
      key={color}
      component="button"
      color={theme.colors[color][6]}
      onClick={() => setPrimaryColor(color)}
      className={classes.button}
    >
      {primaryColor === color && <CheckIcon color="white" width={10} />}
    </ColorSwatch>
  ))

  return (
    <Popover position="bottom" withArrow shadow="md">
      <Popover.Target>
        <Button
          aria-label="Toggle theme"
          size="xs"
          onClick={() => toggleColorScheme()}
          title="Ctrl + J"
          variant="subtle"
          leftIcon={<IconColorPicker size="15" />}
        >
          Cores
        </Button>
      </Popover.Target>
      <Popover.Dropdown>
        <SimpleGrid cols={7}>{swatches}</SimpleGrid>
      </Popover.Dropdown>
    </Popover>
  )
}
