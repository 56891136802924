import App from "./App"
import { postList } from "./api/postList"
import { PageOAuth } from "./pages/PageOAuth"
import { PageTenant } from "./pages/PageTenant"
import { PageHome } from "./pages/home/PageHome"
import { PageNotFound } from "./pages/notfound/PageNotFound"
import { PageSettings } from "./pages/settings/PageSettings"
import { PublicRoute } from "./components/PublicRoute"
import { createBrowserRouter } from "react-router-dom"
import { PageProducer } from "./pages/producer/PageProducer"
import { ProtectedRoute } from "./components/ProtectedRoute"
import { PageHomeCreator } from "./pages/home/PageHomeCreator"
import { PageDashboard } from "./pages/dashboard/PageDashboard"
import { PageSignInMember } from "./pages/sign/PageSignInMember"
import { PageSignUpMember } from "./pages/sign/PageSignUpMember"
import { PageSignUpCreator } from "./pages/sign/PageSignupCreator"
import { PageNewPassword } from "./pages/password/PageNewPassword"
import { PageChannelPosts } from "./pages/channel/PageChannelPosts"
import { PageForgotPassword } from "./pages/password/PageForgotPassword"
import { PageEmailFeedback } from "./pages/sign/PageEmailFeedback"
import { PageCreateCommunity } from "./pages/sign/PageCreateCommunity"
import { TenantWrapper } from "./components/TenantWrapper"

export const router = createBrowserRouter([
  {
    path: "/",
    element: <TenantWrapper />,
    children: [
      {
        path: "*",
        element: <PageNotFound />
      },
      {
        path: "/tenant/:tenant",
        element: <PageTenant />
      },

      {
        path: "/remeber-password",
        element: (
          <PublicRoute>
            <PageForgotPassword />
          </PublicRoute>
        )
      },
      {
        path: "/sign-in",
        element: (
          <PublicRoute>
            <PageSignInMember />
          </PublicRoute>
        )
      },
      {
        path: "/sign-up",
        element: (
          <PublicRoute>
            <PageSignUpMember />
          </PublicRoute>
        )
      },
      {
        path: "/sign-up/creator",
        element: (
          <PublicRoute>
            <PageSignUpCreator />
          </PublicRoute>
        )
      },
      {
        path: "/verify/email",
        element: (
          <PublicRoute>
            <PageEmailFeedback />
          </PublicRoute>
        )
      },
      {
        path: "/",
        element: <PageHome />,
        errorElement: <PageNotFound />
      },
      {
        path: "/creator",
        element: <PageHomeCreator />,
        errorElement: <PageNotFound />
      },
      {
        path: "/",
        element: (
          <ProtectedRoute>
            <App />
          </ProtectedRoute>
        ),
        errorElement: <PageNotFound />,
        children: [
          {
            path: "/oauth",
            element: <PageOAuth />
          },
          {
            path: "/producer",
            element: <PageProducer />
          },
          {
            path: "/dashboard",
            element: <PageDashboard />,
            loader: async () => {
              const posts = await postList({ page: 1, limit: 50 })
              return { posts }
            }
          },
          {
            path: "/channels/posts",
            element: <PageChannelPosts />
          },
          {
            path: "/channels/:id/posts",
            element: <PageChannelPosts />
          },
          {
            path: "/settings",
            element: <PageSettings />
          }
        ]
      },
      {
        path: "/new-password",
        element: (
          <ProtectedRoute>
            <PageNewPassword />
          </ProtectedRoute>
        )
      },
      {
        path: "/spaces/create",
        element: (
          <ProtectedRoute>
            <PageCreateCommunity />
          </ProtectedRoute>
        )
      }
    ]
  }
])
