import { Mail } from "@raise-sistemas/icons"
import {
  DsTextInputBase,
  DsTextInputBaseProps
} from "../DsTextInputBase/DsTextInputBase"

export type DsTextInputEmailProps = Omit<
  DsTextInputBaseProps,
  "icon" | "type" | "withAsterisk"
> & {
  isRequired?: boolean
}

export function DsTextInputEmail(props: DsTextInputEmailProps) {
  return (
    <DsTextInputBase
      type="email"
      withAsterisk={props.isRequired}
      {...props}
      icon={<Mail />}
    />
  )
}
