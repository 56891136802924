import { useTranslation } from "react-i18next"
import { IconCheck, IconX } from "@tabler/icons"
import { showNotification } from "@mantine/notifications"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { channelEdit } from "../../api/channelEdit"
import { queries } from "../../lib/query-key-factory"
import { channelCreate, ChannelCreatePayload } from "../../api/channelCreate"

type Payload = ChannelCreatePayload & { id?: string }

const handleMutation = (payload: Payload) => {
  return payload.id
    ? channelEdit({ ...payload, id: payload.id })
    : channelCreate(payload)
}

export const useChannelMutation = (id?: string) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation({
    mutationFn: handleMutation,
    onSuccess: () => {
      showNotification({
        color: "green",
        icon: <IconCheck />,
        title: `${t("root.channel")} ${id ? t("root.edited") : t("root.created")} ${t("root.with_success")}`,
        message: ""
      })
      queryClient.invalidateQueries(queries.channels.list().queryKey)
    },
    onError: () => {
      showNotification({
        color: "red",
        icon: <IconX />,
        title: t("root.oops"),
        message: t("error.cant_finalize")
      })
    }
  })
}
