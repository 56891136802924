export function nameToInitials(name: string) {
  const parts = name.trim().toLocaleUpperCase().split(/\s+/)
  if (parts.length === 0) return ""
  const firstName = parts[0]
  const lastName = parts[parts.length - 1]
  if (parts.length === 1) {
    // só tem o primeiro nome
    if (firstName.length === 1) return firstName
    return firstName.charAt(0) + firstName.charAt(1).toLocaleLowerCase()
  } else {
    return firstName.charAt(0) + lastName.charAt(0)
  }
}
