import { supabase } from "../lib/supabase"
import { ChannelType } from "../models/channel"
import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"

type ChannelsResponse = ChannelType[]

export async function channelList(): Promise<ChannelsResponse> {
  const { data } = await supabase
    .from("db")
    .select("id::text, data")
    .eq("tenant", getTenant())
    .eq("kind", KINDS.CHANNEL)
    .order("id", { ascending: false })

  return data ? (data as unknown as ChannelType[]) : []
}
