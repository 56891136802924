import { NotificationProps, showNotification } from "@mantine/notifications"
import { IconCheck } from "@tabler/icons"

type Payload = Pick<NotificationProps, "title" | "message" | "autoClose" | "id">

export const toastSuccess = (payload: Payload) => {
  showNotification({
    color: "green",
    icon: <IconCheck />,
    ...payload
  })
}
