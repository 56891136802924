import { ConfirmDeleteModal } from "../ConfirmDeleteModal"
import { useChannelDeleteMutation } from "../../hooks/mutations/useChannelDeleteMutation"
import { useTranslation } from "react-i18next"

type Props = {
  id: string
  opened: boolean
  onClose: () => void
}

export function ChannelConfirmDeleteModal({ id, opened, onClose }: Props) {
  const { t } = useTranslation()
  const { isLoading, mutate } = useChannelDeleteMutation()

  const handleDelete = () => mutate(id)

  return (
    <ConfirmDeleteModal
      opened={opened}
      onClose={onClose}
      title={t("validation.are_you_sure")}
      description={t("validation.delete_channel_question")}
      onDelete={handleDelete}
      isLoading={isLoading}
    />
  )
}
