import { uid } from "../lib/uid"
import { supabase } from "./../lib/supabase"
import { getTenant } from "../helpers/getTenant"
import { getUserId } from "../helpers/getUserId"
import { KINDS } from "../lib/constants"

type CommentCreatePayload = {
  content: string
  parent_id: string
}

export async function commentCreate(payload: CommentCreatePayload) {
  const userId = await getUserId()
  if (!userId) throw new Error("User not found")
  const { error } = await supabase.from("db").insert({
    id: uid(),
    user_uuid: userId,
    tenant: getTenant(),
    kind: KINDS.POST_COMMENT,
    data: {
      content: payload.content
    },
    parent_id: payload.parent_id
  })
  if (error) throw new Error(error.message)
}
