export type HeartProps = {
  color?: string
  size?: number
}

export function Heart(props: HeartProps) {
  return (
    <svg
      width={props.size ? props.size + 1 : 21}
      height={props.size}
      viewBox="0 0 21 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width={props.size}
        height={props.size}
        transform="translate(0.717773)"
        fill="transparent"
      />
      <path
        d="M14.5036 3.125C11.9677 3.125 10.7177 5.625 10.7177 5.625C10.7177 5.625 9.46768 3.125 6.93175 3.125C4.87081 3.125 3.23878 4.84922 3.21768 6.90664C3.17472 11.1773 6.60557 14.2145 10.3661 16.7668C10.4698 16.8373 10.5923 16.8751 10.7177 16.8751C10.8431 16.8751 10.9656 16.8373 11.0692 16.7668C14.8294 14.2145 18.2603 11.1773 18.2177 6.90664C18.1966 4.84922 16.5646 3.125 14.5036 3.125Z"
        fill={props.color}
      />
    </svg>
  )
}
