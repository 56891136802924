import { Container, Group, Image, Space, Text, Title } from "@mantine/core"
import { DsButtonPrimary, DsButtonSecondary } from "@raise-sistemas/ds"
import { IconUserCircle, IconWorld } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { useNavigateTo } from "../../helpers/navigateTo"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"
import { PageFallback } from "../PageFallback"

export const PageHomeSpace = () => {
  const data = useSpaceQuery()
  const { t } = useTranslation()
  const navigateTo = useNavigateTo()

  if (!data) return <PageFallback />

  return (
    <Container size="xs" px="xl">
      <Space h={100} />
      <Image src={data.data.logo} width={64} radius="md" />
      <Space h={32} />
      <Title size={64} weight="400">
        {data.data.title}
      </Title>
      <Space h={30} />
      <Group spacing="xs">
        <IconWorld color="green" size="13" />
        <Text color="green" size={12} mr={30}>
          {t("root.open_to_all")}
        </Text>
        <IconUserCircle color="gray" size={13} />
        <Text color="dimmed" size={12}>
          {data.data.qty_members} {t("root.members")}
        </Text>
      </Group>
      <Space h={24} />
      <Text color="dimmed" size={20}>
        {data.data.description}
      </Text>
      <Space h={40} />
      <Group spacing="md">
        <DsButtonPrimary onClick={() => navigateTo("/sign-up")}>
          {t("button.join_us")}
        </DsButtonPrimary>
        <DsButtonSecondary onClick={() => navigateTo("/sign-in")}>
          {t("auth.login")}
        </DsButtonSecondary>
      </Group>
    </Container>
  )
}
