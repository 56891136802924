import { useState } from "react"
import { IconCirclePlus } from "@tabler/icons"
import { useTranslation } from "react-i18next"
import { CSSObject, MediaQuery } from "@mantine/core"
import { DsBox, DsButtonPrimary } from "@raise-sistemas/ds"
import { useUserPermissions } from "../hooks/useUserPermissions"
import { PostCreateEditDrawer } from "./post/PostCreateEditDrawer"

const mobileStyles: CSSObject = {
  position: "fixed",
  flexDirection: "column",
  alignItems: "center",
  bottom: "2%",
  left: 20,
  right: 20,
  zIndex: 200
}

export function ButtonNewContent() {
  const { t } = useTranslation()
  const [opened, setOpened] = useState(false)
  const permissions = useUserPermissions()

  if (!permissions.CAN_SEE_NEW_POST_BUTTON) return null

  return (
    <>
      <MediaQuery smallerThan="sm" styles={mobileStyles}>
        <DsButtonPrimary
          onClick={() => setOpened(true)}
          leftIcon={<IconCirclePlus />}
        >
          {t("button.create_post")}
        </DsButtonPrimary>
      </MediaQuery>
      <PostCreateEditDrawer opened={opened} setOpened={setOpened} />
    </>
  )
}
