import { Space } from "@mantine/core"
import { DsText } from "../../DsText"
import { DsButtonBase, DsButtonBaseProps } from "../DsButtonBase/DsButtonBase"

export type DsButtonBadgeProps = Pick<
  DsButtonBaseProps,
  "children" | "onClick"
> & {
  title?: string
  emoji?: string
}

export function DsButtonBadge(props: DsButtonBadgeProps) {
  return (
    <DsButtonBase
      compact
      variant="light"
      color="gray"
      {...props}
      styles={{
        label: { whiteSpace: "break-spaces" },
        root: { height: "auto" }
      }}
    >
      {props.emoji ? (
        <>
          <DsText children={props.emoji} variant="body-1" />
        </>
      ) : null}
      {props.emoji && props.title ? <Space mr="md" /> : null}
      {props.title ? (
        <DsText
          variant="body-2"
          children={props.title}
          isWordBreak
          weight="bold"
        />
      ) : null}
    </DsButtonBase>
  )
}
