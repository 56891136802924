import { Button } from "@mantine/core"
import { useTranslation } from "react-i18next"
import { IconBrandGoogle } from "@tabler/icons"
import { signInWithGoogle } from "../api/signIn"

export const SocialButtons = () => {
  const { t } = useTranslation()
  return (
    <>
      <Button
        type="button"
        onClick={signInWithGoogle}
        variant="light"
        leftIcon={<IconBrandGoogle />}
      >
        {t("auth.login_with_google")}
      </Button>
    </>
  )
}
