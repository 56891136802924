import { useForm } from "@mantine/form"
import { FormEvent, forwardRef } from "react"
import { useTranslation } from "react-i18next"
import { useAuth } from "../../hooks/useAuth"
import { CommentBox } from "./CommentBox"

type CommentFormProps = {
  isVisible?: boolean
  defaultValue?: string
  actionAlwaysVisible?: boolean
  onHidden?: () => void
  onSubmit: (comment: string) => void
}

export const CommentForm = forwardRef<HTMLInputElement, CommentFormProps>(
  (props, ref) => {
    const {
      actionAlwaysVisible = false,
      defaultValue = "",
      isVisible = true,
      onHidden,
      onSubmit
    } = props
    const { user } = useAuth()
    const form = useForm({
      initialValues: {
        content: defaultValue
      },
      validate: {
        content: value =>
          value.length < 3 ? t("validation.comment_min_size") : null
      }
    })
    const { t } = useTranslation()

    const handleSubmit = () => {
      onSubmit(form.values.content)
      form.reset()
    }

    const handleCancel = () => {
      form.reset()
      onHidden?.()
    }

    const showActions = actionAlwaysVisible || !!form.values.content

    return (
      <>
        <CommentBox
          onClickConfirm={(e: unknown) =>
            form.onSubmit(handleSubmit)(e as FormEvent<HTMLFormElement>)
          }
          src={user?.data?.avatar}
          name={user?.data?.name || ""}
          onCLickCancel={handleCancel}
          contentVisible={isVisible}
          t={t}
          ref={ref}
          buttonVisible={showActions}
          form={form}
          isEdit={!!defaultValue}
        />
      </>
    )
  }
)
