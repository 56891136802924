import { supabase } from "../lib/supabase"
import { ReplyType } from "../models/comment"
import { getTenant } from "../helpers/getTenant"
import { getUserId } from "../helpers/getUserId"
import { KINDS } from "../lib/constants"

export type ReplyListPayload = {
  page?: number
  limit?: number
  commentId: string
}

export async function replyList({
  page = 1,
  limit = 3,
  commentId
}: ReplyListPayload) {
  const currentPage = Math.max(0, page - 1)
  const rangeStart = currentPage * limit
  const rangeEnd = rangeStart + limit - 1
  const userId = await getUserId()

  const { data } = await supabase
    .from("db")
    .select(
      `
        id::text,
        data->content,
        user_uuid,
        member(data->name,data->avatar),
        likes:db!parent_id(id::text),
        likesCount:db!parent_id(count)
      `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.COMMENT_REPLY)
    .eq("likes.kind", KINDS.REPLY_LIKE)
    .eq("likesCount.kind", KINDS.REPLY_LIKE)
    .eq("likes.user_uuid", userId)
    .eq("parent_id", commentId)
    .order("id", { ascending: false })
    .range(rangeStart, rangeEnd)
  return data ? (data as unknown as ReplyType[]) : []
}
