import { TextInput, TextInputProps } from "@mantine/core"

export type DsTextInputBaseProps = Pick<
  TextInputProps,
  | "value"
  | "disabled"
  | "placeholder"
  | "label"
  | "error"
  | "onChange"
  | "onBlur"
  | "onFocus"
  | "rightSection"
  | "icon"
  | "type"
  | "withAsterisk"
  | "classNames"
  | "required"
  | "maxLength"
  | "inputWrapperOrder"
>

export function DsTextInputBase(props: DsTextInputBaseProps) {
  return <TextInput {...props} />
}
