import { Container, Image, SimpleGrid, Text, Title } from "@mantine/core"
import { DsButtonSecondary, DsStackVertical } from "@raise-sistemas/ds"
import { useTranslation } from "react-i18next"
import image from "../../assets/not-found.svg"
import { useNavigateTo } from "../../helpers/navigateTo"
import { useStyles } from "./PageNotFound.styles"

export function PageNotFound() {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const navigateTo = useNavigateTo()
  return (
    <Container className={classes.root}>
      <SimpleGrid
        spacing={80}
        cols={2}
        breakpoints={[{ maxWidth: "sm", cols: 1, spacing: 40 }]}
      >
        <Image src={image} className={classes.mobileImage} />
        <div>
          <Title className={classes.title}>
            {t("error.message.something_wrong")}
          </Title>
          <DsStackVertical spacing="xl">
            <Text color="dimmed" size="lg">
              {t("error.message.not_found")}
            </Text>
            <DsButtonSecondary onClick={() => navigateTo("/")}>
              {t("button.go_home")}
            </DsButtonSecondary>
          </DsStackVertical>
        </div>
        <Image src={image} className={classes.desktopImage} />
      </SimpleGrid>
    </Container>
  )
}
