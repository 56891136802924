import { parsePost } from "../helpers/parsePost"
import { PostType } from "../models/post"
import { supabase } from "../lib/supabase"
import { getUserId } from "../helpers/getUserId"
import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"

export type PostListPayload = {
  page?: number
  limit?: number
  channelId?: string
}

export async function postList({
  page = 1,
  limit = 10,
  channelId = ""
}: PostListPayload) {
  const currentPage = Math.max(0, page - 1)
  const rangeStart = currentPage * limit
  const rangeEnd = rangeStart + limit - 1

  const userId = await getUserId()

  let query = supabase
    .from("db")
    .select(
      `
      id::text,
      data,
      member(user_uuid, data),
      user_uuid,
      liked:db!parent_id(id::text),
      likesCount:db!parent_id(count),
      commentsCount:db!parent_id(count),
      channel_post:db!child_id!inner(
        channel:parent_id(
          id::text, 
          data
        )
      )
    `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST)
    .eq("liked.kind", KINDS.POST_LIKE)
    .eq("likesCount.kind", KINDS.POST_LIKE)
    .eq("liked.user_uuid", userId)
    .eq("commentsCount.kind", KINDS.POST_COMMENT)
    .order("id", { ascending: false })
    .range(rangeStart, rangeEnd)

  if (channelId) {
    query = query.eq("channel_post.parent_id", channelId)
  }

  const { data } = await query
  const posts = data as unknown as PostType[] | null
  return posts ? posts.map(post => parsePost(post)) : []
}
