// ao inicializar o app react, receber do backend o último milisegundo gerado + 1
let last_time = Date.now()
let last_rands: BigInt[] = []

export function rand() {
  return crypto.getRandomValues(new Uint32Array(1))[0]
}

// retorna uma string para não precisar trabalhar com BigInt
// |-----------| 13 numbers timestmap: new Date(1662496201761) => 2022-09-06T20:30:01.761Z
// 1662496201761245192 19 chars
//              |----| 6 random numbers 0 to 999_999
// BigInt(1662496201761245192n).toString(36) => "cmplpvj8qj9k" 12 caracters alpha numéricos
export function uid() {
  // o uid começa com o timestamp atual (até o milisegundo)
  const now = Date.now()
  // se o último gerado for num milisegundo diferente, reseta
  if (now !== last_time) {
    last_time = now
    last_rands = []
  }
  // gera um aleatório de 0 à 999_999
  // tenta dinovo se repetir em last_rands
  let bigRand = 0n
  const shift = 1_000_000n
  do {
    bigRand = BigInt(rand()) % shift
  } while (last_rands.includes(bigRand))
  last_rands.push(bigRand)
  const time = BigInt(now) * shift
  const id = time + bigRand

  return id.toString()
}

// parseInt só funciona até Number.MAX_SAFE_INTEGER 9_007_199_254_740_991 (2gosa7pa2gv) 11 chars 2**53-1 (53 bits)
// para converter algo maior como judkkipvrcvfjgprpwli em BigInt: 7368387669174284376715250315334n
// Postgres BigInt: -9,223,372,036,854,775,808 to +9_223_372_036_854_775_807 1y2p0ij32e8e7 13 chars 2**63-1 (63 bits)
export function str2bigint(str: string, radix = 36) {
  return [...str].reduce(
    (r, v) => r * BigInt(radix) + BigInt(parseInt(v, radix)),
    0n
  )
}
