import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { IconCheck, IconX } from "@tabler/icons"
import { showNotification } from "@mantine/notifications"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"
import { channelDelete } from "../../api/channelDelete"

export const useChannelDeleteMutation = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  return useMutation(channelDelete, {
    onSuccess: () => {
      showNotification({
        color: "green",
        icon: <IconCheck />,
        title: t("validation.channel_deletd_success"),
        message: ""
      })
      queryClient.invalidateQueries(queries.channels.list().queryKey)
      navigate("/channels/posts")
    },
    onError: () => {
      showNotification({
        color: "red",
        icon: <IconX />,
        title: t("root.oops"),
        message: t("error.cant_finalize")
      })
    }
  })
}
