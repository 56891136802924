import { AnchorHTMLAttributes, ReactNode, useEffect } from "react"
import { Link, useMatch } from "react-router-dom"
import { useQueryClient } from "@tanstack/react-query"
import { Box, createStyles, NavLink } from "@mantine/core"
import { queries } from "../lib/query-key-factory"

type Props = {
  title: string
  icon?: ReactNode
  to: string
  rightElement?: ReactNode
} & AnchorHTMLAttributes<HTMLAnchorElement>

const useStyles = createStyles(theme => ({
  navLink: {
    borderRadius: theme.radius.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[6],

    "&:hover": {
      color:
        theme.colorScheme === "light"
          ? theme.colors.gray[7]
          : theme.colors.gray[1],
      backgroundColor: theme.fn.variant({
        color: theme.primaryColor,
        variant: "light"
      }).background
    }
  },
  navLinkLabel: {
    fontWeight: 500,
    fontSize: theme.fontSizes.sm
  },
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",

    "&:not(:last-of-type)": {
      marginBottom: 4
    }
  },
  button: {
    position: "absolute",
    right: 4,
    color:
      theme.colorScheme === "dark" ? theme.colors.dark[2] : theme.colors.gray[6]
  }
}))

export const AppNavLink = ({ title, icon, to, rightElement }: Props) => {
  const { classes, theme } = useStyles()
  const match = useMatch(to)
  const isActive = Boolean(match)

  const queryClient = useQueryClient()

  useEffect(() => {
    if (isActive) {
      const updater = () => ({ title, emoji: icon })
      queryClient.setQueryData(queries.channels.current.queryKey, updater)
    }
  }, [isActive])

  return (
    <Box className={classes.container}>
      <NavLink
        to={to}
        label={title}
        component={Link}
        active={isActive}
        icon={icon}
        style={{
          backgroundColor: isActive
            ? theme.fn.variant({
                color: theme.primaryColor,
                variant: "light"
              }).background
            : undefined
        }}
        classNames={{
          root: classes.navLink,
          label: classes.navLinkLabel
        }}
      />
      {!!rightElement && <Box className={classes.button}>{rightElement}</Box>}
    </Box>
  )
}
