import { supabase } from "../lib/supabase"

export const getSession = async () => {
  const {
    data: { session }
  } = await supabase.auth.getSession()

  if (!session) {
    throw new Error("Token not provided")
  }

  return session
}
