import { useForm } from "@mantine/form"
import { signUp } from "../../api/signUp"
import { useTranslation } from "react-i18next"
import { useMutation } from "@tanstack/react-query"
import { toastError } from "../../helpers/toastError"
import { useUnexpectedError } from "../../hooks/errors/useUnexpectedError"

export const useSignUpContainer = () => {
  const form = useForm({
    initialValues: {
      name: "",
      email: "",
      password: "",
      repeat_password: ""
    },
    validate: {
      name: value =>
        value.length > 0 ? null : t("validation.name_must_not_blank"),
      email: value =>
        /^\S+@\S+$/.test(value) ? null : t("input.email.invalid_email"),
      password: value =>
        value.length >= 6 ? null : t("input.password.invalid_password"),
      repeat_password: (value, values) =>
        value === values?.password ? null : t("validation.password_must_same")
    }
  })
  const { t } = useTranslation()
  const unexpectedError = useUnexpectedError()

  const mutation = useMutation({
    mutationFn: signUp,
    onError(error) {
      if (error instanceof Error && error.message === "User already exists") {
        return toastError({
          title: t("error.message.user_already_exists"),
          message: ""
        })
      }

      unexpectedError(t("error.message.something_wrong"))
    }
  })

  const handleSignUp = () => mutation.mutate(form.values)

  function handleSubmit() {
    if (!form.validate().hasErrors) {
      return handleSignUp()
    }
  }

  return {
    form,
    mutation,
    handleSubmit
  }
}
