import { Navigate, useLocation } from "react-router-dom"
import { PageFallback } from "../pages/PageFallback"
import { useAuth } from "../hooks/useAuth"
import { useHostRedirect } from "../hooks/useHostRedirect"

type Props = {
  children: JSX.Element
}

export const ProtectedRoute = ({ children }: Props) => {
  const { signed, isLoading, user } = useAuth()
  const { pathname } = useLocation()

  useHostRedirect()

  if (isLoading) return <PageFallback />

  if (!signed) return <Navigate to="/" replace />

  if (!user?.tenant && pathname !== "/spaces/create") {
    return <Navigate to="/spaces/create" />
  }

  return children
}
