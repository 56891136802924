import { getUserId } from "../helpers/getUserId"
import { KINDS, ROLES } from "../lib/constants"
import { supabase } from "../lib/supabase"

export const spaceCreatorByUserId = async () => {
  const userId = await getUserId()

  if (!userId) throw new Error("User not found")

  const { data, error } = await supabase
    .from("db")
    .select("*, member!inner(*)")
    .eq("kind", KINDS.SPACE)
    .eq("member.user_uuid", userId)
    .eq("member.role", ROLES.OWNER)
    .limit(1)
    .single()

  if (error) throw error

  return data
}
