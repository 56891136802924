import { Tooltip, TooltipProps } from "@mantine/core"

export type DsTooltipProps = Pick<
  TooltipProps,
  "label" | "withArrow" | "children"
>

export function DsTooltip(props: DsTooltipProps) {
  return <Tooltip {...props} />
}
