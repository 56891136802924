import {
  Box,
  Button,
  Divider,
  Drawer,
  DrawerProps,
  Group,
  Stack,
  Tabs,
  Text,
  TypographyStylesProvider,
  createStyles
} from "@mantine/core"
import { DsAvatar } from "@raise-sistemas/ds"
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { memberAddRole } from "../../api/memberAddRole"
import { getRoles } from "../../helpers/getRoles"
import { ROLES } from "../../lib/constants"
import { queries } from "../../lib/query-key-factory"
import { MemberSince } from "./MemberSince"
import { MemberTags } from "./MemberTags"

type MemberDetailDrawerProps = DrawerProps & {
  memberId: string
}

const useStyles = createStyles(theme => {
  const grayColors = theme.fn.variant({ color: "gray", variant: "light" })
  const darkColors = theme.fn.variant({
    color: "dark",
    variant: theme.colorScheme === "dark" ? "filled" : "default"
  })

  const isDark = theme.colorScheme === "dark"
  const borderColor = isDark ? theme.colors.dark[5] : theme.colors.gray[2]
  return {
    drawer: {
      overflowY: "auto",
      overflowX: "hidden"
    },
    title: {
      fontSize: 24,
      fontWeight: 600
    },
    tabList: {
      background: grayColors.background,
      padding: "6px 16px",
      borderRadius: 8,
      border: `1px solid ${borderColor}`
    },
    tabItem: {
      color: darkColors.color,
      background: "transparent",
      borderRadius: 8,
      border: 0,

      "&[data-active=true]": {
        background: darkColors.background,
        color: darkColors.color,
        fontWeight: 500,
        boxShadow: theme.shadows.xs
      }
    },
    postContainer: {
      boxShadow: theme.shadows.sm,
      padding: theme.spacing.lg,
      borderRadius: theme.radius.md,
      border: `1px solid ${borderColor}`
    }
  }
})

export const MemberDetailDrawer = ({
  memberId,
  ...rest
}: MemberDetailDrawerProps) => {
  const { classes } = useStyles()
  const queryClient = useQueryClient()
  const { data } = useQuery({
    ...queries.members.detail(memberId),
    enabled: !!memberId
  })
  const { data: posts } = useQuery({
    ...queries.posts.listByUser({ limit: 10, page: 1, userId: memberId }),
    enabled: !!memberId
  })
  const roles = getRoles(data?.role)
  const { t } = useTranslation()

  const mutation = useMutation({
    mutationFn: memberAddRole,
    onSuccess() {
      queryClient.invalidateQueries(queries.members.list())
      queryClient.invalidateQueries(queries.members.detail(memberId))
    }
  })

  const toggleRole = () => {
    if (!data) return

    mutation.mutate({
      role: roles.member ? ROLES.MODERATOR : ROLES.MEMBER,
      id: data.user_uuid
    })
  }

  return (
    <Drawer
      size={500}
      title="Mais informações"
      zIndex={9000}
      position="right"
      padding={24}
      classNames={{
        title: classes.title,
        drawer: classes.drawer
      }}
      {...rest}
    >
      {data && (
        <>
          <Group spacing="xl" align="flex-start" mt={50} mb={40} noWrap>
            <DsAvatar
              name={data.data.name || ""}
              src={data.data.avatar}
              size="md"
            />

            <Box>
              <Text weight={600} mb={2} size="lg">
                {data.data.name}
              </Text>
              <MemberTags member={data} />
              <Text size="sm" color="dimmed">
                {data.data.email}
              </Text>
              <MemberSince createdAt={data.data.created_at} />

              {data.role !== ROLES.OWNER && (
                <Button
                  onClick={toggleRole}
                  loading={mutation.isLoading}
                  variant="outline"
                  mt={12}
                >
                  Tornar {roles.member ? "moderador" : "membro"}
                </Button>
              )}
            </Box>
          </Group>

          <Divider mb="lg" />

          <Tabs defaultValue="posts">
            <Tabs.List className={classes.tabList}>
              <Tabs.Tab value="posts" className={classes.tabItem}>
                Posts
              </Tabs.Tab>
              <Tabs.Tab value="comments" className={classes.tabItem}>
                Comentários
              </Tabs.Tab>
            </Tabs.List>

            <Tabs.Panel value="posts">
              <Stack py="lg">
                {posts && posts.length > 0 ? (
                  posts.map(post => (
                    <Box key={post.id} className={classes.postContainer}>
                      <Stack>
                        <TypographyStylesProvider
                          style={{ wordBreak: "break-word" }}
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: post.data.content
                            }}
                          />
                        </TypographyStylesProvider>
                      </Stack>
                    </Box>
                  ))
                ) : (
                  <Text>Nenhum post encontrado!</Text>
                )}
              </Stack>
            </Tabs.Panel>
            <Tabs.Panel value="comments">
              <Stack py="lg">
                <Group noWrap spacing={16}>
                  <DsAvatar name="Wanderson Alves" size="md" />

                  <Box>
                    <Group spacing={8}>
                      <Text weight={500} size="md">
                        Wanderson Alves
                      </Text>
                      <Text color="dimmed" weight={400} size="sm">
                        há 2 horas
                      </Text>
                    </Group>
                    <Text color="dimmed" weight={400}>
                      Comentou o post "Opa Galera" em "
                      <Text display="inline" color="indigo" weight={400}>
                        :) Se Apresente
                      </Text>
                      "
                    </Text>
                  </Box>
                </Group>
                <Group noWrap spacing={16}>
                  <DsAvatar name="Wanderson Alves" size="md" />

                  <Box>
                    <Group spacing={8}>
                      <Text weight={500} size="md">
                        Wanderson Alves
                      </Text>
                      <Text color="dimmed" weight={400} size="sm">
                        há 2 horas
                      </Text>
                    </Group>
                    <Text color="dimmed" weight={400}>
                      Comentou o post "Opa Galera" em "
                      <Text display="inline" color="indigo" weight={400}>
                        :) Se Apresente
                      </Text>
                      "
                    </Text>
                  </Box>
                </Group>
              </Stack>
            </Tabs.Panel>
          </Tabs>
        </>
      )}
    </Drawer>
  )
}
