import { uid } from "../uid"
import { supabase } from "../lib/supabase"
import { PostDataType } from "../models/post"
import { getTenant } from "../helpers/getTenant"
import { getUserId } from "../helpers/getUserId"
import { KINDS } from "../lib/constants"

export type PostUpdatePayload = {
  id: string
  data: PostDataType
  channels: {
    add: string[]
    remove: string[]
  }
}

export async function postUpdate(payload: PostUpdatePayload) {
  const tenant = getTenant()
  const userId = await getUserId()

  if (!userId) throw new Error("User not found")

  const recordChannelPosts = payload.channels.add.map(channelId => ({
    id: +uid(),
    tenant,
    kind: KINDS.CHANNEL_POST,
    user_uuid: userId,
    parent_id: +channelId,
    child_id: +payload.id
  }))

  if (recordChannelPosts.length > 0) {
    await supabase.from("db").insert(recordChannelPosts)
  }

  if (payload.channels.remove.length > 0) {
    await supabase
      .from("db")
      .delete()
      .eq("kind", KINDS.CHANNEL_POST)
      .in("parent_id", payload.channels.remove)
  }

  const recordPost = {
    data: {
      title: payload.data.title,
      content: payload.data.content
    }
  }

  const { error } = await supabase
    .from("db")
    .update(recordPost)
    .eq("id", payload.id)

  if (error) throw new Error(error.message)
}
