import { supabase } from "../lib/supabase"
import { getTenant } from "../helpers/getTenant"
import { PostModel } from "../models/post"
import { KINDS } from "../lib/constants"

export type PostListByUserPayload = {
  page?: number
  limit?: number
  userId: string
}

export async function postListByUser({
  page = 1,
  limit = 10,
  userId
}: PostListByUserPayload) {
  const currentPage = Math.max(0, page - 1)
  const rangeStart = currentPage * limit
  const rangeEnd = rangeStart + limit - 1

  const { data } = await supabase
    .from("db")
    .select(
      `
    id::text,
    data
  `
    )
    .eq("tenant", getTenant())
    .eq("kind", KINDS.POST)
    .eq("user_uuid", userId)
    .order("id", { ascending: false })
    .range(rangeStart, rangeEnd)
  return data as unknown as Pick<PostModel, "id" | "data">[]
}
