import { useAuth } from "./useAuth"
import { MemberType } from "../models/member"
import { getRoles } from "../helpers/getRoles"

type Roles = ReturnType<typeof getRoles>
type User = MemberType & { roles: Roles }

export const useUser = (): User => {
  const { user } = useAuth()

  const roles = getRoles(user?.role)

  return { ...user, roles } as User
}
