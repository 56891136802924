import { createQueryKeyStore } from "@lukemorales/query-key-factory"
import { postById } from "../api/postById"
import { channelList } from "../api/channelList"
import { PostListPayload, postList } from "../api/postList"
import { replyList, ReplyListPayload } from "../api/replyList"
import { commentList, CommentListPayload } from "../api/commentList"
import { memberList } from "../api/memberList"
import { memberById } from "../api/memberById"
import { MemberType } from "../models/member"
import { postListByUser, PostListByUserPayload } from "../api/postListByUser"
import { getTenant } from "../helpers/getTenant"
import { spaceConfigGet } from "../api/spaceConfigGet"

export const queries = createQueryKeyStore({
  channels: {
    current: null,
    list: () => ({
      queryKey: [getTenant()],
      queryFn: channelList
    })
  },
  posts: {
    list: (filters: PostListPayload) => ({
      queryKey: [{ filters, tenant: getTenant() }],
      queryFn: () => postList(filters)
    }),
    listByUser: (filters: PostListByUserPayload) => ({
      queryKey: [{ filters }],
      queryFn: () => postListByUser(filters)
    }),
    detail: (postId: string) => ({
      queryKey: [postId],
      queryFn: () => postById(postId)
    })
  },
  comments: {
    list: (filters: CommentListPayload) => ({
      queryKey: [{ filters }],
      queryFn: () => commentList(filters)
    })
  },
  replies: {
    list: (filters: ReplyListPayload) => ({
      queryKey: [{ filters }],
      queryFn: () => replyList(filters)
    })
  },
  space: {
    info: () => ({
      queryKey: [getTenant()]
    })
  },
  spaceConfig: {
    info: () => ({
      queryKey: [getTenant()],
      queryFn: spaceConfigGet
    })
  },
  creator: {
    space: null
  },
  members: {
    list: () => ({
      queryKey: [getTenant()],
      queryFn: memberList
    }),
    detail: (memberId: string) => ({
      queryKey: [memberId],
      queryFn: async () => {
        const { data, error } = await memberById(memberId)
        if (error) throw error
        return data as MemberType
      }
    })
  },
  tenant: null
})
