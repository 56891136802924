import { MantineThemeOverride } from "@mantine/core"

export const customTheme: MantineThemeOverride = {
  fontFamily: "Poppins, sans-serif",
  headings: { fontFamily: "Poppins, sans-serif" },
  colors: {
    gray: [
      "#F7FAFC",
      "#F1F3F5",
      "#E9ECEF",
      "#DEE2E6",
      "#CED4DA",
      "#ADB5BD",
      "#868E96",
      "#495057",
      "#343A40",
      "#212529"
    ]
  }
}
