import { DsButtonIcon, DsText, DsTooltip } from "@raise-sistemas/ds"
import { ChatBoxOutline } from "@raise-sistemas/icons"
import { useTranslation } from "react-i18next"

type ButtonCommentProps = {
  onClick?: () => void
  count?: number
}

export function ButtonComment(props: ButtonCommentProps) {
  const { t } = useTranslation()
  return (
    <DsTooltip label={t("tooltip.write_comment")}>
      <DsButtonIcon
        leftIcon={<ChatBoxOutline size={20} color="gray" />}
        onClick={props.onClick}
      >
        {props.count ? (
          <DsText color="#A0AEC0" weight="regular" variant="body-2">
            {props.count}
          </DsText>
        ) : null}
      </DsButtonIcon>
    </DsTooltip>
  )
}
