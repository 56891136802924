import { supabase } from "../lib/supabase"

export type MemberAddRolePayload = {
  id: string
  role: string
}

export async function memberAddRole(payload: MemberAddRolePayload) {
  const record = {
    role: payload.role
  }

  const { error } = await supabase
    .from("member")
    .update(record)
    .eq("user_uuid", payload.id)

  if (error) throw new Error(error.message)
}
