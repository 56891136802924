import { useTranslation } from "react-i18next"
import { toastError } from "../../helpers/toastError"

export const useAuthError = () => {
  const { t } = useTranslation()

  return () =>
    toastError({
      title: t("auth.invalid_email_or_password"),
      message: t("validation.verify_your_info")
    })
}
