import { useState, useEffect } from "react"
import { useInterval } from "@mantine/hooks"

export function useCountdown(initialTimeLeft: number) {
  const [timeLeft, setTimeLeft] = useState(initialTimeLeft)
  const interval = useInterval(
    () => setTimeLeft(currentTime => Math.max(0, currentTime - 1)),
    1000
  )
  const minutes = String(Math.floor(timeLeft / 60)).padStart(2, "0")
  const seconds = String(timeLeft % 60).padStart(2, "0")

  const resetCountdown = () => {
    setTimeLeft(initialTimeLeft)
    interval.start()
  }

  useEffect(() => {
    interval.start()
    return interval.stop
  }, [])

  useEffect(() => {
    if (timeLeft === 0) {
      interval.stop()
    }
  }, [timeLeft])

  return {
    timeLeft,
    minutes,
    seconds,
    formatedTime: `${minutes}:${seconds}`,
    resetCountdown
  }
}
