import { useEffect, useState } from "react"
import { useAuth } from "./useAuth"
import { useInterval } from "@mantine/hooks"
import { hostCheck } from "../api/hostCheck"
import { getHost } from "../helpers/getHost"
import { toastSuccess } from "../helpers/toastSuccess"
import { navigateToSpace } from "../helpers/navigateToSpace"
import { spaceCreatorByUserId } from "../api/spaceCreatorByUserId"
import { STORAGE_KEYS } from "../lib/constants"

export const useHostRedirect = () => {
  const { signed } = useAuth()
  const currentHost = getHost()
  const interval = useInterval(hostCheck, 1000 * 10)
  const [hasTenantValue, setHasTenantValue] = useState(false)

  const shouldListenHostStatus =
    signed && currentHost === "app" && hasTenantValue

  useEffect(() => {
    ;(async () => {
      if (currentHost === "app") {
        try {
          const { tenant } = await spaceCreatorByUserId()
          localStorage.setItem(STORAGE_KEYS.TENANT_ID, tenant)
          setHasTenantValue(true)
        } catch (error) {
          console.log(error)
        }
      }
    })()
  }, [])

  useEffect(() => {
    const handleRedirect = () => {
      interval.stop()

      toastSuccess({
        id: "subdomain-published",
        title: "Seu domínio foi publicado com sucesso",
        message: "Você será redirecionado para sua página...",
        autoClose: false
      })

      setTimeout(() => navigateToSpace(window.location.pathname), 1000 * 10)
    }

    if (shouldListenHostStatus) {
      interval.start()
      document.addEventListener("SUBDOMAIN_ACTIVE", handleRedirect)
    }

    return () => {
      document.removeEventListener("SUBDOMAIN_ACTIVE", handleRedirect)
      interval.stop()
    }
  }, [shouldListenHostStatus])
}
