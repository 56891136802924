import { getTenant } from "../helpers/getTenant"
import { KINDS } from "../lib/constants"
import { supabase } from "../lib/supabase"
import { uid } from "../lib/uid"

export type PostCreatePayload = {
  title: string
  content: string
  user_uuid: string
  channels: string[]
}

export async function postCreate(payload: PostCreatePayload) {
  const postId = uid()
  const tenant = getTenant()

  const recordPost = {
    id: postId,
    tenant,
    kind: KINDS.POST,
    data: {
      title: payload.title,
      content: payload.content
    },
    user_uuid: payload.user_uuid
  }

  const recordChannelPosts = payload.channels.map(channelId => ({
    id: uid(),
    tenant,
    kind: KINDS.CHANNEL_POST,
    user_uuid: payload.user_uuid,
    parent_id: channelId,
    child_id: postId
  }))

  const { error } = await supabase
    .from("db")
    .insert([recordPost, ...recordChannelPosts])

  if (error) throw error
}
