import { supabase } from "../lib/supabase"

type CommentDeletePayload = {
  id: string
}

export async function commentDelete({ id }: CommentDeletePayload) {
  const { error } = await supabase.from("db").delete().match({ id: id })

  if (error) throw error
}
