import { supabase } from "../lib/supabase"
import { getTenant } from "../helpers/getTenant"
import { getUserId } from "../helpers/getUserId"
import { KINDS } from "../lib/constants"

type ReplyLikePayload = {
  liked: boolean
  replyId: string
}

export const replyLike = async ({ replyId, liked }: ReplyLikePayload) => {
  const userId = await getUserId()

  if (!userId) return

  const record = {
    kind: KINDS.REPLY_LIKE,
    tenant: getTenant(),
    user_uuid: userId,
    parent_id: replyId
  }

  if (liked) {
    return supabase
      .from("db")
      .delete()
      .eq("kind", KINDS.REPLY_LIKE)
      .eq("parent_id", replyId)
      .eq("user_uuid", userId) as unknown as void
  } else {
    return supabase.from("db").insert(record) as unknown as void
  }
}
