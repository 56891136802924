import { useTranslation } from "react-i18next"
import { IconX } from "@tabler/icons"
import { showNotification } from "@mantine/notifications"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"
import { commentCreate } from "../../api/commentCreate"

export const useCommentCreateMutation = () => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  return useMutation(commentCreate, {
    onSuccess: (_, variables) => {
      queryClient.invalidateQueries(queries.posts._def)
      queryClient.invalidateQueries(
        queries.comments.list({ postId: variables.parent_id })
      )
    },
    onError: () => {
      showNotification({
        color: "red",
        icon: <IconX />,
        title: t("root.oops"),
        message: t("error.cant_finalize")
      })
    }
  })
}
