import { useQuery } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"
import { spaceById } from "../../api/spaceById"
import { getHost } from "../../helpers/getHost"

export const useSpaceQuery = (enabled = false) => {
  const currentHost = getHost()

  return useQuery({
    queryFn: spaceById,
    queryKey: queries.space.info().queryKey,
    enabled: enabled || currentHost !== "app"
  }).data
}
