import { createStyles, Header, Container, Burger, Image } from "@mantine/core"
import { useCurrentChannel } from "../hooks/useCurrentChannel"
import { useSpaceQuery } from "../hooks/queries/useSpaceQuery"
import { DsGroup, DsText } from "@raise-sistemas/ds"
import { useParams } from "react-router-dom"
import { ButtonNewContent } from "./ButtonNewContent"
import { HeaderTimeline } from "./HeaderTimeline"

type Props = {
  opened: boolean
  toggle: () => void
}

const HEADER_HEIGHT = 80

const useStyles = createStyles(theme => ({
  inner: {
    height: HEADER_HEIGHT,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "nowrap"
  },

  logo: {
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      display: "none",
      flexWrap: "nowrap"
    }
  },

  burger: {
    [theme.fn.largerThan("sm")]: {
      display: "none"
    }
  },

  activity: {
    display: "none",
    [`@media (max-width: ${theme.breakpoints.xs}px)`]: {
      display: "block"
    }
  },

  header: {
    marginLeft: 0,
    zIndex: 401,
    padding: 0,
    [`@media (min-width: ${theme.breakpoints.sm}px)`]: {
      marginLeft: 350,
      padding: "0 16px"
    }
  },
  title: {
    whiteSpace: "nowrap",
    overflow: "hidden",
    maxWidth: 100,
    textOverflow: "ellipsis"
  }
}))

export function AppHeader({ opened, toggle }: Props) {
  const params = useParams()
  const id = params?.id || ""
  const { emoji } = useCurrentChannel()
  const space = useSpaceQuery()

  const { classes } = useStyles()
  return (
    <>
      <Header
        height={HEADER_HEIGHT}
        withBorder={false}
        className={classes.header}
      >
        <Container className={classes.inner} fluid>
          <Burger
            opened={opened}
            onClick={toggle}
            className={classes.burger}
            size="sm"
          />
          <DsGroup className={classes.logo}>
            <Image src={space?.data.logo} width={24} height={24} radius="sm" />
            <DsText className={classes.title} variant="body-1">
              {space?.data.title || ""}
            </DsText>
          </DsGroup>

          <HeaderTimeline />

          {!emoji && <div />}

          {!!id && !opened && <ButtonNewContent />}
        </Container>
      </Header>
    </>
  )
}
