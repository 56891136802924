import { useLocalStorage } from "@mantine/hooks"
import { STORAGE_KEYS } from "../../lib/constants"

export const usePrimaryColor = () => {
  return useLocalStorage<string>({
    key: STORAGE_KEYS.PRIMARY_COLOR,
    defaultValue: "violet",
    getInitialValueInEffect: true
  })
}
