import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import resourcesToBackend from "i18next-resources-to-backend"

i18n
  .use(initReactI18next)
  .use(
    resourcesToBackend(async (language, namespace, callback) => {
      try {
        const resources = await import(`./${language}/${namespace}.json`)
        callback(null, resources.default)
      } catch (error) {
        console.log(error)
      }
    })
  )
  .init({
    fallbackLng: "ptBR",

    defaultNS: "translation"
  })

export default i18n
