import { useTranslation } from "react-i18next"
import { IconCheck, IconX } from "@tabler/icons"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { showNotification } from "@mantine/notifications"
import { postCreate } from "../../api/postCreate"
import { queries } from "../../lib/query-key-factory"

type Options = {
  onSuccess?: () => void
}

export const usePostCreateMutation = (options?: Options) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  return useMutation(postCreate, {
    onSuccess: () => {
      showNotification({
        color: "green",
        icon: <IconCheck />,
        title: t("validation.post_created_success"),
        message: ""
      })

      queryClient.invalidateQueries(queries.posts._def)
      options?.onSuccess?.()
    },
    onError: () => {
      showNotification({
        color: "red",
        icon: <IconX />,
        title: t("root.oops"),
        message: t("error.cant_finalize")
      })
    }
  })
}
