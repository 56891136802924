import { useParams } from "react-router-dom"
import { useQuery } from "@tanstack/react-query"
import { queries } from "../../lib/query-key-factory"
import { EmptyState } from "../../components/EmptyState"
import { PostItem } from "../../components/post/PostItem"
import { SkeletonChannelPosts } from "./SkeletonChannelPosts"
import { DsContainer, DsSpace, DsStackVertical } from "@raise-sistemas/ds"
import { Divider } from "@mantine/core"

export function PageChannelPosts() {
  const params = useParams()

  const { data: posts, isLoading } = useQuery(
    queries.posts.list({ channelId: params?.id, limit: 50, page: 1 })
  )

  if (isLoading || !posts) return <SkeletonChannelPosts />

  return (
    <>
      <Divider size="sm" />

      <DsContainer fluid style={{ marginBottom: 100, padding: 0 }}>
        <DsStackVertical spacing={0}>
          {posts.length !== 0 ? (
            posts.map(post => (
              <div key={`post-item-${post.id}`}>
                <PostItem post={post} />
                <Divider size="sm" />
              </div>
            ))
          ) : (
            <EmptyState />
          )}
        </DsStackVertical>
      </DsContainer>
    </>
  )
}
