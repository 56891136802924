import { useTranslation } from "react-i18next"
import { Link, useNavigate } from "react-router-dom"
import { Button, Center, Image, Space, Stack, Text, Title } from "@mantine/core"
import { signin } from "../../api/signIn"
import { SocialButtons } from "../../components/SocialButtons"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"
import { useSignInMemberForm } from "../../hooks/useSignInMemberForm"
import { DsTextInputEmail, DsTextInputPassword } from "../../@raise-sistemas/ds"
import { useUnexpectedError } from "../../hooks/errors/useUnexpectedError"
import { useAuthError } from "../../hooks/errors/useAuthError"
import { getHost } from "../../helpers/getHost"
import { DEFAULT_SPACE_LOGO } from "../../lib/constants"
import { useMutation } from "@tanstack/react-query"
import { AuthApiError } from "@supabase/supabase-js"

export const PageSignInMember = () => {
  const form = useSignInMemberForm({ email: "", password: "" })
  const data = useSpaceQuery()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const unexpectedError = useUnexpectedError()
  const authError = useAuthError()
  const { mutate, isLoading } = useMutation(signin)

  const handleSingIn = () => {
    mutate(form.values, {
      onSuccess: () => {
        navigate("/channels/posts")
      },
      onError(error) {
        if (error instanceof AuthApiError) return authError()
        unexpectedError(t("error.message.something_wrong"))
      }
    })
  }

  const handleSubmit = () => !form.validate().hasErrors && handleSingIn()

  const currentHost = getHost()

  return (
    <Center
      style={{ height: "100vh", display: "flex", flexDirection: "column" }}
    >
      <div style={{ marginLeft: "auto", marginRight: "auto" }}>
        <Image
          height={40}
          width={40}
          radius="md"
          src={currentHost === "app" ? DEFAULT_SPACE_LOGO : data?.data?.logo}
          alt={t("logo_image")}
        />
      </div>

      <Space h="md" />
      <Title align="center" order={3} weight="500">
        {t("auth.sign_in")}
      </Title>
      <Space h="xs" />
      <Text size="xs" align="center" color="#718096">
        {t("greetings.welcome_login_to_enter")}
      </Text>
      <Space h="xs" />
      <Stack
        sx={() => ({
          width: "360px",
          "@media (max-width: 600px)": {
            width: "280px"
          }
        })}
      >
        <DsTextInputEmail
          {...form.getInputProps("email")}
          label={t("input.email.label")}
          type="email"
          placeholder={t("input.email.placeholder_write_email")}
        />
        <Space />
        <DsTextInputPassword
          type="password"
          {...form.getInputProps("password")}
          label={t("input.password.label")}
          placeholder={t("input.password.placeholder")}
          isVisible
        />
        <Text
          size="xs"
          variant="link"
          component={Link}
          to="/remeber-password"
          ml="auto"
        >
          {t("auth.forgot_password")}
        </Text>
        <Button onClick={handleSubmit} loading={isLoading}>
          {t("auth.login")}
        </Button>
        <SocialButtons />
        <Text size="xs" align="center">
          {t("auth.no_account_question")}{" "}
          <Text
            size="xs"
            variant="link"
            component={Link}
            to="/sign-up"
            align="center"
          >
            {t("auth.sign_up")}
          </Text>
        </Text>
      </Stack>
    </Center>
  )
}
