import { getTenant } from "../helpers/getTenant"
import { supabase } from "../lib/supabase"

export const memberById = (user_uuid: string) => {
  if (!user_uuid) throw new Error("user_uuid is required")
  return supabase
    .from("member")
    .select("*")
    .match({
      user_uuid,
      tenant: getTenant()
    })
    .single()
}
