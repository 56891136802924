import { Box, Flex, Input, createStyles } from "@mantine/core"
import { ChangeEventHandler, FocusEventHandler } from "react"
const { Wrapper } = Input

export type DsTextInputAddonProps = {
  label?: string
  leftSection?: string | React.ReactNode
  rightSection?: string | React.ReactNode
  placeholder?: string
  defaultValue?: string | number | readonly string[]
  iconRight?: React.ReactNode
  value?: string | number | readonly string[]
  onChange?: ChangeEventHandler<HTMLInputElement>
  onFocus?: FocusEventHandler<HTMLInputElement>
  onBlur?: FocusEventHandler<HTMLInputElement>
  error?: React.ReactNode
  required?: boolean
}

const useStyles = createStyles(
  (
    theme,
    {
      leftSection,
      rightSection,
      error
    }: Pick<DsTextInputAddonProps, "rightSection" | "leftSection" | "error">
  ) => {
    const colors = theme.fn.variant({ color: "gray", variant: "default" })
    return {
      root: {
        display: "flex",
        flexDirection: "column",
        gap: "6px",
        "& > div:nth-of-type(1)": {
          border: error
            ? `1px solid ${theme.colors.red[5]}`
            : `1px solid ${colors.border} `,
          borderRadius: theme.radius.sm
        }
      },
      inputContent: {
        color: error ? theme.colors.red[5] : theme.colors.gray[6],
        padding: "0 16px",
        borderTop: "none",
        borderBottom: "none",
        borderTopRightRadius: rightSection ? 0 : theme.radius.sm,
        borderBottomRightRadius: rightSection ? 0 : theme.radius.sm,
        borderRight: rightSection
          ? `1px solid ${error ? theme.colors.red[5] : colors.border}`
          : 0,
        borderTopLeftRadius: leftSection ? 0 : theme.radius.sm,
        borderBottomLeftRadius: leftSection ? 0 : theme.radius.sm,
        borderLeft: leftSection
          ? `1px solid ${error ? theme.colors.red[5] : colors.border}`
          : 0,
        "&:focus": {
          /*  border: `1px solid ${colors.border}`, */
          borderLeft: leftSection
            ? `1px solid ${error ? theme.colors.red[5] : colors.border}`
            : 0,
          borderRight: rightSection
            ? `1px solid ${error ? theme.colors.red[5] : colors.border}`
            : 0
        }
      },
      inputWrapper: {
        flex: 1,
        marginBottom: error ? 0 : 0
      },
      indicator: {
        padding: "0 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopRightRadius: theme.radius.sm,
        borderBottomRightRadius: theme.radius.sm,
        /*  border: `1px solid ${colors.border}`, */
        backgroundColor: colors.hover,
        color: error ? theme.colors.red[5] : theme.colors.gray[6]
      },
      indicatorLeft: {
        padding: "0 16px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderTopLeftRadius: theme.radius.sm,
        borderBottomLeftRadius: theme.radius.sm,
        /* border: `1px solid ${colors.border}`, */
        backgroundColor: colors.hover,
        color: error ? theme.colors.red[5] : theme.colors.gray[6]
      },
      rightSection: {
        marginTop: "2px",
        "& > svg": {
          stroke: error ? theme.colors.red[5] : theme.colors.gray[6],
          maxWidth: "16px"
        }
      }
    }
  }
)

export function DsTextInputAddon(props: DsTextInputAddonProps) {
  const { leftSection, rightSection, error } = props
  const { classes } = useStyles({ leftSection, rightSection, error })
  return (
    <Wrapper
      required={props.required}
      error={props.error}
      classNames={{
        root: classes.root
      }}
      label={props.label}
    >
      <Flex>
        {props.leftSection ? (
          <Box
            className={classes.indicatorLeft}
            component={typeof props.leftSection === "string" ? "span" : "div"}
          >
            {props.leftSection}
          </Box>
        ) : null}
        <Input
          onChange={props.onChange}
          value={props.value}
          defaultValue={props.defaultValue}
          placeholder={props.placeholder}
          onFocus={props.onFocus}
          onBlur={props.onBlur}
          type="url"
          rightSection={props.iconRight}
          classNames={{
            wrapper: classes.inputWrapper,
            input: classes.inputContent,
            rightSection: classes.rightSection
          }}
        />
        {props.rightSection ? (
          <Box
            className={classes.indicator}
            component={typeof props.rightSection === "string" ? "span" : "div"}
          >
            {props.rightSection}
          </Box>
        ) : null}
      </Flex>
    </Wrapper>
  )
}
