import { HOSTS_DEV, IS_PRODUCTION } from "../lib/constants"

/**
 * @example const host = getHost()
 * @host app.luminy.app
 * @returns app
 *
 * @host luminy.app
 * @returns luminy.app
 *
 * @host customdomain.com
 * @returns customdomain.com
 */
export const getHost = () => {
  const { hostname } = window.location

  const currentHost = IS_PRODUCTION
    ? hostname.replace(/.luminy.app|.mycomu.com|.100tedio.com.br/, "")
    : hostname.replace(/.localhost|.lvh.me/, "")

  if (HOSTS_DEV.includes(currentHost)) {
    return "dev"
  }

  return currentHost
}
