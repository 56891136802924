import { NotificationProps, showNotification } from "@mantine/notifications"
import { IconX } from "@tabler/icons"

type Payload = Pick<NotificationProps, "title" | "message">

export const toastError = ({ title, message }: Payload) => {
  showNotification({
    color: "red",
    icon: <IconX />,
    title,
    message
  })
}
