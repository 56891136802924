import { DsButtonIcon, DsText, DsTooltip } from "@raise-sistemas/ds"
import { Heart, HeartOutline } from "@raise-sistemas/icons"
import { useTranslation } from "react-i18next"

export type ButtonLikeProps = {
  count: number
  liked: boolean
  onLike: (liked: boolean) => Promise<void> | void
}

export function ButtonLike({ liked, count, onLike }: ButtonLikeProps) {
  const { t } = useTranslation()

  return (
    <DsTooltip label={t("tooltip.like")}>
      <DsButtonIcon
        leftIcon={
          liked ? (
            <Heart color="#FC2323" size={24} />
          ) : (
            <HeartOutline color="gray" size={18} />
          )
        }
        onClick={() => onLike(liked)}
      >
        <DsText color="#A0AEC0" weight="regular" variant="body-2">
          {count}
        </DsText>
      </DsButtonIcon>
    </DsTooltip>
  )
}
