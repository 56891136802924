import {
  Center,
  PasswordInput,
  Stack,
  Title,
  createStyles
} from "@mantine/core"
import { useForm } from "@mantine/form"
import {
  DsButtonPrimary,
  DsImageLogo,
  DsSpace,
  DsStackVertical
} from "@raise-sistemas/ds"
import { useMutation } from "@tanstack/react-query"
import { FormEvent } from "react"
import { useTranslation } from "react-i18next"
import { Form, useNavigate } from "react-router-dom"
import { newPassword } from "../../api/newPassword"
import { toastSuccess } from "../../helpers/toastSuccess"
import { useUnexpectedError } from "../../hooks/errors/useUnexpectedError"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"

const useStyles = createStyles(() => ({
  container: {
    height: "100vh",
    display: "flex",
    flexDirection: "column"
  },
  form: {
    width: "360px",

    ["@media (max-width: 600px)"]: {
      width: "260px"
    }
  },
  logo: {
    margin: "0 auto"
  }
}))

export const PageNewPassword = () => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const data = useSpaceQuery()
  const navigate = useNavigate()
  const unexpectedError = useUnexpectedError()
  const mutation = useMutation(newPassword, {
    onError() {
      unexpectedError(t("error.cant_finalize"))
    },
    onSuccess() {
      toastSuccess({
        title: t("password.change_success"),
        message: ""
      })
      navigate("/channels/posts")
    }
  })

  const form = useForm({
    initialValues: {
      password: "",
      passwordConfirmation: ""
    },

    validate: {
      password: value =>
        value.length >= 6 ? null : t("validation.password_min_size"),
      passwordConfirmation: (value, values) =>
        value === values.password ? null : t("validation.password_dont_match")
    }
  })

  const handleSubmit = () => {
    if (!form.isValid) return
    mutation.mutate({ password: form.values.password })
  }

  return (
    <Center className={classes.container}>
      <DsStackVertical spacing="md">
        <DsImageLogo
          size="sm"
          radius="sm"
          className={classes.logo}
          src={data?.data?.logo}
          alt={t("logo_image")}
        />
        <DsSpace height="sm" />
        <DsSpace height="lg" />
        <Title align="center" order={3} weight="500">
          {t("auth.new_password")}
        </Title>
        <Form method="post">
          <Stack className={classes.form} spacing="xl">
            <Stack spacing="sm">
              <PasswordInput
                label={t("input.new_password.label")}
                placeholder={t("input.new_password.placeholder")}
                {...form.getInputProps("password")}
              />
              <PasswordInput
                label={t("input.new_password.label")}
                placeholder={t("input.new_password.placeholder")}
                {...form.getInputProps("passwordConfirmation")}
              />
            </Stack>
            <DsButtonPrimary
              onClick={(e: unknown) =>
                form.onSubmit(handleSubmit)(e as FormEvent<HTMLFormElement>)
              }
              loading={mutation.isLoading}
            >
              {t("button.save")}
            </DsButtonPrimary>
          </Stack>
        </Form>
      </DsStackVertical>
    </Center>
  )
}
