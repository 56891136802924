import { Avatar, Button, Flex, Text, Textarea } from "@mantine/core"
import { useForm } from "@mantine/form"
import {
  DsSpace,
  DsStackVertical,
  DsTextInputAddon,
  DsTextInputBase
} from "@raise-sistemas/ds"
import { useMutation, useQueryClient } from "@tanstack/react-query"
import { useTranslation } from "react-i18next"
import { spaceEdit } from "../../api/spaceEdit"
import { AppColorSwatchGrid } from "../../components/AppColorSwatchGrid"
import { ImageUpload } from "../../components/ImageUpload"
import { getTenant } from "../../helpers/getTenant"
import { useSpaceQuery } from "../../hooks/queries/useSpaceQuery"
import { usePrimaryColor } from "../../hooks/theme/usePrimaryColor"
import { queries } from "../../lib/query-key-factory"
import { SpaceType } from "../../models/space"

export const SettingsSpace = () => {
  const space = useSpaceQuery()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const [primaryColor, setPrimaryColor] = usePrimaryColor()

  const form = useForm({
    initialValues: {
      title: space?.data.title || "",
      description: space?.data.description || "",
      logo: space?.data.logo || ""
    },
    validate: {
      title: value =>
        value.length < 3
          ? t("validation.field_min_size", {
              size: 3
            })
          : null,
      description: value =>
        value.length < 1 ? t("validation.required_field") : null
    }
  })

  const mutation = useMutation({
    mutationFn: spaceEdit,
    onSuccess: () => {
      queryClient.invalidateQueries(queries.space.info._def)
    }
  })

  const handleSubmit = () => {
    const data = space?.data || ({} as SpaceType["data"])

    form.onSubmit(values =>
      mutation.mutate({
        ...data,
        ...values
      })
    )()
  }

  const handleUploadLogo = (url: string | null) => {
    if (!space) return

    if (url) {
      mutation.mutate({
        ...space?.data,
        logo: url
      })
    }
  }

  return (
    <>
      <Text fw={500} size={16}>
        {t("root.community_info")}
      </Text>
      <Text fw={400} size={12} c="dimmed">
        {t("root.community_info_helper_text")}
      </Text>
      <DsStackVertical spacing="sm">
        <DsStackVertical spacing="lg">
          <DsTextInputBase
            value={form.values.title}
            label={t("input.community.name.label")}
            placeholder={t("input.community.name.placeholder")}
            {...form.getInputProps("title")}
          />
          <DsSpace height="sm" />
          <Text fw={500} size={14}>
            Logo
          </Text>
        </DsStackVertical>

        <DsStackVertical spacing="lg">
          <Flex gap="md">
            <Avatar
              h={64}
              w={64}
              component="a"
              target="_blank"
              src={space?.data.logo}
              alt="image"
            />
            <ImageUpload onUpload={handleUploadLogo} />
          </Flex>
          <DsSpace height="sm" />
          <Textarea
            minRows={5}
            value={form.values.description}
            label={t("input.community.description.label")}
            placeholder={t("input.community.description.placeholder")}
            {...form.getInputProps("description")}
          />
          <DsSpace height="sm" />
          <Text fw={500} size={14}>
            {t("input.community.color.label")}
          </Text>
        </DsStackVertical>

        <DsStackVertical spacing="lg">
          <AppColorSwatchGrid value={primaryColor} onChange={setPrimaryColor} />
          <DsSpace height="sm" />
          <Text fw={500} size={14}>
            {t("root.public_page")}
          </Text>
        </DsStackVertical>

        <DsStackVertical spacing="lg">
          <Text fw={400} size={12}>
            {t("root.public_page_helper_text")}
          </Text>
          <DsSpace height="sm" />
          <DsStackVertical spacing="xl">
            <DsTextInputAddon
              label={t("input.community.subdomain.label")}
              placeholder="mycommu"
              defaultValue={getTenant()}
              rightSection=".luminy.app"
            />
            <DsSpace height="xl" />
            <Flex justify="flex-end">
              <Button loading={mutation.isLoading} onClick={handleSubmit}>
                {t("button.save")}
              </Button>
            </Flex>
          </DsStackVertical>
        </DsStackVertical>
      </DsStackVertical>
    </>
  )
}
