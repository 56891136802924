import { getTenant } from "../helpers/getTenant"
import { supabase } from "../lib/supabase"
export type JoinPayload = {
  user_uuid: string
  role: string
  tenant?: string
  data: {
    name: string
    email: string
    avatar: string
  }
}

export function memberCreate(payload: JoinPayload) {
  const record = {
    user_uuid: payload.user_uuid,
    role: payload.role,
    tenant: payload.tenant || getTenant(),
    data: {
      ...payload.data,
      created_at: new Date().toISOString()
    }
  }

  return supabase.from("member").insert(record)
}
